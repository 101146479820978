import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import React from "react";

function Account() {

    return (
        <div>
            <div
                className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 md:py-16">
                <h2 className="text-2xl font-semibold leading-tight text-gray-800 false">
                    We are sorry but unfortunately account management is not available at the moment.
                </h2>
            </div>
            {/*<div className="overlay">*/}
            {/*    <div className="bg-gradient-to-b from-blue-200 via-orange-50 dark:via-gray-950 pt-10 ">*/}

            {/*        <div className=" d-flex align-items-center justify-content-center">*/}
            {/*            <Form>*/}
            {/*                <h1 className="text-lg leading-tight mb-2 md:text-xl font-open font-bold">Your details</h1>*/}
            {/*                <Row className="mb-3">*/}
            {/*                    <Form.Group as={Col} controlId="formGridEmail">*/}
            {/*                        <Form.Label>Email</Form.Label>*/}
            {/*                        <Form.Control type="email" placeholder="Enter email"/>*/}
            {/*                    </Form.Group>*/}

            {/*                    <Form.Group as={Col} controlId="formGridPassword">*/}
            {/*                        <Form.Label>Password</Form.Label>*/}
            {/*                        <Form.Control type="password" placeholder="Password"/>*/}
            {/*                    </Form.Group>*/}
            {/*                </Row>*/}

            {/*                <Form.Group className="mb-3" controlId="formGridAddress1">*/}
            {/*                    <Form.Label>Address</Form.Label>*/}
            {/*                    <Form.Control placeholder="1234 Main St"/>*/}
            {/*                </Form.Group>*/}

            {/*                <Form.Group className="mb-3" controlId="formGridAddress2">*/}
            {/*                    <Form.Label>Address 2</Form.Label>*/}
            {/*                    <Form.Control placeholder="Apartment, studio, or floor"/>*/}
            {/*                </Form.Group>*/}

            {/*                <Row className="mb-3">*/}
            {/*                    <Form.Group as={Col} controlId="formGridCity">*/}
            {/*                        <Form.Label>City</Form.Label>*/}
            {/*                        <Form.Control/>*/}
            {/*                    </Form.Group>*/}

            {/*                    <Form.Group as={Col} controlId="formGridState">*/}
            {/*                        <Form.Label>State</Form.Label>*/}
            {/*                        <Form.Select defaultValue="Choose...">*/}
            {/*                            <option>Choose...</option>*/}
            {/*                            <option>...</option>*/}
            {/*                        </Form.Select>*/}
            {/*                    </Form.Group>*/}

            {/*                    <Form.Group as={Col} controlId="formGridZip">*/}
            {/*                        <Form.Label>Zip</Form.Label>*/}
            {/*                        <Form.Control/>*/}
            {/*                    </Form.Group>*/}
            {/*                </Row>*/}

            {/*                <Row className="mb-3">*/}
            {/*                    <Form.Group as={Col} controlId="formGridSubscription">*/}
            {/*                        <Button>Manage Subscription</Button>*/}
            {/*                    </Form.Group>*/}

            {/*                    <Form.Group as={Col} controlId="formGridCourses">*/}
            {/*                        <Button>Manage Courses</Button>*/}
            {/*                    </Form.Group>*/}

            {/*                    <Form.Group as={Col} controlId="formCancel">*/}
            {/*                        <Button>Delete Account</Button>*/}
            {/*                    </Form.Group>*/}
            {/*                </Row>*/}

            {/*                <div className="flex flex-end align-items-end ">*/}
            {/*                    <Button variant="primary" type="submit" className="flex align-items-end">*/}
            {/*                        Save details*/}
            {/*                    </Button>*/}
            {/*                </div>*/}
            {/*            </Form>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
);
}

export default Account;