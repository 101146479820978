import React, { Component } from 'react';
import LandingPanel from "../landingPagePanel/landingPagePanel";

class MLNETTutorialLanding extends Component{

    constructor(props){
        super(props);
        this.gettingStartedTutorials = [
                {id: 1, 
                    title: 'CLI Installation',
                    content: 'Installing ML.NET to create and train a model from the command line ',
                    image:"/images/machine-learning/panel-dotnet-console.svg",
                    slug:'getting-started-cli',
                    enabled: true},
                {id: 2, 
                    title: 'Training a model in the IDE',
                    content: 'An example showing how to create and train a model from within the IDE',
                    image:"/images/machine-learning/panel-dotnet-ide.svg",
                    slug:'getting-started-ide',
                    enabled: true},

                {id: 3,
                    title: '.NET Interactive Notebooks ',
                    content: 'How to get started with .NET interactive notebooks',
                    image:"/images/machine-learning/panel-dotnet-interactive.svg",
                    slug:'dotnet-interactive-notebook',
                    enabled: true},
                {id: 4,
                    title: 'Interactive Iris classification',
                    content: 'The Iris classification problem in an interactive notebook',
                    image:"/images/machine-learning/panel-dotnet-interactive.svg",
                    slug:'interactive-iris-classification',
                    enabled: true}
            ];


        this.classificationTutorials = [
            {id: 1, 
                title: 'Binary Classification',
                content: 'Classification example using the well known Titanic dataset',
                image:"/images/machine-learning/panel-classification.svg",
                slug:'mlnet-titanic-binary-classification',
                enabled: true},
            {id: 2,
                title: 'Interactive Binary Classification',
                content: 'Interactive notebook example using the Titanic dataset',
                image:"/images/machine-learning/panel-classification.svg",
                slug:'mlnet-interactive-titanic-binary-classification',
                enabled: true},
            {id: 3,
                title: 'Binary Image Classification',
                content: 'How to get started with image classification',
                image:"/images/machine-learning/panel-image-classification.svg",
                slug:'mlnet-binary-image-classification',
                enabled: true},
            {id: 3,
                title: 'Multi Image Classification',
                content: 'Classifying multiple images with a prebuilt tensorflow model',
                image:"/images/machine-learning/panel-image-classification.svg",
                slug:'mlnet-multi-image-classification',
                enabled: true}
            
        ];

        this.regressionTutorials = [
            {id: 1, 
                title: 'Simple Linear Regression',
                content: 'Regression example using New York taxi data',
                image:"/images/machine-learning/panel-regression.svg",
                slug:'mlnet-linear-regression',
                enabled: false},
            {id: 2, 
                title: 'Interactive Time Series Analysis',
                content: 'Investigating how to train and evaluate time series data with ML.NET',
                image:"/images/machine-learning/panel-time-series.svg",
                slug:'mlnet-interactive-time-series',
                enabled: true},
            {id: 3,
                title: 'Stock Prediction (time series analysis)',
                content: 'Stock prediction example with ML.NET',
                image:"/images/machine-learning/panel-time-series.svg",
                slug:'mlnet-stock-price-prediction',
                enabled: true}
            
        ];

        this.imageTutorials = [
            {id: 1, 
                title: 'Basic Regression tutorial page',
                content: 'A basic classification example using the well known IRIS dataset which classifies Iris flowers based on their features', 
                image:"/images/panel-regression.svg", 
                slug:'mlnet-basic-regression-tutorial',
                enabled: false},
            {id: 2,
                title: 'Image Classification',
                content: 'How to get started with image classification',
                image:"/images/panel-image-classification.svg",
                slug:'mlnet-binary-image-classification',
                enabled: false},
            
        ];

        this.nlpTutorials = [
            {id: 1, 
                title: 'Introduction to NLP',
                content: 'Natural Language Processing with ML.NET',
                image:"/images/machine-learning/panel-nlp-classification.svg",
                slug:'mlnet-nlp-text-classification'},
            {id: 2, 
                title: 'Sentence Similarity',
                content: 'Compare sentences to determine their similarity ',
                image:"/images/machine-learning/panel-nlp-classification.svg",
                slug:'mlnet-nlp-senntence-similarity'}
            
        ];
    }
    

    render(){

        return (
            <React.Fragment>
                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-red-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2"></div>
                                <div className="flex rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Getting started
                                        </h2></div>
                                </div>
                                <div className="h-6"></div>
                                <p className="leading-snug text-gray-500">A gentle introduction to Machine Learning
                                </p>
                                <div className="h-6"></div>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.gettingStartedTutorials.map((data) => (
                                <LandingPanel key={data.id}  title={data.title} imageSource={data.image} to={"/machine-learning/tutorials/mlnet/" + data.slug} strapLine={data.content}  />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-blue-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2"></div>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Classification
                                        </h2></div>
                                </div>
                                <div className="h-6"></div>
                                <p className="leading-snug text-gray-500">An introduction to classification with ML.NET
                                </p>
                                <div className="h-6"></div>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.classificationTutorials.map((data) => (
                                <LandingPanel key={data.id}  title={data.title} imageSource={data.image} to={"/machine-learning/tutorials/mlnet/" + data.slug} strapLine={data.content}  />
                            ))}
                        </div>
                    </div>
                </div>


                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-green-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2"></div>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Regression
                                        </h2></div>
                                </div>
                                <div className="h-6"></div>
                                <p className="leading-snug text-gray-500">An introduction to regression with ML.NET
                                </p>
                                <div className="h-6"></div>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.regressionTutorials.map((data) => (
                                <LandingPanel key={data.id}  title={data.title} imageSource={data.image} to={"/machine-learning/tutorials/mlnet/" + data.slug} strapLine={data.content}  />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-fuchsia-800 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2"></div>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Natural Language Processing
                                        </h2></div>
                                </div>
                                <div className="h-6"></div>
                                <p className="leading-snug text-gray-500">An introduction to NLP with ML.NET
                                </p>
                                <div className="h-6"></div>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.nlpTutorials.map((data) => (
                                <LandingPanel key={data.id} title={data.title} imageSource={data.image} to={"/machine-learning/tutorials/mlnet/" + data.slug} strapLine={data.content}  />
                            ))}
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };

}

export default MLNETTutorialLanding;