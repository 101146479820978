import React, { Component } from 'react';

import Prism from 'prismjs';
import {withParams} from "../../App";
import PageComponent from "../pageComponent/pageComponent";
import $ from "jquery";
import {toast} from "react-toastify";

class ScipyPage extends Component {


    state = {
        title: '',
        tags: [],
        html: '',
        nextContent:'',
        relatedContent: [],
        sourceLinks: [],
        algos: [],
        languages: [],
        attributed:[],
        suggestedCourses:[],
        slug: '',
        headings: []
    };



  slugs = [
    {slug: 'installing-scipy',
        source: '/ml/tutorials/scipy/installing-scipy.html',
        title: 'How to install SciPy',
        tags: ['SciPy', 'UnSupervised'], sourceLinks: [], languages:['Python'], algos: [], attributed:[] },
    {slug: 'scipy-in-an-ide',
        source: '/ml/tutorials/scipy/scipy-in-an-ide.html',
        title: 'Using SciPy in an IDE',
        tags: [], sourceLinks: [], languages:['Python'], algos: [], attributed:[] },
    {slug: 'scipy-interactive-notebooks',
        source: '/ml/tutorials/scipy/scipy-in-an-interactive-notebook.html',
        title: 'Using SciPy in an interactive notebook',
        tags: ['Interactive notebook'], sourceLinks: [], languages:['Python', 'C#'], algos: [], attributed:[] },
    {slug: 'scipy-interactive-iris-classification',
        source: '/ml/tutorials/scipy/interactive-iris-classification.html',
        title: 'Running the Iris classification in an interactive notebook',
        tags: [], sourceLinks: [], languages:['Python','C#', 'Java'], algos: [], attributed:[] },
    {slug: 'machine-learning-classification',
        source: '/ml/tutorials/scipy/classification.html',
        title: 'Classification',
        tags: ['Binary Classification', 'Clustering'], sourceLinks: [], languages:['C#'], algos: ['K-Means'], attributed:[] },
    {slug: 'data-bias-in-machine-learning',
        source: '/ml/tutorials/scipy/data-bias-in-machine-learning.html',
        title: 'Data Bias',
        tags: ['Data'], sourceLinks: [], languages:['Python', 'C#'], algos: [], attributed:[] },
    {slug: 'types-of-neural-networks',
        source: '/ml/tutorials/scipy/types-of-neural-networks.html',
        title: 'Types of Neural Networks',
        tags: ['MLP', 'FeedForward'], sourceLinks: [], languages:['Python', 'C#'], algos: [], attributed:[] },
    {slug: 'common-machine-learning-algorithms',
        source: '/ml/tutorials/scipy/common-machine-learning-algorithms.html',
        title: 'Common Machine Learning Algorithms',
        tags: [], sourceLinks: [], languages:['C#'], algos: ['K-Means'], attributed:[] },
    {slug: 'when-to-not-use-machine-learning',
        source: '/ml/tutorials/scipy/when-to-not-use-machine-learning.html',
        title: 'When to not use Machine Learning',
        tags: [], sourceLinks: [], languages:[], algos: [], attributed:[]  },
    {slug: 'binary-classification',
        source: '/ml/tutorials/scipy/classification.html',
        title: 'Classification',
        tags: ['Binary Classification'], sourceLinks: [], languages:['Python', 'C#'], algos: [], attributed:[]  },
    {slug: 'basic-classification-problem',
        source: '/ml/tutorials/scipy/basic-classification-problem.html',
        title: 'Basic Classification Problem',
        tags: [], sourceLinks: [], languages:['Python', 'C#'], algos: [], attributed:[] },
    {slug: 'basic-regression',
        source: '/ml/tutorials/scipy/basic-regression.html',
        title: 'Regression',
        tags: [], sourceLinks: [], languages:['Python', 'C#'], algos: [], attributed:[] },
    {slug: 'basic-regression-problem',
        source: '/ml/tutorials/scipy/basic-regression-problem.html',
        title: 'Basic Regression Problem',
        tags: [], sourceLinks: [], languages:['C#'], algos: [], attributed:[] },
    {slug: 'data-and-feature-engineering',
        source: '/ml/tutorials/scipy/data-and-feature-engineering.html',
        title: 'Data and Feature Engineering',
        tags: ['Data', 'Normalization', 'Features'], sourceLinks: [], languages:['Python', 'C#'], algos: [], attributed:[] },
      {slug: 'large-data-sets',
          source: '/ml/tutorials/scipy/large-data-sets.html',
          title: 'Dealing with Large Datasets',
          tags: ['Data'], sourceLinks: [], languages:['Python', 'C#'], algos: [], attributed:[] },
      {slug: 'transformers',
          source: '/ml/tutorials/scipy/transformers.html',
          title: 'Transformers',
          tags: ['Models', 'RNNs', 'LSTM'], sourceLinks: [], languages:['Python', 'C#'], algos: [], attributed:['https://arxiv.org/pdf/1706.03762.pdf'] },
    //================================== Tutorials ========================================
    {slug: 'scipy-getting-started-cli', source: '/tutorials/scipy/mlnet-getting-started-cli.html', title: 'Getting started with the Command Line', tags: [], sourceLinks: [], languages:['Python'], algos: [], attributed:[]  },
    {slug: 'mlnet-getting-started-ide', source: '/tutorials/scipy/mlnet-getting-started-ide.html', title: 'Getting started in an IDE', tags: [], sourceLinks: [], languages:['Python'], algos: [], attributed:[]  },
    {slug: 'mlnet-classification-tutorial', source: '/tutorials/scipy/mlnet-classification-tutorial.html', title: 'Classification Tutorial', tags: [], sourceLinks: [], languages:['Python'], algos: [], attributed:[]  },
    {slug: 'mlnet-binary-image-classification', source: '/tutorials/scipy/mlnet-binary-image-classification.html', title: 'MLNET Image Classification Tutorial', tags: [], sourceLinks: [], languages:['C#'], algos: [], attributed:[]  },
    {slug: 'mlnet-basic-regression-tutorial', source: '/tutorials/scipy/mlnet-basic-regression-tutorial.html', title: 'Regression', tags: [], sourceLinks: [], languages:['Python'], algos: [], attributed:[]  },
    {slug: 'mlnet-time-series-tutorial', source: '/tutorials/scipy/mlnet-time-series.html', title: 'Basic Time Series Analysis', tags: [], sourceLinks: [], languages:['Python'], algos: [], attributed:[]  }
    //================================== Examples ========================================
  ];


  nextPage = [
    {key: 'what-is-machine-learning', 
        slug: 'how-do-machines-learn', 
        title: 'How do machines learn', 
        path: '/machine-learning/basics/'},
    {key: 'brief-history-of-machine-learning', 
        slug: 'how-do-machines-learn', 
        title:'How do machines learn',  
        path: '/machine-learning/basics/' },
    {key: 'how-do-machines-learn', 
        slug: 'machine-learning-frameworks',  
        title:'Common ML frameworks', 
        path: '/machine-learning/basics/' },
    {key: 'machine-learning-frameworks', 
        slug: '', 
        title: 'Machine Learning',
        path: '/machine-learning/' },
    {key: 'machine-learning-classification', 
        slug: '/ml/classification.html', 
        title: 'Basics of Classification', 
        path: '/machine-learning/' },
    {key: 'data-bias-in-machine-learning',
        slug: 'data-and-feature-engineering', 
        title:'Data and Feature Engineering',  
        path: '/machine-learning/basics/' },

    {key: 'data-and-feature-engineering', 
        slug: '',
        title:'Machine Learning',  
        path: '/machine-learning/' },
    {key: 'mlnet-getting-started-cli',
        slug: 'mlnet-getting-started-ide',
        path: '/tutorials/' }
  ]


  relatedPages = [
    { id: 1, key: 'what-is-machine-learning', title:'Brief history of Machine Learning', slug:"brief-history-of-machine-learning", link: '/machine-learning/basics/' },
    { id: 2, key: 'what-is-machine-learning', title:'Common Machine Learning Frameworks', slug:"machine-learning-frameworks", link: '/machine-learning/basics/' },
    { id: 3, key: 'what-is-machine-learning', title:'How do machines learn?', slug:"how-do-machines-learn", link: '/machine-learning/basics/' },

    { id: 4, key: 'mlnet-getting-started-cli', title:'Getting started with ML.NET in the IDE', slug:"mlnet-getting-started-ide", link: '/tutorials/' },

    { id: 5, key: 'brief-history-of-machine-learning', title:'How do machines learn?', slug:"how-do-machines-learn", link: '/machine-learning/basics/' },
    { id: 6, key: 'brief-history-of-machine-learning', title:'Common Machine Learning Frameworks', slug:"machine-learning-frameworks", link: '/machine-learning/basics/' },

    { id: 7, key: 'how-do-machines-learn', title:'Brief history of Machine Learning', slug:"brief-history-of-machine-learning", link: '/machine-learning/basics/'  },
    { id: 8, key: 'how-do-machines-learn', title:'Common Machine Learning Frameworks', slug:"machine-learning-frameworks", link: '/machine-learning/basics/' },
      { id: 9, key: 'transformers', title:'Google Paper', slug:"transformers", link: 'https://arxiv.org/pdf/1706.03762.pdf' },

  ]

    suggestedCourses = [
        { id: 1, key: 'what-is-machine-learning', title:'Brief history of Machine Learning', slug:"brief-history-of-machine-learning", link: '/machine-learning/basics/' },
        { id: 2, key: 'what-is-machine-learning', title:'Common Machine Learning Frameworks', slug:"machine-learning-frameworks", link: '/machine-learning/basics/' },
        { id: 3, key: 'what-is-machine-learning', title:'How do machines learn?', slug:"how-do-machines-learn", link: '/machine-learning/basics/' },

        { id: 4, key: 'mlnet-getting-started-cli', title:'Getting started with ML.NET in the IDE', slug:"mlnet-getting-started-ide", link: '/tutorials/' },

        { id: 5, key: 'brief-history-of-machine-learning', title:'How do machines learn?', slug:"how-do-machines-learn", link: '/machine-learning/basics/' },
        { id: 6, key: 'brief-history-of-machine-learning', title:'Common Machine Learning Frameworks', slug:"machine-learning-frameworks", link: '/machine-learning/basics/' },

        { id: 7, key: 'how-do-machines-learn', title:'Brief history of Machine Learning', slug:"brief-history-of-machine-learning", link: '/machine-learning/basics/'  },
        { id: 8, key: 'how-do-machines-learn', title:'Common Machine Learning Frameworks', slug:"machine-learning-frameworks", link: '/machine-learning/basics/' },

    ]

    constructor(props) {
        super(props);
        this.state.slug = props.params.slug;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {

      console.log('shouldComponentUpdate returning TRUE')

      return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.attachCopyTagHandlers();
    }


  componentDidUnMount() {
    console.log('Machine Learning component UNMOUNTED');
  }


    componentDidMount() {

    // Get the contents from the HTML file and put them in the React state, so we can reference it in render() below.
    const path = this.slugs.filter(path => path.slug === this.state.slug);
    console.log("Slug [" + this.state.slug + "] matched to url [" + path[0].source + "]" )

    if(path.length === 0){
      console.error('Failed to located source content path for : ' + this.state.slug);
    }

    const next = this.nextPage.filter(item => item.key === this.state.slug)[0];
    const related = this.relatedPages.filter(item => item.key === this.state.slug);
    const suggested = this.suggestedCourses.filter(item => item.key === this.state.slug);

    this.setState({
        nextContent : next !== undefined ? next : {key: '', slug: '', title:this.state.nextContent, path: '/machine-learning/' },
        relatedContent : related,
        title : path[0].title,
        tags : path[0].tags,
        sourceLinks : path[0].sourceLinks,
        languages: path[0].languages,
        algos: path[0].algos,
        suggestedCourses: suggested,
        attributed: path[0].attributed

    });

    if(this.state){
      console.log("Next Page Value determined as : " + this.state.nextContent);
    }

     fetch(path[0].source)
      .then(res => res.text())
      .then(html => this.setState((state) => ({ ...state, html})))
      .catch((err) => console.error(err));

      Prism.highlightAll();

    console.log('Machine Learning component mounted ');
  }

    attachCopyTagHandlers(){

        console.log("Checking for Tags and copy buttons");

        const copy = $('button.listing-action');
        const tags = $('span.js-tag-group-entry');

        console.log('Tags :' + tags.length + '  ' + tags[0].innerHTML);
        console.log("Copy :" + copy.length);

        copy.on('click', function() {
            const data = $(this).parent().siblings().first().children().first().text();
            const message = ({ closeToast }) => (
                <div className="toasty-preview">
                    <p><strong>{data}</strong></p>
                    <p>Has been added to the clipboard</p>
                    <button onClick={closeToast}>Dismiss</button>
                </div>
            )
            toast(message, {
                toastId: '007',
                autoClose: 2500
            });
        });

        // With the current setup the handler can be added multiple times
        tags.off('click').on('click', function() {
            const data = $(this).text();

            console.log('Tag clicked with tag: ' + data);

            const message = ({ closeToast }) => (
                <div>
                    <p><strong>{data}</strong></p>
                    <p>Tag search is currently disabled</p>
                    <button onClick={closeToast}>Dismiss</button>
                </div>
            )
            toast(message, {
                toastId: '007',
                autoClose: 2500
            });
        });

    }


  render() {

      return (
          <PageComponent props={this.state} page="Scipy ML Page"/>
      );


  }
}

export default withParams(ScipyPage);