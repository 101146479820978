import React, {Component} from 'react';
import LandingPanel from '../landingPagePanel/landingPagePanel';

class MachineLearningLanding extends Component {

    constructor(props) {
        super(props);
        this.machineLearningBasics = [
            {
                id: 1, title: 'What is Machine Learning?',
                content: 'An introduction to machine learning and what it can do.',
                slug: 'what-is-machine-learning',
                image: "/images/machine-learning/panel-default-image.svg",
                enabled: true
            },
            {
                id: 2, title: 'Brief history of Machine Learning',
                content: 'How machine learning has evolved over the decades',
                slug: 'brief-history-of-machine-learning',
                image: "/images/machine-learning/panel-brief-history.svg",
                enabled: true
            },
            {
                id: 3, title: 'How do machines learn?',
                content: 'Some of the concepts behind machine learning',
                slug: 'how-do-machines-learn',
                image: "/images/machine-learning/panel-default-image.svg",
                enabled: true
            },
            {
                id: 4, title: 'Common Machine Learning Frameworks',
                content: 'A brief examination of some common Machine Learning frameworks and tools',
                slug: 'machine-learning-frameworks',
                image: '/images/machine-learning/panel-ml-frameworks.svg',
                enabled: true
            }

        ];

        this.machineLearningConcepts = [
            {
                id: 1, title: 'Types of Neural Networks',
                content: 'Some of the most common types of Neural Networks',
                slug: 'types-of-neural-networks',
                image: '/images/machine-learning/panel-neural-network_small.svg',
                enabled: true
            },
            {
                id: 2, title: 'Common Algorithms',
                content: 'Some of the algorithms that power Machine Learning       ',
                slug: 'common-machine-learning-algorithms',
                image: '/images/machine-learning/panel-equation.svg',
                enabled: true
            },
            {
                id: 3, title: 'Data and Feature Analysis',
                content: 'Introduction to data cleansing and feature engineering',
                slug: 'data-and-feature-engineering',
                image: '/images/machine-learning/data-analysis.svg',
                enabled: true
            },
            {
                id: 4, title: 'Large Data Sets',
                content: 'Dealing with Large Data Sets',
                slug: 'large-data-sets',
                image: '/images/machine-learning/panel-large-dataset.svg',
                enabled: true
            },
            {
                id: 5, title: 'Data Bias',
                content: 'Data bias and how it manifests',
                slug: 'data-bias-in-machine-learning',
                image: '/images/machine-learning/panel-data-bias.svg',
                enabled: true
            },
            {
                id: 6, title: 'When not to use Machine Learning',
                content: 'Inferred learning is not always the correct approach',
                slug: 'when-to-not-use-machine-learning',
                image: '/images/machine-learning/panel-people-working.svg',
                enabled: true
            },

        ];

        this.classificationBasics = [
            {
                id: 1, title: 'Binary Classification',
                content: 'Classification and common problems/approaches',
                slug: 'binary-classification',
                image: '/images/machine-learning/panel-classification.svg'
            },
            {
                id: 2, title: 'Multiclass',
                content: 'Classification of multiple options',
                slug: 'multiclass-classification',
                image: '/images/machine-learning/panel-multi-classification.svg'
            },
            {
                id: 3, title: 'Clustering',
                content: 'Grouping related items',
                slug: 'clustering',
                image: '/images/machine-learning/panel-clustering.svg'
            },
            {
                id: 4, title: 'Dimensionality Reduction',
                content: 'Distinguish between two options',
                slug: 'dimensionality-reduction',
                image: '/images/machine-learning/panel-dimensionality-reduction.svg'
            },
            {
                id: 5, title: 'Optimization',
                content: 'Distinguish between two options',
                slug: 'unsupervised-learning-optimization',
                image: '/images/machine-learning/panel-classification-optimization.svg'
            },
            {
                id: 6, title: 'Simulation',
                content: 'Generating Simulations',
                slug: 'unsupervised-learning-simulation',
                image: '/images/machine-learning/panel-classification-simulation.svg'
            },

        ];

        this.regressionBasics = [
            {
                id: 1, title: 'What is regression',
                content: 'Installing ML.NET and VSCode interactive',
                slug: 'basic-regression',
                image: '/images/macine-learning/panel-regression.svg'
            },
            {
                id: 2, title: 'Hello Regression',
                content: 'Training a simple ML.NET Regression model',
                slug: 'basic-regression-problem',
                image: '/images/machine-learning/panel-regression.svg'
            },
            {
                id: 3, title: 'What is a regression problem',
                content: 'Installing ML.NET and VSCode interactive',
                slug: 'basic-regression',
                image: '/images/machine-learning/panel-regression.svg'
            },
            {
                id: 4, title: 'Hello Regression',
                content: 'Training a simple ML.NET Regression model',
                slug: 'basic-regression-problem',
                image: '/images/machine-learning/panel-regression.svg'
            },

        ];

        this.nlpBasics = [
            {
                id: 1,
                title: 'Natural Language Processing',
                content: 'Processing and classifying text',
                slug: 'basic-natural-language-processing',
                image: '/images/machine-learning/panel-natural-language-processing.svg'
            },
            {
                id: 2,
                title: 'Large Language Models (LLMs)',
                content: 'Dealing with Large Language Models',
                slug: 'large-language-models',
                image: '/images/machine-learning/panel-large-language-models.svg'
            }

        ];

        this.imageProcessing = [
            {
                id: 1,
                title: 'Image processing in ML',
                content: 'Installing ML.NET and VSCode interactive',
                slug: 'basic-image-processing',
                image: '/images/machine-learning/panel-regression.svg'
            },
            {
                id: 2,
                title: 'Convolutional Neural Networks (CNNs)',
                content: 'How do CNNs work?',
                slug: 'convolutional-neural-network',
                image: '/images/machine-learning/panel-convolutional-image.svg'
            }

        ];

        this.timeSeriesBasics = [
            {
                id: 1, title: 'What is a time series problem',
                content: 'Installing ML.NET and VSCode interactive', slug: 'basic-time-series'
            },
            {
                id: 2,
                title: 'Hello Classification',
                content: 'A basic example showing how to train and evaluate a simple ML.NET model',
                slug: 'basic-time-series-problem'
            }

        ];
    }


    render() {

        return (
            <React.Fragment>
                <div
                    className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-red-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path
                                        d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8"
                                        stroke="#222222" strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2"/>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Getting started
                                        </h2></div>
                                </div>
                                <div className="h-6"/>
                                <p className="leading-snug text-gray-500">A gentle introduction to Machine Learning
                                </p>
                                <div className="h-6"/>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.machineLearningBasics.map((data) => (
                                <LandingPanel key={data.id} title={data.title} imageSource={data.image}
                                              to={"/machine-learning/basics/" + data.slug} strapLine={data.content}/>
                            ))}
                        </div>
                    </div>
                </div>

                <div
                    className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">

                                <svg className="text-blue-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path
                                        d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8"
                                        stroke="#222222" strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2"></div>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Concepts
                                        </h2></div>
                                </div>
                                <div className="h-6"></div>
                                <p className="leading-snug text-gray-500">Some of the theory underpinning Machine
                                    Learning
                                </p>
                                <div className="h-6"></div>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.machineLearningConcepts.map((data) => (
                                <LandingPanel key={data.id} title={data.title} imageSource={data.image}
                                              to={"/machine-learning/concepts/" + data.slug} strapLine={data.content}/>
                            ))}
                        </div>
                    </div>
                </div>


                <div
                    className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-green-400 text-2xl" width="1.5em" height="1.5em"
                                     viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path
                                        d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8"
                                        stroke="#222222" strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2"></div>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Classification
                                        </h2></div>
                                </div>
                                <div className="h-6"></div>
                                <p className="leading-snug text-gray-500">Solving classification problems with machine learning
                                </p>
                                <div className="h-6"></div>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.classificationBasics.map((data) => (
                                <LandingPanel key={data.id} title={data.title} imageSource={data.image}
                                              to={"/machine-learning/classification/" + data.slug} strapLine={data.content}/>
                            ))}
                        </div>
                    </div>
                </div>

                <div
                    className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-purple-400 text-2xl" width="1.5em" height="1.5em"
                                     viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path
                                        d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8"
                                        stroke="#222222" strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2"/>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Regression
                                        </h2></div>
                                </div>
                                <div className="h-6"/>
                                <p className="leading-snug text-gray-500">What are regression problems in machine
                                    learning?
                                </p>
                                <div className="h-6"/>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.regressionBasics.map((data) => (
                                <LandingPanel key={data.id} title={data.title} imageSource={data.image}
                                              to={"/machine-learning/regression/" + data.slug} strapLine={data.content}/>
                            ))}
                        </div>
                    </div>
                </div>
                <div
                    className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-yellow-400 text-2xl" width="1.5em" height="1.5em"
                                     viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path
                                        d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8"
                                        stroke="#222222" strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2"/>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Image Processing
                                        </h2></div>
                                </div>
                                <div className="h-6"/>
                                <p className="leading-snug text-gray-500">Processing images in machine learning
                                </p>
                                <div className="h-6"/>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.imageProcessing.map((data) => (
                                <LandingPanel key={data.id} title={data.title} imageSource={data.image}
                                              to={"/machine-learning/" + data.slug} strapLine={data.content}/>
                            ))}
                        </div>
                    </div>
                </div>
                <div
                    className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-indigo-400 text-2xl" width="1.5em" height="1.5em"
                                     viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path
                                        d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8"
                                        stroke="#222222" strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2"/>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Natural Language Processing (NLP)
                                        </h2></div>
                                </div>
                                <div className="h-6"/>
                                <p className="leading-snug text-gray-500">Processing Text Data
                                </p>
                                <div className="h-6"/>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.nlpBasics.map((data) => (
                                <LandingPanel key={data.id} title={data.title} imageSource={data.image}
                                              to={"/machine-learning/" + data.slug} strapLine={data.content}/>
                            ))}
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };

}

export default MachineLearningLanding;