import React, { Component} from 'react';



class LandingPanel extends Component {

  render() {
    return (

        <React.Fragment>

            <div className={this.props.enabled === false ? "bg-yellow-100 overlay" : "bg-yellow-200"} >
                <div className="h-100 rounded-lg bg-gradient-to-r from-blue-50 to-yellow dark:from-gray-900 dark:to-gray-950 mb-50 flex justify-left shadow-inner">
                    <img alt="Image for News Panel" className="w-20" src={this.props.imageSource} />

                    <div className="flex-fill flex-col ">
                        <div className="flex align-items-end px-1">
                            <div>
                                <h3><a href={this.props.to} className="font-semibold leading-tight mt-6 text-green-800 false">{this.props.title}</a></h3>
                            </div>
                        </div>
                        <p className="px-1 text-gray-400">{this.props.strapLine}</p>
                        <div className="border-gray-800"><a href={this.props.to} className="float-right
                                        hover:text-red-700 pb-0.5 hover:border-gray-500 border-gray-600 px-2">More</a></div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
  }
}

export default LandingPanel;