import React, { Component } from 'react';
import LandingPanel from "../../landingPagePanel/landingPagePanel";

class SparkTutorials extends Component{

    constructor(props){
        super(props);
        this.gettingStartedTutorials = [
            {id: 1,
                title: 'Installing Microsoft Orleans',
                content: 'Ways to install Apache Beam',
                image:"/images/beam/panel-dotnet-console.svg",
                slug:'apache-beam-getting-started',
                enabled: true},
            {id: 2,
                title: 'Training a model in the IDE',
                //content: 'Installing ML.NET and VSCode interactive',
                content: 'An example showing how to create and train a model from within the IDE',
                image:"/images/panel-dotnet-ide.svg",
                slug:'mlnet-getting-started-ide',
                enabled: true},

            {id: 3,
                title: '.NET Interactive Notebooks ',
                //content: 'Installing ML.NET and VSCode interactive',
                content: 'How to get started with .NET interactive notebooks',
                image:"/images/panel-dotnet-interactive.svg",
                slug:'mlnet-dotnet-interactive-notebook',
                enabled: true},
            {id: 4,
                title: 'Interactive Iris classification',
                //content: 'Installing ML.NET and VSCode interactive',
                content: 'The Iris classification problem in an interactive notebook',
                image:"/images/panel-dotnet-interactive.svg",
                slug:'interactive-iris-classification',
                enabled: true}
        ];


        this.simpleExamples = [
            {id: 1,
                title: 'Classification',
                content: 'Classification example using the well known IRIS dataset which classifies Iris flowers based on their features',
                image:"/images/panel-classification.svg",
                slug:'mlnet-classification-tutorial',
                enabled: true},
            {id: 2,
                title: 'Image Classification',
                content: 'How to get started with image classification',
                image:"/images/panel-image-classification.svg",
                slug:'mlnet-binary-image-classification',
                enabled: false}

        ];

        this.tutorials = [
            {id: 1,
                title: 'Simple Linear Regression',
                content: 'Regression example using the well known IRIS dataset which classifies Iris flowers based on their features',
                image:"/images/panel-regression.svg",
                slug:'mlnet-linear-regression',
                enabled: false},
            {id: 2,
                title: 'Time Series Analysis',
                content: 'Investigating how to train and evaluate time series data with ML.NET',
                image:"/images/panel-time-series.svg",
                slug:'interactive-stock-market-prediction',
                enabled: true}

        ];


    }


    render(){

        return (
            <React.Fragment>
                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-red-400 text-2xl" xmlns="http://www.w3.org/2000/svg"
                                     xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false"
                                     role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet"
                                     viewBox="0 0 24 24">
                                    <path className="uim-quaternary"
                                          d="M20.23 7.24L12 12L3.77 7.24a1.98 1.98 0 0 1 .7-.71L11 2.76c.62-.35 1.38-.35 2 0l6.53 3.77c.29.173.531.418.7.71z"
                                          opacity=".25" fill="currentColor"></path>
                                    <path className="uim-tertiary"
                                          d="M12 12v9.5a2.09 2.09 0 0 1-.91-.21L4.5 17.48a2.003 2.003 0 0 1-1-1.73v-7.5a2.06 2.06 0 0 1 .27-1.01L12 12z"
                                          opacity=".5" fill="currentColor"></path>
                                    <path className="uim-primary"
                                          d="M20.5 8.25v7.5a2.003 2.003 0 0 1-1 1.73l-6.62 3.82c-.275.13-.576.198-.88.2V12l8.23-4.76c.175.308.268.656.27 1.01z"
                                          fill="currentColor"></path>
                                </svg>
                                <div className="h-2"></div>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Getting started
                                        </h2></div>
                                </div>
                                <div className="h-6"></div>
                                <p className="leading-snug text-gray-500">A gentle introduction to Machine Learning
                                </p>
                                <div className="h-6"></div>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.gettingStartedTutorials.map((data) => (
                                <LandingPanel title={data.title} imageSource={data.image} to={"/distributed-computing/orleans/basics/" + data.slug} strapLine={data.content}  />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-blue-400 text-2xl" xmlns="http://www.w3.org/2000/svg"
                                     aria-hidden="true" focusable="false"
                                     role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet"
                                     viewBox="0 0 24 24">
                                    <path className="uim-quaternary"
                                          d="M20.23 7.24L12 12L3.77 7.24a1.98 1.98 0 0 1 .7-.71L11 2.76c.62-.35 1.38-.35 2 0l6.53 3.77c.29.173.531.418.7.71z"
                                          opacity=".25" fill="currentColor"></path>
                                    <path className="uim-tertiary"
                                          d="M12 12v9.5a2.09 2.09 0 0 1-.91-.21L4.5 17.48a2.003 2.003 0 0 1-1-1.73v-7.5a2.06 2.06 0 0 1 .27-1.01L12 12z"
                                          opacity=".5" fill="currentColor"></path>
                                    <path className="uim-primary"
                                          d="M20.5 8.25v7.5a2.003 2.003 0 0 1-1 1.73l-6.62 3.82c-.275.13-.576.198-.88.2V12l8.23-4.76c.175.308.268.656.27 1.01z"
                                          fill="currentColor"></path>
                                </svg>
                                <div className="h-2"></div>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Concepts
                                        </h2></div>
                                </div>
                                <div className="h-6"></div>
                                <p className="leading-snug text-gray-500">Some of the theory underpinning Machine Learning
                                </p>
                                <div className="h-6"></div>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.simpleExamples.map((data) => (
                                <LandingPanel title={data.title} imageSource={data.image} to={"/distributed-computing/orleans/examples/" + data.slug} strapLine={data.content}  />
                            ))}
                        </div>
                    </div>
                </div>


                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-green-400 text-2xl" xmlns="http://www.w3.org/2000/svg"
                                     aria-hidden="true" focusable="false"
                                     role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet"
                                     viewBox="0 0 24 24">
                                    <path className="uim-quaternary"
                                          d="M20.23 7.24L12 12L3.77 7.24a1.98 1.98 0 0 1 .7-.71L11 2.76c.62-.35 1.38-.35 2 0l6.53 3.77c.29.173.531.418.7.71z"
                                          opacity=".25" fill="currentColor"></path>
                                    <path className="uim-tertiary"
                                          d="M12 12v9.5a2.09 2.09 0 0 1-.91-.21L4.5 17.48a2.003 2.003 0 0 1-1-1.73v-7.5a2.06 2.06 0 0 1 .27-1.01L12 12z"
                                          opacity=".5" fill="currentColor"></path>
                                    <path className="uim-primary"
                                          d="M20.5 8.25v7.5a2.003 2.003 0 0 1-1 1.73l-6.62 3.82c-.275.13-.576.198-.88.2V12l8.23-4.76c.175.308.268.656.27 1.01z"
                                          fill="currentColor"></path>
                                </svg>
                                <div className="h-2"></div>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Classification
                                        </h2></div>
                                </div>
                                <div className="h-6"></div>
                                <p className="leading-snug text-gray-500">Ad introduction to classification with ML.NET
                                </p>
                                <div className="h-6"></div>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.tutorials.map((data) => (
                                <LandingPanel title={data.title} imageSource={data.image} to={"/distributed-computing/orleans/tutorials/" + data.slug} strapLine={data.content}  />
                            ))}
                        </div>
                    </div>
                </div>

                {/*<div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">*/}
                {/*    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">*/}
                {/*        <div className="lg:pr-12 lg:place-self-top">*/}
                {/*            <div className="flex flex-col ">*/}
                {/*                <svg className="text-purple-400 text-2xl" xmlns="http://www.w3.org/2000/svg"*/}
                {/*                     xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false"*/}
                {/*                     role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet"*/}
                {/*                     viewBox="0 0 24 24">*/}
                {/*                    <path className="uim-quaternary"*/}
                {/*                          d="M20.23 7.24L12 12L3.77 7.24a1.98 1.98 0 0 1 .7-.71L11 2.76c.62-.35 1.38-.35 2 0l6.53 3.77c.29.173.531.418.7.71z"*/}
                {/*                          opacity=".25" fill="currentColor"></path>*/}
                {/*                    <path className="uim-tertiary"*/}
                {/*                          d="M12 12v9.5a2.09 2.09 0 0 1-.91-.21L4.5 17.48a2.003 2.003 0 0 1-1-1.73v-7.5a2.06 2.06 0 0 1 .27-1.01L12 12z"*/}
                {/*                          opacity=".5" fill="currentColor"></path>*/}
                {/*                    <path className="uim-primary"*/}
                {/*                          d="M20.5 8.25v7.5a2.003 2.003 0 0 1-1 1.73l-6.62 3.82c-.275.13-.576.198-.88.2V12l8.23-4.76c.175.308.268.656.27 1.01z"*/}
                {/*                          fill="currentColor"></path>*/}
                {/*                </svg>*/}
                {/*                <div className="h-2"></div>*/}
                {/*                <div className="flex  rounded-lg">*/}
                {/*                    <div>*/}
                {/*                        <h2 className="text-2xl font-semibold*/}
                {/*							leading-tight text-gray-800 false">Regression*/}
                {/*                        </h2></div>*/}
                {/*                </div>*/}
                {/*                <div className="h-6"></div>*/}
                {/*                <p className="leading-snug text-gray-500">An introduction to regression with ML.NET*/}
                {/*                </p>*/}
                {/*                <div className="h-6"></div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">*/}
                {/*            {this.regressionTutorials.map((data) => (*/}
                {/*                <LandingPanel title={data.title} imageSource={data.image} to={"/machine-learning/" + data.slug} strapLine={data.content}  />*/}
                {/*            ))}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </React.Fragment>
        )

    };

}

export default SparkTutorials;