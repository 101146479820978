import React, { Component } from 'react'

import Add from "@mui/icons-material/Add"
import Remove from "@mui/icons-material/Remove"


import $ from 'jquery';

class PlaygroundComponent extends Component {


    componentDidMount() {
        console.log("Attempting to execute initialization script");
        $.ajax({
            type: "GET",
            url: "lib.js",
            dataType: "script",
            async: false,
            success: function(){console.log("Lib script has been loaded successfully");},
            fail: function(){console.log("Lib Script has failed to load");}
        });
        //
        $.ajax({
            type: "GET",
            url: "bundle_format.js",
            dataType: "script",
            async: false,
            success: function(){console.log("Bundle script has been loaded successfully");},
            fail: function(){console.log("Bundle Script has failed to load");}
        });
    }

    render() {

        return (

            <div className="w-full bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900">
                <div style={{width: "90%", margin: "0 auto"}} className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900">
                    <h1 className="text-3xl font-bold text-center text-gray-800 pt-2">Neural Network Playground</h1>
                    <br></br>
                    <div>
                        <p className="prose">
                            Welcome to the Neural Network playground. This is a tool originally developed for learning purposes at <a href={"https://tensorflow.org"}>Tensorflow</a> and the original source code is <a href={"https://github.com/tensorflow/playground"}>here</a>. It is backed by a simple Neural Network (NN)
                            and allows you to see the effects of changing some of the typical parameters for a NN in a more graphical manner. You can alter training data size, activation function used, number of layers and nodes etc and see the
                            impact it has on the output to help give you a better understanding of what's going on in teh background when you alter these parameters.
                        </p>
                    </div>
                {/*<a className="github-link" href="https://github.com/tensorflow/playground" title="Source on GitHub" target="_blank">*/}
                {/*    <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" viewBox="0 0 60.5 60.5" width="60" height="60">*/}
                {/*        <polygon className="bg" points="60.5,60.5 0,0 60.5,0 "/>*/}
                {/*    <path className="icon" d="M43.1,5.8c-6.6,0-12,5.4-12,12c0,5.3,3.4,9.8,8.2,11.4c0.6,0.1,0.8-0.3,0.8-0.6c0-0.3,0-1,0-2c-3.3,0.7-4-1.6-4-1.6c-0.5-1.4-1.3-1.8-1.3-1.8c-1.1-0.7,0.1-0.7,0.1-0.7c1.2,0.1,1.8,1.2,1.8,1.2c1.1,1.8,2.8,1.3,3.5,1c0.1-0.8,0.4-1.3,0.8-1.6c-2.7-0.3-5.5-1.3-5.5-5.9c0-1.3,0.5-2.4,1.2-3.2c-0.1-0.3-0.5-1.5,0.1-3.2c0,0,1-0.3,3.3,1.2c1-0.3,2-0.4,3-0.4c1,0,2,0.1,3,0.4c2.3-1.6,3.3-1.2,3.3-1.2c0.7,1.7,0.2,2.9,0.1,3.2c0.8,0.8,1.2,1.9,1.2,3.2c0,4.6-2.8,5.6-5.5,5.9c0.4,0.4,0.8,1.1,0.8,2.2c0,1.6,0,2.9,0,3.3c0,0.3,0.2,0.7,0.8,0.6c4.8-1.6,8.2-6.1,8.2-11.4C55.1,11.2,49.7,5.8,43.1,5.8z"/>*/}
                {/*    </svg>*/}
                {/*</a>*/}

                <div id="top-controls">
                    <div className="container l--page">
                        <div className="timeline-controls">
                            <button className="mdl-button mdl-js-button mdl-button--icon ui-resetButton"
                                    id="reset-button" title="Reset the network">
                                    <i className="material-icons">replay</i>

                            </button>

                            <button
                                className="mdl-button mdl-js-button mdl-button--fab mdl-button--colored ui-playButton"
                                id="play-pause-button" title="Run/Pause" >
                                <i className="material-icons display-button" >play_arrow</i>
                                <i className="material-icons display-button">pause</i>
                            </button>
                            <button className="mdl-button mdl-js-button md-button--icon ui-stepButton"
                                    id="next-step-button" title="Step">
                                <i className="material-icons">skip_next</i>
                            </button>
                        </div>
                        <div className="control">
                            <span className="label">Epoch</span>
                            <span className="value" id="iter-number" />
                        </div>
                        <div className="control ui-learningRate">
                            <label htmlFor="learningRate">Learning rate</label>
                            <div className="select">
                                <select id="learningRate">
                                    <option value="0.00001">0.00001</option>
                                    <option value="0.0001">0.0001</option>
                                    <option value="0.001">0.001</option>
                                    <option value="0.003">0.003</option>
                                    <option value="0.01">0.01</option>
                                    <option value="0.03">0.03</option>
                                    <option value="0.1">0.1</option>
                                    <option value="0.3">0.3</option>
                                    <option value="1">1</option>
                                    <option value="3">3</option>
                                    <option value="10">10</option>
                                </select>
                            </div>
                        </div>
                        <div className="control ui-activation">
                            <label htmlFor="activations">Activation</label>
                            <div className="select">
                                <select id="activations">
                                    <option value="relu">ReLU</option>
                                    <option value="tanh">Tanh</option>
                                    <option value="sigmoid">Sigmoid</option>
                                    <option value="linear">Linear</option>
                                </select>
                            </div>
                        </div>
                        <div className="control ui-regularization">
                            <label htmlFor="regularizations">Regularization</label>
                            <div className="select">
                                <select id="regularizations">
                                    <option value="none">None</option>
                                    <option value="L1">L1</option>
                                    <option value="L2">L2</option>
                                </select>
                            </div>
                        </div>
                        <div className="control ui-regularizationRate">
                            <label htmlFor="regularRate">Regularization rate</label>
                            <div className="select">
                                <select id="regularRate">
                                    <option value="0">0</option>
                                    <option value="0.001">0.001</option>
                                    <option value="0.003">0.003</option>
                                    <option value="0.01">0.01</option>
                                    <option value="0.03">0.03</option>
                                    <option value="0.1">0.1</option>
                                    <option value="0.3">0.3</option>
                                    <option value="1">1</option>
                                    <option value="3">3</option>
                                    <option value="10">10</option>
                                </select>
                            </div>
                        </div>
                        <div className="control ui-problem">
                            <label htmlFor="problem">Problem type</label>
                            <div className="select">
                                <select id="problem">
                                    <option value="classification">Classification</option>
                                    <option value="regression">Regression</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                    <div id="main-part" className="l--page">

                        <div className="column data">
                            <h4>
                                <span>Data</span>
                            </h4>
                            <div className="ui-dataset">
                                <p>Which dataset do you want to use?</p>
                                <div className="dataset-list">
                                    <div className="dataset" title="Circle">
                                        <canvas className="data-thumbnail" data-dataset="circle" />
                                    </div>
                                    <div className="dataset" title="Exclusive or">
                                        <canvas className="data-thumbnail" data-dataset="xor" />
                                    </div>
                                    <div className="dataset" title="Gaussian">
                                        <canvas className="data-thumbnail" data-dataset="gauss" />
                                    </div>
                                    <div className="dataset" title="Spiral">
                                        <canvas className="data-thumbnail" data-dataset="spiral" />
                                    </div>
                                    <div className="dataset" title="Plane">
                                        <canvas className="data-thumbnail" data-reg-dataset="reg-plane" />
                                    </div>
                                    <div className="dataset" title="Multi gaussian">
                                        <canvas className="data-thumbnail" data-reg-dataset="reg-gauss" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="ui-percTrainData">
                                    <label htmlFor="percTrainData">Ratio of training to test data:&nbsp;&nbsp;<span
                                        className="value">XX</span>%</label>
                                    <p className="slider">
                                        <input className="mdl-slider mdl-js-slider" type="range" id="percTrainData"
                                               min="10" max="90" step="10" />
                                    </p>
                                </div>
                                <div className="ui-noise">
                                    <label htmlFor="noise">Noise:&nbsp;&nbsp;<span className="value">XX</span></label>
                                    <p className="slider">
                                        <input className="mdl-slider mdl-js-slider" type="range" id="noise" min="0"
                                               max="50" step="5" />
                                    </p>
                                </div>
                                <div className="ui-batchSize">
                                    <label htmlFor="batchSize">Batch size:&nbsp;&nbsp;<span className="value">XX</span></label>
                                    <p className="slider">
                                        <input className="mdl-slider mdl-js-slider" type="range" id="batchSize" min="1"
                                               max="30" step="1" />
                                    </p>
                                </div>
                                <button className="basic-button" id="data-regen-button" title="Regenerate data">
                                    Regenerate
                                </button>
                            </div>
                        </div>

                        <div className="column features">
                            <h4>Features</h4>
                            <p>Which properties do you want to feed in?</p>
                            <div id="network">
                                <svg id="svg" width="510" height="450">
                                    <defs>
                                        <marker id="markerArrow" markerWidth="7" markerHeight="13" refX="1" refY="6"
                                                orient="auto" markerUnits="userSpaceOnUse">
                                            <path d="M2,11 L7,6 L2,2"/>
                                        </marker>
                                    </defs>
                                </svg>
                                <div id="hovercard">
                                    <div style={{fontSize: "10px"}}>Click anywhere to edit.</div>
                                    <div><span className="type">Weight/Bias</span> is <span className="value">0.2</span><span><input
                                        type="number"/></span>.
                                    </div>
                                </div>
                                <div className="callout thumbnail">
                                    <svg viewBox="0 0 30 30">
                                        <defs>
                                            <marker id="arrow" markerWidth="5" markerHeight="5" refX="5" refY="2.5"
                                                    orient="auto" markerUnits="userSpaceOnUse">
                                                <path d="M0,0 L5,2.5 L0,5 z" />
                                            </marker>
                                        </defs>
                                        <path d="M12,30C5,20 2,15 12,0" markerEnd="url(#arrow)" />
                                    </svg>
                                    <div className="label">
                                        This is the output from one <b>neuron</b>. Hover to see it larger.
                                    </div>
                                </div>
                                <div className="callout weights">
                                    <svg viewBox="0 0 30 30">
                                        <defs>
                                            <marker id="arrow" markerWidth="5" markerHeight="5" refX="5" refY="2.5"
                                                    orient="auto" markerUnits="userSpaceOnUse">
                                                <path d="M0,0 L5,2.5 L0,5 z"/>
                                            </marker>
                                        </defs>
                                        <path d="M12,30C5,20 2,15 12,0" markerEnd="url(#arrow)" />
                                    </svg>
                                    <div className="label">
                                        The outputs are mixed with varying <b>weights</b>, shown by the thickness of the
                                        lines.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="column hidden-layers">
                            <h4>
                                <div className="ui-numHiddenLayers">
                                    <button id="add-layers" className="mdl-button mdl-js-button mdl-button--icon display-button-small">
                                        {/*<i className="material-icons">add</i>*/}
                                        <Add style={{margin: "0 auto"}} />
                                    </button>
                                    <button id="remove-layers" className="mdl-button mdl-js-button mdl-button--icon">
                                        {/*<i className="material-icons">remove</i>*/}
                                        <Remove />
                                    </button>
                                </div>
                                <span id="num-layers"></span>
                                <span id="layers-label"></span>
                            </h4>
                            <div className="bracket"></div>
                        </div>

                        <div className="column output">
                            <h4>Output</h4>
                            <div className="metrics">
                                <div className="output-stats ui-percTrainData">
                                    <span>Test loss</span>
                                    <div className="value" id="loss-test"></div>
                                </div>
                                <div className="output-stats train">
                                    <span>Training loss</span>
                                    <div className="value" id="loss-train"></div>
                                </div>
                                <div id="linechart"></div>
                            </div>
                            <div id="heatmap"></div>
                            <div style={{float: "left", marginTop: "20px"}}>
                                <div style={{display: "flex", alignItems: "center" }}>

                                    <div className="label" style={{ width: "105px" , marginRight: "10px"}}>
                                        Colors show data, neuron and weight values.
                                    </div>
                                    <svg width="150" height="30" id="colormap">
                                        <defs>
                                            <linearGradient id="gradient" x1="0%" y1="100%" x2="100%" y2="100%">
                                                <stop offset="0%" stopColor="#f59322" stopOpacity="1"></stop>
                                                <stop offset="50%" stopColor="#e8eaeb" stopOpacity="1"></stop>
                                                <stop offset="100%" stopColor="#0877bd" stopOpacity="1"></stop>
                                            </linearGradient>
                                        </defs>
                                        <g className="core" transform="translate(3, 0)">
                                            {/*<rect width="144" height="10" style={{fill: "url('#gradient')"}}></rect>*/}
                                            <rect width="144" height="10" style={{fill: "url('#gradient')"}}></rect>
                                        </g>
                                    </svg>
                                </div>
                                <br/>
                                <div style={{display: "flex"}}>
                                    <label className="ui-showTestData mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect"
                                           htmlFor="show-test-data">
                                        <input type="checkbox" id="show-test-data" className="mdl-checkbox__input"
                                               defaultChecked={true}/>
                                            <span className="mdl-checkbox__label label">Show test data</span>
                                    </label>
                                    <label className="ui-discretize mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect"
                                           htmlFor="discretize">
                                        <input type="checkbox" id="discretize" className="mdl-checkbox__input" defaultChecked={true} />
                                           <span className="mdl-checkbox__label label">Discretize output</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                </div>
                );

    }
}

export default PlaygroundComponent;