import React, {Component} from 'react';

import Prism from '../../prism-plugins-tomorrow'
import $ from "jquery";
import {withParams} from "../../App";
import {attachCopyTagHandlers} from "../../utility";
import PageComponent from "../pageComponent/pageComponent";

class NewsPage extends Component {


    state = {
        title: '',
        tags: [],
        html: '',
        nextContent:'',
        relatedContent: [],
        sourceLinks: [],
        suggestedCourses: [],
        algos: [],
        languages: [],
        slug: '',
        attrib:[]
    };

  slugs = [
    {slug: 'what-is-distributed-computing',
        source: '/news/what-is-distributed-computing.html',
        title: 'What is Distributed Computing',
        tags: ['Distributed', 'Network'],
        sourceLinks:[],
        languages: [], attrib:[] },
    {slug: 'brief-history-of-distributed-computing',
        source: '/dc/brief-history-of-distributed-computing.html',
        title: 'How id distributed computing come about',
        tags: ['Distributed'],
        sourceLinks:[],
        languages: [] },
    {slug: 'distributed-computing-frameworks',
        source: '/dc/distributed-computing-frameworks.html',
        title: 'Some common distributed computing frameworks',
        tags: ['Distributed', 'Frameworks'],
        sourceLinks:[],
        languages: [] },
    {slug: 'how-do-distributed-frameworks-operate',
        source: '/dc/distributed-computing-frameworks-operation.html',
        title: 'How do Distributed Frameworks Operate',
        tags: ['Distributed', 'Frameworks'],
        sourceLinks:[],
        languages: [] },
    {slug: 'instrumenting-your-code',
        source: '/dc/instrumenting-your-code.html',
        title: 'Instrumenting Your Code',
        tags: ['Distributed', 'Observability', 'Tracing'],
        sourceLinks:[],
        languages: [] },
    {slug: 'microsoft-orleans-scheduling',
        source: '/dc/html/orleans/orleans-scheduling.html',
        title: 'Microsoft Orleans Scheduling',
        tags: ['Distributed', 'Orleans'],
        sourceLinks:[],
        languages: [] },
    {slug: 'microsoft-orleans-state',
        source: '/dc/html/orleans/orleans-state.html',
        title: 'Microsoft Orleans State',
        tags: ['Distributed', 'Orleans'],
        sourceLinks:[],
        languages: [] },
    {slug: 'microsoft-orleans-persistence',
        source: '/dc/html/orleans/orleans-grain-persistence.html',
        title: 'Microsoft Orleans Persistence',
        tags: ['Distributed', 'Orleans'],
        sourceLinks:[],
        languages: [] },

    {slug: 'observability-in-distributed-computing',
        source: '/dc/observability-in-distributed-computing.html',
        title: 'Observability in Distributed Computing',
        tags: ['Distributed', 'Orleans', 'Observability'],
        sourceLinks:[],
        languages: [] },
    {slug: 'zipkin',
        source: '/dc/distributed-computing-zipkin.html',
        title: 'Zipkin',
        tags: ['Distributed', 'Observability', 'Zipkin'],
        sourceLinks:[],
        languages: [] },
      {slug: 'jaeger',
          source: '/dc/distributed-computing-jaeger.html',
          title: 'Jaeger Tracing',
          tags: ['Distributed', 'Observability', 'Tracing'],
          sourceLinks:[],
          algos: [],
          languages: [] },
    {slug: 'grafana',
        source: '/dc/distributed-computing-grafana.html',
        title: 'Grafana',
        tags: ['Distributed', 'Observability', 'Grafana'],
        sourceLinks:[],
        languages: [] },
    {slug: 'prometheus',
        source: '/dc/distributed-computing-prometheus.html',
        title: 'Prometheus',
        tags: ['Distributed', 'Observability', 'Prometheus'],
        sourceLinks:[],
        languages: [] },
    {slug: 'distributed-computing-scalability',
        source: '/dc/distributed-computing-scalability.html',
        title: 'Scalability',
        tags: ['Distributed', 'Observability', 'Scalability'],
        sourceLinks:[],
        languages: [] },
    {slug: 'distributed-computing-reliability',
        source: '/dc/distributed-computing-reliability.html',
        title: 'Reliability',
        tags: ['Distributed', 'Observability', 'Reliability'],
        sourceLinks:[],
        languages: [] },
    {slug: 'distributed-computing-messaging',
        source: '/dc/distributed-computing-messaging.html',
        title: 'Distributed Messaging',
        tags: ['Distributed', 'Messaging', 'Asynchronous'],
        sourceLinks:[],
        languages: [],
        attrib: ['https://www.csl.cornell.edu/~delimitrou/papers/2019.asplos.microservices.pdf'] },
    {slug: 'stream-processing-introduction',
        source: '/dc/distributed-computing-stream-processing.html',
        title: 'Stream Processing',
        tags: ['Distributed', 'Observability', 'Stream'],
        sourceLinks:[],
        languages: [] },
    {slug: 'batch-processing-introduction',
        source: '/dc/distributed-computing-batch-processing.html',
        title: 'Batch Processing',
        tags: ['Distributed', 'Observability', 'Batch'],
        sourceLinks:[],
        languages: [] },
      {slug: 'batch-processing-example',
          source: '/dc/distributed-computing-batch-processing-example.html',
          title: 'Batch Processing Example',
          tags: ['Distributed', 'Observability', 'Batch'],
          sourceLinks:[],
          languages: [] },
      {slug: 'stream-processing-example',
          source: '/dc/distributed-computing-stream-processing-example.html',
          title: 'Stream Processing Example',
          tags: ['Distributed', 'Observability', 'Stream'],
          sourceLinks:[],
          languages: [] },
      {slug: 'open-telemetry-tracing',
          source: '/dc/distributed-computing-open-telemetry.html',
          title: 'Tracing and Instrumentation',
          tags: ['Distributed', 'Observability', 'Tracing'],
          sourceLinks:[],
          languages: [] },

  ];


    constructor(props) {
        super(props);
        this.state.slug = props.params.slug;
    }

    componentDidUpdate(prevProps, prevState, False) {
        console.log('NewsPage: Rebinding jquery hooks after document update');
        // console.log('MarkdownDisplay: component updating ' + this.state.html);

        // Connect Copy and Tag functionality
        attachCopyTagHandlers();

        $("div[data-mime-type='application/vnd.jupyter.stderr']").hide();

        setTimeout(() => Prism.highlightAll(), 0);
    }

    componentDidUnMount() {
        console.log('News Page component UNMOUNTED');
    }


    componentDidMount() {

        // Get the contents from the HTML file and put them in the React state, so we can reference it in render() below.
        const path = this.slugs.filter(path => path.slug === this.state.slug);
        console.log("OP Slug [" + this.state.slug + "] matched to url [" + path[0].source + "]")

        if (path.length === 0) {
            console.error('DP Failed to located path for : ' + this.state.slug);
            //this.setState((state) => ({ ...state, "Failed to located requested content" }));
        }

        const next = this.nextPage.filter(item => item.key === this.state.slug)[0];
        const related = this.relatedPages.filter(item => item.key === this.state.slug);
        const suggested = this.suggestedCourses.filter(item => item.key === this.state.slug);

        this.setState({
            nextContent: next !== undefined ? next : {
                key: '',
                slug: '',
                title: 'News',
                path: '/news/'
            },
            relatedContent: related,
            title: path[0].title,
            tags: path[0].tags,
            sourceLinks: path[0].sourceLinks,
            languages: path[0].languages,
            algos: path[0].algos !== undefined ? path[0].algos : [],
            suggestedCourses: [],
            attributed: path[0].attributed

        });
        if (this.state) {
            console.log("News Next Page Value determined as : " + this.state.nextContent);
        }

        fetch(path[0].source)
            .then(res => res.text())
            .then(html => this.setState((state) => ({...state, html})))
            //.then(html => console.log(html))
            .catch((err) => console.error(err));
        console.log("News Page content was successfully retrieved ");

        console.log('News component mounted ' + this.state.html);
    }


    render() {

        return (
            <PageComponent props={this.state} page="News Page"/>
        );

    }
}


export default withParams(NewsPage);