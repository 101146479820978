import React, {useEffect} from 'react';
import {
    BrowserRouter as Router,
    Route, Routes, useLocation
} from 'react-router-dom'

import Header from "./components/headerComponent/header";
import Footer from "./components/footerComponent/footer";
import Homepage from "./components/pages/homepage";
import MLExamples from './components/pages/MLExamples';
import Prism from 'prismjs'

import MarkdownDisplay from './components/markdownComponent/MLNetLanding'
import MachineLearning from './components/mlComponent/mlComponent';
import DCPage from './components/dcComponent/dcComponent';
import NewsPage from './components/newsComponent/newsComponent';
import {NotFound} from './components/pages/notFound';

import './assets/css/default.min.css';
import PlaygroundComponent from './components/playground/playgroundComponent';
import DataExplorer from "./components/dataExplorerComponent/dataExplorer";
import DCLanding from "./components/topicLanding/distributed-computing-landing";
import OrleansLanding from "./components/distributed/orleans/orleansComponent";
import OrleansTutorials from "./components/distributed/orleans/orleansTutorials";

import BeamLanding from "./components/distributed/beam/beamComponent";
import BeamTutorials from "./components/distributed/beam/beamTutorials";

import Spark from "./components/distributed/spark/sparkComponent";
import SparkTutorials from "./components/distributed/spark/sparkTutorials";
import OrleansPage from "./components/distributed/orleans/orleansPage";

import Courses from "./components/coursesComponent/coursesComponent";
import Account from "./components/accountComponent/account"
import Register from "./components/registrationComponent/register"
import {useParams} from "react-router";
import {Maintenance} from "./components/pages/notFound";
import BeamPage from "./components/distributed/beam/beamPage";
import SparkPage from "./components/distributed/spark/sparkPage";
import AkkaLanding from "./components/distributed/akka/akkaComponent";
import AkkaTutorials from "./components/distributed/akka/akkaTutorials";
import AkkaPage from "./components/distributed/akka/akkaPage";
import StormTutorials from "./components/distributed/storm/stormTutorials";
import StormLanding from "./components/distributed/storm/stormComponent";
import StormPage from "./components/distributed/storm/stormPage";
import HeronTutorials from "./components/distributed/heron/heronTutorials";
import HeronLanding from "./components/distributed/heron/heronComponent";
import HeronPage from "./components/distributed/heron/heronPage";
import MachineLearningLanding from "./components/topicLanding/machine-learning-landing";
import MachineLearningPage from "./components/mlComponent/mlComponent";
import MLNETTutorialLanding from "./components/tutorialLanding/mlnet-tutorial-landing";
import ScipyTutorialLanding from "./components/tutorialLanding/scipy-tutorial-landing";
import ScikitLearnTutorial from "./components/tutorialLanding/scikit-tutorial-landing";
import PytorchTutorial from "./components/pages/ml/pytorch-tutorial";
import TensorflowTutorial from "./components/tutorialLanding/tensorflow-tutorial";
import ScipyPage from "./components/mlComponent/scipyComponent";
import MLNETPage from "./components/markdownComponent/MLNetLanding";
//import MLNETPage from "./components/markdownComponent/MLNetLanding";


function App() {
    const maintenance = false;


  return (
      <>
          {maintenance ? (<Maintenance/>) : (
          <Router>
              <Header/>
              <Routes>
                  <Route path='/' element={<Homepage/>}/>

                  <Route path='/playground' element={<PlaygroundComponent/>}/>
                  <Route path='/examples' element={<MLExamples/>}/>
                  <Route path='/news/:slug' element={<NewsPage/>}/>

                  <Route path='/machine-learning' element={<MachineLearningLanding/>}/>
                  <Route path='/machine-learning/basics/:slug' element={<MachineLearningPage/>}/>
                  <Route path='/machine-learning/concepts/:slug' element={<MachineLearningPage/>}/>
                  <Route path='/machine-learning/classification/:slug' element={<MachineLearningPage/>}/>
                  <Route path='/machine-learning/regression/:slug' element={<MachineLearningPage/>}/>


                  <Route path='/machine-learning/tutorials/mlnet/' element={<MLNETTutorialLanding/>}/>
                  <Route path='/machine-learning/tutorials/mlnet/:slug' element={<MLNETPage/>}/>
                  <Route path='/machine-learning/examples/mlnet' element={<MLExamples/>}/>


                  <Route path='/machine-learning/tutorials/scipy/' element={<ScipyTutorialLanding/>}/>
                  <Route path='/machine-learning/tutorials/scipy/:slug' element={<ScipyPage/>}/>
                  <Route path='/machine-learning/examples/scipy' element={<MLExamples/>}/>

                  <Route path='/machine-learning/tutorials/scikit-learn/' element={<ScikitLearnTutorial/>}/>
                  <Route path='/machine-learning/examples/scikit-learn' element={<MLExamples/>}/>

                  <Route path='/machine-learning/tutorials/pytorch/' element={<PytorchTutorial/>}/>
                  <Route path='/machine-learning/examples/pytorch' element={<MLExamples/>}/>

                  <Route path='/machine-learning/tutorials/tensorflow/' element={<TensorflowTutorial/>}/>
                  <Route path='/machine-learning/examples/tensorflow' element={<MLExamples/>}/>



                  {/*Routing for Python Machine learning*/}
                  <Route path='/machine-learning/python/examples/:slug' element={<MachineLearning/>}/>
                  <Route path='/machine-learning/python/tutorials/:slug' element={<MarkdownDisplay/>}/>

                  {/*Routing for Distributed Computing*/}
                  <Route path='/distributed-computing' element={<DCLanding/>}/>
                  <Route path='/distributed-computing/basics/:slug' element={<DCPage/>}/>
                  <Route path='/distributed-computing/concepts/:slug' element={<DCPage/>}/>
                  <Route path='/distributed-computing/observability/:slug' element={<DCPage/>}/>
                  <Route path='/distributed-computing/batch-procesing/:slug' element={<DCPage/>}/>
                  <Route path='/distributed-computing/stream-processing/:slug' element={<DCPage/>}/>

                  <Route path='/distributed-computing/microsoft-orleans/' element={<OrleansLanding/>}/>
                  <Route path='/distributed-computing/microsoft-orleans/concepts/:slug' element={<OrleansPage/>}/>
                  <Route path='/distributed-computing/microsoft-orleans/tutorials' element={<OrleansTutorials/>}/>
                  <Route path='/distributed-computing/microsoft-orleans/tutorials/:slug' element={<OrleansPage/>}/>
                  <Route path='/distributed-computing/microsoft-orleans/examples/:slug' element={<OrleansPage/>}/>

                  <Route path='/distributed-computing/apache-beam' element={<BeamLanding/>}/>
                  <Route path='/distributed-computing/apache-beam/tutorials/' element={<BeamTutorials/>}/>
                  <Route path='/distributed-computing/apache-beam/tutorials/:slug' element={<BeamPage/>}/>

                  <Route path='/distributed-computing/apache-spark' element={<Spark/>}/>
                  <Route path='/distributed-computing/apache-spark/tutorials/' element={<SparkTutorials/>}/>
                  <Route path='/distributed-computing/apache-spark/tutorials/:slug' element={<SparkPage/>}/>

                  <Route path='/distributed-computing/akka' element={<AkkaLanding/>}/>
                  <Route path='/distributed-computing/akka/tutorials/' element={<AkkaTutorials/>}/>
                  <Route path='/distributed-computing/akka/tutorials/:slug' element={<AkkaPage/>}/>

                  <Route path='/distributed-computing/akka' element={<HeronLanding/>}/>
                  <Route path='/distributed-computing/akka/tutorials/' element={<HeronTutorials/>}/>
                  <Route path='/distributed-computing/akka/tutorials/:slug' element={<HeronPage/>}/>


                  <Route path='/distributed-computing/akka' element={<StormLanding/>}/>
                  <Route path='/distributed-computing/akka/tutorials/' element={<StormTutorials/>}/>
                  <Route path='/distributed-computing/akka/tutorials/:slug' element={<StormPage/>}/>


                  <Route path='/machine-learning/courses/' element={<Courses/>}/>

                  <Route path='/data/explore/:slug' element={<DataExplorer/>}/>
                  <Route path='/account' element={<Account/>}/>
                  <Route path='/register' element={<Register/>}/>

                  <Route element={<NotFound/>}/>
              </Routes>
              <Footer/>
          </Router>
      )}
      </>

  );
}

export function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}


export function usePrismHighlightAll() {
    useEffect(() => {
        Prism.highlightAll();

    }, [])
}

export async function copyTextToClipboard(text) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return navigator.clipboard.writeText(text);
  }
}

export function ErrorFallback({error, componentStack, resetErrorBoundary}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <pre>{componentStack}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}


// Helper method to get the path for sharing functionality
export async function usePathname() {
  const location = useLocation();
  return location.pathname;
}

export default App;
