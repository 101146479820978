import React, { Component } from 'react';

class Footer extends Component {

    render(){
        return (
            <footer>
                <div className="container pb-32">
                    <div className="grid md:grid-cols-4 gap-x-4 border-t border-gray-200 pt-12">
                        {/*  <div>
                            <div className="font-semibold text-lg mb-4">Site</div>
                            <ul className="space-y-2 text-gray-400">
                                <li><a className="hover:underline" href="/">Home</a></li>
                                <li><a className="hover:underline" href="/machine-learning">Machine Learning</a></li>
                                <li><a className="hover:underline" href="/machine-learning/mlnet/tutorials">Machine Learning Tutorials</a></li>
                                <li><a className="hover:underline" href="/orleans">Microsoft Orleans</a></li>
                                <li><a className="hover:underline" href="/orleans/tutorials">Orleans Tutorials</a></li>
                                <li><a className="hover:underline" href="/playground">Machine Learning Playground</a></li>
                            </ul>
                        </div>*/}
                        <div>
                            <div className="font-semibold text-lg mb-4">Machine Learning</div>
                            <ul className="space-y-2 text-gray-400">
                                <li><a className="hover:underline" href="/src/components/topicLanding/machine-learning">Machine Learning</a>
                                </li>
                                <li><a className="hover:underline" href="/machine-learning/mlnet/tutorials">MLNET
                                    Tutorials</a></li>
                              {/*  <li><a className="hover:underline" href="/machine-learning/tensorflow/tutorials">Tensorflow
                                    Tutorials</a></li>
                                <li><a className="hover:underline" href="/machine-learning/pytorch/tutorials">PyTorch
                                    Tutorials</a></li>
                                <li><a className="hover:underline" href="/machine-learning/scikit/tutorials">Scikit-Learn
                                    Tutorials</a></li>*/}
                                <li><a className="hover:underline" href="/playground">Machine Learning
                                    Playground</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="font-semibold text-lg mb-4">Distributed Computing</div>
                            <ul className="space-y-2 text-gray-600">
                                <li><a className="hover:underline" href="/distributed-computing/microsoft-orleans">Microsoft
                                    Orleans</a></li>
                                <li><a className="hover:underline"
                                       href="/distributed-computing/microsoft-orleans/tutorials">Microsoft Orleans
                                    Tutorials</a></li>
                             {/*   <li><a className="hover:underline" href="/distributed-computing/apache-beam">Apache
                                    Beam</a></li>
                                <li><a className="hover:underline"
                                       href="/distributed-computing/apache-beam/tutorials">Apache
                                    Beam Tutorials</a></li>
                                <li><a className="hover:underline" href="/distributed-computing/apache-heron">Apache
                                    Heron</a></li>
                                <li><a className="hover:underline"
                                       href="/distributed-computing/apache-heron/tutorials">Apache
                                    Heron Tutorials</a></li>
                                <li><a className="hover:underline" href="/distributed-computing/apache-spark">Apache
                                    Spark</a></li>
                                <li><a className="hover:underline"
                                       href="/distributed-computing/apache-spark/tutorials">Apache
                                    Spark Tutorials</a></li>
                                <li><a className="hover:underline" href="/distributed-computing/akka">AKKA</a></li>
                                <li><a className="hover:underline" href="/distributed-computing/akka/tutorials">AKKA
                                    Tutorials</a></li>*/}
                            </ul>
                        </div>
                        <div>
                            <div className="font-semibold text-lg mb-4">Useful Links</div>
                            <ul className="space-y-2 text-gray-600">
                                <li><a className="hover:underline" href="https://towardsdatascience.com/"
                                       target="_blank" rel="noreferrer">Towards Data Science</a></li>
                                <li><a className="hover:underline" href="https://incubator.apache.org/projects/"
                                       target="_blank" rel="noreferrer">Apache Incubator Projects</a></li>
                                <li><a className="hover:underline" href="https://kaggle.com/" target="_blank"
                                       rel="noreferrer">Kaggle</a></li>
                            </ul>


                        </div>
                        <div>
                            <div className="font-semibold text-lg mb-4">Social</div>
                            <ul className="space-y-2 text-gray-600">
                                <li><a className="hover:underline" href="https://github.com/intelligeus">GitHub
                                </a></li>
                                <li><a className="hover:underline" href="https://twitter.com/intelligeus"
                                       data-url="https://twitter.com/intelligeus"
                                       data-homelink="https://twitter.com">Trends
                                </a></li>
                                <li><a className="hover:underline" href="https://twitter.com/intelligeus"
                                       data-url="https://twitter.com/intelligeus"
                                       data-homelink="https://twitter.com">Twitter
                                </a></li>
                                <li><a className="hover:underline" href="mailto:enquiries@intelligeus.io">Contact
                                    us</a>
                                </li>
                                {/*<li><a className="hover:underline" href="https://www.linkedin.com/company/intelligeus/">LinkedIn*/}
                                {/*</a></li>*/}
                            </ul>
                        </div>
                    </div>
                </div>
                {/*<div className="copyright-footer"><span>© Intelligeus Ltd 2020,2021 </span></div>*/}

            </footer>
        )
    }
}

export default Footer;
