import React, { Component } from 'react';
import NewsPanel from '../newsPagePanel/newsPagePanel';
 

class Homepage extends Component{


    constructor(props){
        super(props);

        this.recentNews = [

            {id: 12,
                title: 'Open AI releases new reasoning models',
                content: "We've developed a new series of AI models designed to spend more time thinking before they respond. They can reason through complex tasks and solve harder problems than previous models in science, coding, and math.",
                image: "https://images.ctfassets.net/kftzwdyauwt9/bgJUZGtbvelVjCeoIjfUl/5e78175b7f7324d72f20927df2c3424d/o1-research-blogcard.png?w=640&q=90&fm=webp",
                link: "https://openai.com/index/introducing-openai-o1-preview/",
                comment: ''},
            {id: 11,
                title: 'New method allows AI to learn indefinitely',
                content: "A team of AI researchers and computer scientists at the University of Alberta has found that current artificial networks used with deep-learning systems lose their ability to learn during extended training on new data.",
                image: "https://scx1.b-cdn.net/csz/news/800a/2024/a-way-to-maintain-plas.jpg",
                link: "https://techxplore.com/news/2024-08-method-ai-indefinitely.html",
                comment: ''},
            {id: 10,
                title: 'Meta releases Llama 3',
                content: "Now available with both 8B and 70B pretrained and instruction-tuned versions to support a wide range of applications.",
                image: "https://github.com/meta-llama/llama3/blob/main/Llama3_Repo.jpeg?raw=true",
                link: "https://llama.meta.com/llama3/",
                comment: ''},
            {id: 9,
                title: 'Microsoft acquires Inflection',
                content: "Microsoft Corp. has agreed to pay Inflection AI $650 million, largely to license its artificial intelligence software, alongside its move earlier this week to hire much of the startup’s staff.",
                image: "https://assets.bwbx.io/images/users/iqjWHBFdfxIU/isqJhk05l_JE/v1/1200x800.jpg",
                link:'https://www.bloomberg.com/news/articles/2024-03-21/microsoft-to-pay-inflection-ai-650-million-after-scooping-up-most-of-staff',
                comment: ''},
            {id: 8,
                title: 'TensorFlow 2.17.0 Released',
                content: "Support for NVIDIA GPUs with compute capability 5.x (Maxwell generation) has been removed from TF binary distributions (Python wheels).",
                image: "https://storage.googleapis.com/gweb-cloudblog-publish/images/Google_Cloud_TensorFlow_Enterprise_1.max-1100x1100.jpg",
                link: "https://github.com/tensorflow/tensorflow/releases",
                comment: ''},
            {id: 7, 
                title: 'ChatGPT can now see, hear, and speak',
                content: "We are beginning to roll out new voice and image capabilities in ChatGPT. They offer a new, more intuitive type of interface by allowing you to have a voice conversation or show ChatGPT what you’re talking about.",
                image: "/images/general/panel-default-image.svg",
                link:'https://openai.com/blog/chatgpt-can-now-see-hear-and-speak',
                comment: ''},
            {id: 6, 
                title: 'Anysphere raises $8M from OpenAI to build an AI-powered IDE',
                content: "Anysphere, a startup building what it describes as an \"AI-native\" software development environment, called Cursor, today announced that it raised $8 million in seed funding led by OpenAI's Startup Fund with participation from former GitHub CEO Nat Friedman, Dropbox co-founder Arash Ferdowsi and other angel investors",
                image: "/images/general/panel-default-image.svg",
                link:'https://www.yahoo.com/news/anysphere-raises-8m-openai-build-190043349.html',
                comment: ''},
            {id: 5, 
                title: 'AlphaProteo generates novel proteins for biology and health research',
                content: "New AI system designs proteins that successfully bind to target molecules, with potential for advancing drug design, disease understanding and more.",
                image: "https://lh3.googleusercontent.com/7RKd6r-Wc8JfMau5x9knRq9DrOKGDwS3ye4YxY0jjWGntf74y8WL0lOlktJefxwkJYw33UEf2Ph_BhQ51TIufCxPkmtCPOpakekMpnOUwVI-3R6RzQ=w1072-h603-n-nu-rw",
                link:'https://deepmind.google/discover/blog/alphaproteo-generates-novel-proteins-for-biology-and-health-research/',
                comment: ''},
            {id: 4, 
                title: 'The Impact of AI and Machine Learning on Startup Innovation',
                content: "In the current fast-paced and ever-changing business environment, startups are continuously searching for creative methods to establish a competitive advantage.",
                image: "https://www.healthcareitnews.com/sites/hitn/files/Vaccination.jpg", 
                link:"https://techround.io/startups/impact-ai-machine-startup-innovation/"},
            {id: 3, 
                title: 'Automation and machine learning future of stem cell production, experts say',
                content: "Health leaders have highlighted how digital advancements could give patients streamlined access to life-saving stem cell treatments.",
                image: 'https://www.healthcareitnews.com/sites/hitn/files/Artificial%20Intelligence%203_0.jpg', 
                link:'https://www.nationalhealthexecutive.com/articles/automation-and-machine-learning-future-stem-cell-production-experts-say'},
            {id: 2, 
                title: 'EVEscape: The AI Revolution in Forecasting Viral Variants Before They Emerge',
                content: 'New AI tool called EVEscape uses evolutionary and biological information to predict how a virus could change to escape the immune system. ',
                image: '/images/general/panel-default-image.svg',
                link:'https://scitechdaily.com/evescape-the-ai-revolution-in-forecasting-viral-variants-before-they-emerge/'},
            {id: 1,
                title: 'SANPO: A Scene understanding, Accessibility, Navigation, Pathfinding, & Obstacle avoidance dataset',
                content: 'As most people navigate their everyday world, they process visual input from the environment using an eye-level perspective. Unlike robots and self-driving cars, people don\'t have any "out-of-body" sensors to help guide them.',
                image: '/images/general/panel-default-image.svg',
                link:'https://blog.research.google/2023/10/sanpo-scene-understanding-accessibility.html'},
            
        ];
    }

    render(){
        return (

            <div
                className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-32">
                <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                    <div className="lg:pr-12 lg:place-self-top">
                        <div className="flex flex-col ">
                            <svg className="text-blue-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                            </svg>
                            <div className="h-2"></div>
                            <div className="flex  rounded-lg">
                                <div>
                                    <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Recent news
                                    </h2></div>
                            </div>
                            <div className="h-6"></div>
                            <p className="leading-snug text-gray-500">Some recent events in the world of machine learning
                            </p>
                            <div className="h-6"></div>
                        </div>
                    </div>
                    <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                        {this.recentNews.map((data) => (
                                             <NewsPanel key={data.id} title={data.title} imageSource={data.image} link={data.link} preview={data.content} comment={data.comment} />
                                         ))}
                    </div>
                </div>
            </div>

        )
    };

}

export default Homepage;