import React, { Component } from 'react';
import LandingPanel from '../../landingPagePanel/landingPagePanel';
import {ToastContainer} from "react-toastify";

class AkkaLanding extends Component{

    constructor(props){
        super(props);
        this.orleansConcepts = [
            {id: 1, title: 'What is Microsoft Orleans?',
                content: 'An introduction to Microsoft Orleans and what it can do.',
                slug:'getting-started-with-microsoft-orleans',
                image: "/images/distributed/orleans/panel-getting-started-with-microsoft-orleans.svg",
                enabled: true,
                sourceLinks:[]},
            {id: 2, title: 'Concepts in Microsoft Orleans',
                content: 'The concepts behind Microsoft Orleans',
                slug:'microsoft-orleans-concepts',
                image: "/images/distributed/orleans/panel-orleans-concepts.svg",
                enabled: true,
                sourceLinks:[]},
            {id: 3, title: 'Grains in Microsoft Orleans',
                content: 'How Microsoft Orleans implements virtual actors',
                slug:'microsoft-orleans-grains',
                image: "/images/distributed/orleans/panel-orleans-grains.svg",
                enabled: true,
                sourceLinks:[]},
            {id: 4, title: 'Silos in Microsoft Orleans',
                content: 'Silos in Microsoft Orleans',
                slug:'orleans-silos-lifecycle',
                image: "/images/distributed/orleans/panel-orleans-silos.svg",
                enabled: true,
                sourceLinks:[]},
            {id: 5, title: 'State in Microsoft Orleans',
                content: 'How Microsoft Orleans handles state',
                slug:'microsoft-orleans-state',
                image: "/images/distributed/orleans/panel-orleans-state.svg",
                enabled: true,
                sourceLinks:[]},
            {id: 6, title: 'Persistence in Microsoft Orleans',
                content: 'How Microsoft Orleans handles persistence',
                slug: 'microsoft-orleans-persistence',
                image: '/images/distributed/orleans/panel-orleans-persistence.svg',
                enabled: true,
                sourceLinks:[]},
            {id: 7, title: 'Scheduling in Microsoft Orleans',
                content: 'How Microsoft Orleans handles scheduling',
                slug: 'microsoft-orleans-scheduling',
                image: '/images/distributed/orleans/panel-orleans-scheduling.svg',
                enabled: true,
                sourceLinks:[]},
            {id: 8, title: 'Hosting in Microsoft Orleans',
                content: 'Hosting and deployment in Microsoft Orleans',
                slug: 'microsoft-orleans-hosting',
                image: '/images/distributed/orleans/panel-orleans-hosting.svg',
                enabled: true,
                sourceLinks:[]},
            {id: 9, title: 'Orleans Dashboard',
                content: 'Under the hood in Microsoft Orleans',
                slug: 'microsoft-orleans-dashboard',
                image: '/images/distributed/orleans/panel-orleans-dashboard.svg',
                enabled: true,
                sourceLinks:[]},
            {id: 10, title: 'Orleans Streaming',
                content: 'Stream processing with Microsoft Orleans',
                slug: 'microsoft-orleans-streaming',
                image: '/images/distributed/orleans/panel-orleans-streaming.svg',
                enabled: true,
                sourceLinks:[]},
            {id: 11, title: 'Observability in Microsoft Orleans',
                content: 'Orleans Metrics with Prometheus and Grafana',
                slug: 'microsoft-orleans-observability',
                image: '/images/distributed/orleans/panel-orleans-observability.svg',
                enabled: true,
                sourceLinks:[]},
            {id: 12, title: 'Distributed Tracing with Microsoft Orleans',
                content: 'Distributed Tracing Prometheus and Zipkin',
                slug: 'microsoft-orleans-distributed-tracing',
                image: '/images/distributed/orleans/panel-orleans-observability.svg',
                enabled: true,
                sourceLinks:[]},
            {id: 13, title: 'Unit Testing in Microsoft Orleans',
                content: 'How to Unit Test grains in Microsoft Orleans',
                slug: 'orleans-unit-testing',
                image: '/images/distributed/orleans/panel-orleans-unit-testing.svg',
                enabled: true,
                sourceLinks:[]}

        ];

        this.orleansGrainDetails = [
            {id: 1, title: 'Grain Identity',
                content: 'What is grain Identity',
                slug:'orleans-grain-identity',
                image: '/images/distributed/orleans/panel-orleans-identity.svg',
                enabled: true,
                sourceLinks:[]},
            {id: 2, title: 'Grain Lifecycle',
                content: 'The lifecycle of a grain in Orleans',
                slug:'orleans-grain-lifecycle',
                image: '/images/distributed/orleans/panel-orleans-grain-lifecycle.svg',
                enabled: true,
                sourceLinks:[]},
            {id: 3, title: 'Grain Placement',
                content: 'How Orleans distributes Grains ',
                slug:'orleans-grain-placement',
                image: '/images/distributed/orleans/panel-orleans-grain-placement.svg',
                enabled: true,
                sourceLinks:[]},
            {id: 4, title: 'Grain State',
                content: 'How grains handle state',
                slug: 'orleans-grain-state',
                image: '/images/distributed/orleans/panel-orleans-grain-state.svg',
                enabled: true,
                sourceLinks:[]},
            {id: 5, title: 'Grain Versioning',
                content: 'Managing Grain changes',
                slug: 'orleans-grain-versioning',
                image: '/images/distributed/orleans/panel-orleans-grain-state.svg',
                enabled: true,
                sourceLinks:[]},
            {id: 6, title: 'Stateless Worker Grains',
                content: 'Stateless Worker Grains',
                slug: 'orleans-stateless-worker-grains',
                image: '/images/distributed/orleans/panel-orleans-grain-stateless.svg',
                enabled: true,
                sourceLinks:[]},
            {id: 7, title: 'Re-Entrant Grains',
                content: 'Using Re-entrant Grains',
                slug: 'microsoft-orleans-reentrant-grains',
                image: '/images/distributed/orleans/panel-orleans-reentrant-grains.svg',
                enabled: true,
                sourceLinks:[]},

        ];

        this.orleansExamples = [
            {id: 1, title: 'Hello Classification',
                content: 'Training a simple ML.NET classification model',
                slug:'basic-classification-problem',
                image: '/images/panel-classification.svg',
                sourceLinks:[]},
            {id: 2, title: 'Clustering',
                content: 'Training a simple ML.NET clustering model',
                slug:'basic-clustering-problem',
                image: '/images/panel-clustering.svg',
                sourceLinks:[]}

        ];

    }

    render(){

        return (
            <React.Fragment>
                <ToastContainer
                    autoClose={5000}
                    hideProgressBar={true}

                    /*{...otherPropsFromToastConfigure}*/
                />
                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-red-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2" />
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Orleans Concepts
                                        </h2></div>
                                </div>
                                <div className="h-6" />
                                <p className="leading-snug text-gray-500">Some of the concepts and functionality underpinning Microsoft Orleans
                                </p>
                                <div className="h-6" />
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.orleansConcepts.map((data) => (
                                <LandingPanel key={data.id} title={data.title} imageSource={data.image} to={"/distributed-computing/microsoft-orleans/concepts/" + data.slug} strapLine={data.content}  />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-blue-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2"></div>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Grain Features
                                        </h2></div>
                                </div>
                                <div className="h-6"></div>
                                <p className="leading-snug text-gray-500">Some features we find in Grains
                                </p>
                                <div className="h-6"></div>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.orleansGrainDetails.map((data) => (
                                <LandingPanel key={data.id}  title={data.title} imageSource={data.image} to={"/distributed-computing/microsoft-orleans/examples/" + data.slug} strapLine={data.content}  />
                            ))}
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };

}

export default AkkaLanding;