import React, {Component} from 'react';

import Prism from '../../../prism-plugins-tomorrow'
import $ from "jquery";
import {withParams} from "../../../App";
import {attachCopyTagHandlers} from "../../../utility";
import PageComponent from "../../pageComponent/pageComponent";

class OrleansPage extends Component {


    state = {
        title: '',
        tags: [],
        html: '',
        nextContent: '',
        relatedContent: [],
        sourceLinks: [],
        algos: [],
        languages: [],
        attributed: [],
        suggestedCourses: [],
        slug: '',
        counter: 0
    };

    slugs = [
        {
            slug: 'getting-started-with-microsoft-orleans',
            source: '/dc/html/orleans/getting-started-with-orleans.html',
            title: 'Microsoft Orleans',
            tags: ['Distributed', 'Orleans'], sourceLinks: [], languages: ['C#'], algos: []
        },
        {
            slug: 'microsoft-orleans-scheduling',
            source: '/dc/html/orleans/orleans-scheduling.html',
            title: 'Microsoft Orleans Scheduling',
            tags: ['Distributed', 'Orleans'], sourceLinks: [], languages: ['C#'], algos: []
        },
        {
            slug: 'microsoft-orleans-state',
            source: '/dc/html/orleans/orleans-grain-state.html',
            title: 'Microsoft Orleans State',
            tags: ['Distributed', 'Orleans'], sourceLinks: ['https://github.com/intelligeus/orleans-samples/tree/main/GrainState'], languages: ['C#'], algos: []
        },
        {
            slug: 'microsoft-orleans-grains',
            source: '/dc/html/orleans/orleans-grain.html',
            title: 'Microsoft Orleans Grains',
            tags: ['Distributed', 'Orleans', 'Grains'], sourceLinks: ['https://github.com/intelligeus/orleans-samples/tree/main/CommonGrains'], languages: ['C#'], algos: []
        },
        {
            slug: 'orleans-silos-lifecycle',
            source: '/dc/html/orleans/orleans-silos-lifecycle.html',
            title: 'The lifecycle of a silo in Orleans',
            tags: ['Distributed', 'Orleans', 'Silo'], sourceLinks: ['https://github.com/intelligeus/orleans-samples/tree/main/SiloLifecycle'], languages: ['C#'], algos: []
        },
        {
            slug: 'microsoft-orleans-persistence',
            source: '/dc/html/orleans/orleans-grain-persistence.html',
            title: 'Microsoft Orleans Persistence',
            tags: ['Distributed', 'Orleans', 'Persistence'], sourceLinks: [], languages: ['C#'], algos: []
        },
        {
            slug: 'microsoft-orleans-streaming',
            source: '/dc/html/orleans/orleans-streaming-data.html',
            title: 'Microsoft Orleans Streaming',
            tags: ['Distributed', 'Orleans', 'Streaming'], sourceLinks: ['https://github.com/intelligeus/orleans-samples/tree/main/OrleansStreaming'], languages: ['C#'], algos: []
        },
        {
            slug: 'microsoft-orleans-observability',
            source: '/dc/html/orleans/orleans-observability.html',
            title: 'Microsoft Orleans Observability',
            tags: ['Distributed', 'Orleans', 'Observability'], sourceLinks: [], languages: ['C#'], algos: []
        },
        {
            slug: 'microsoft-orleans-concepts',
            source: '/dc/html/orleans/orleans-concepts.html',
            title: 'Microsoft Orleans Concepts',
            tags: ['Distributed', 'Orleans', 'Actor'],
            sourceLinks: [],
            languages: ['C#'],
            algos: []
        },
        {
            slug: 'microsoft-orleans-hosting',
            source: '/dc/html/orleans/orleans-hosting.html',
            title: 'Microsoft Orleans Hosting and Deployment',
            tags: ['Distributed', 'Orleans', 'Deployment'],
            sourceLinks: ['https://github.com/intelligeus/orleans-samples/tree/main/OrleansHosting'],
            languages: ['C#'],
            algos: []
        },
        {
            slug: 'microsoft-orleans-dashboard',
            source: '/dc/html/orleans/orleans-dashboard.html',
            title: 'Microsoft Orleans Dashboard',
            tags: ['Distributed', 'Orleans', 'Metrics'],
            sourceLinks: ['https://github.com/intelligeus/orleans-samples/tree/main/Dashboard'],
            languages: ['C#'],
            algos: []
        },
        {
            slug: 'orleans-unit-testing',
            source: '/dc/html/orleans/orleans-unit-testing.html',
            title: 'Unit Testing in Orleans',
            tags: ['Distributed', 'Orleans', 'Test'],
            sourceLinks: ['https://github.com/intelligeus/orleans-samples/tree/main/UnitTesting'],
            languages: ['C#'],
            algos: []
        },
        {
            slug: 'orleans-grain-identity',
            source: '/dc/html/orleans/orleans-grain-identity.html',
            title: 'Microsoft Orleans Identity',
            tags: ['Distributed', 'Orleans', 'Grain'],
            sourceLinks: ['https://github.com/intelligeus/orleans-samples/tree/main/OrleansIdentity'],
            languages: ['C#'],
            algos: []
        },
        {
            slug: 'orleans-grain-state',
            source: '/dc/html/orleans/orleans-grain-state.html',
            title: 'Microsoft Orleans State',
            tags: ['Distributed', 'Orleans', 'Persistence'],
            sourceLinks: ['https://github.com/intelligeus/orleans-samples/tree/main/GrainState'],
            languages: ['C#'],
            algos: []
        },
        {
            slug: 'orleans-grain-placement',
            source: '/dc/html/orleans/orleans-grain-placement.html',
            title: 'Microsoft Orleans Grain Placement',
            tags: ['Distributed', 'Orleans', 'Placement'],
            sourceLinks: ['https://github.com/intelligeus/orleans-samples/tree/main/GrainPlacement', 'https://github.com/intelligeus/orleans-samples/tree/main/GrainPlacementClient'],
            languages: ['C#'],
            algos: []
        },
        {
            slug: 'orleans-grain-lifecycle',
            source: '/dc/html/orleans/orleans-grain-lifecycle.html',
            title: 'Microsoft Orleans Grain Lifecycle',
            tags: ['Distributed', 'Orleans', 'Lifecycle'],
            sourceLinks: ['https://github.com/intelligeus/orleans-samples/tree/main/GrainLifeCycle'],
            languages: ['C#'],
            algos: []
        },
        {
            slug: 'orleans-grain-versioning',
            source: '/dc/html/orleans/orleans-grain-versioning.html',
            title: 'Microsoft Orleans Grain Versioning',
            tags: ['Distributed', 'Orleans', 'Versioning'],
            sourceLinks: [],
            languages: ['C#'],
            algos: []
        },
        {
            slug: 'orleans-stateless-worker-grains',
            source: '/dc/html/orleans/orleans-stateless-worker-grains.html',
            title: 'Microsoft Orleans Stateless Worker',
            tags: ['Distributed', 'Orleans'],
            sourceLinks: ['https://github.com/intelligeus/orleans-samples/tree/main/StatelessWorkerGrains'],
            languages: ['C#'],
            algos: []
        },
        {
            slug: 'orleans-reentrant-grains',
            source: '/dc/html/orleans/orleans-reentrant-grains.html',
            title: 'Re-Entrant Grains',
            tags: ['Distributed', 'Orleans'],
            sourceLinks: ['https://github.com/intelligeus/orleans-samples/tree/main/ReentrantGrains'],
            languages: ['C#'],
            algos: []
        },

        {
            slug: 'getting-started-with-microsoft-orleans',
            source: '/dc/html/orleans/getting-started-with-orleans.html',
            title: 'Getting started ',
            tags: ['Distributed', 'Orleans', 'Examples'],
            sourceLinks: [],
            languages: ['C#'],
            algos: []
        },
        {
            slug: 'microsoft-orleans-hello-world',
            source: '/dc/html/orleans/hello-world.html',
            title: 'Hello World',
            tags: ['Distributed', 'Orleans', 'Examples'],
            sourceLinks: [],
            languages: ['C#'],
            algos: []
        },
        {
            slug: 'microsoft-orleans-iot-example',
            source: '/dc/html/orleans/iot-example.html',
            title: 'IOT Example',
            tags: ['Distributed', 'Orleans', 'IOT'],
            sourceLinks: [],
            languages: ['C#'],
            algos: []
        },
        {
            slug: 'microsoft-orleans-deployment',
            source: '/dc/html/orleans/orleans-deployment.html',
            title: 'Orleans Deployment',
            tags: ['Distributed', 'Orleans', 'Deployment'],
            sourceLinks: [],
            languages: ['C#'],
            algos: []
        },
        {
            slug: 'microsoft-orleans-docker-deployment',
            source: '/dc/html/orleans/orleans-docker-deployment.html',
            title: 'Docker Deployment',
            tags: ['Distributed', 'Orleans', 'Deployment'],
            sourceLinks: [],
            languages: ['C#'],
            algos: []
        },
        {
            slug: 'microsoft-orleans-azure-deployment',
            source: '/dc/html/orleans/orleans-azure-deployment.html',
            title: 'Azure Deployment',
            tags: ['Distributed', 'Orleans', 'Deployment'],
            sourceLinks: [],
            languages: ['C#'],
            algos: []
        },
        {
            slug: 'orleans-chat-application',
            source: '/dc/html/orleans/orleans-chat-application.html',
            title: 'Chat Application',
            tags: ['Distributed', 'Orleans'],
            sourceLinks: [],
            languages: ['C#'],
            algos: []
        },
        {
            slug: 'orleans-iot-application',
            source: '/dc/html/orleans/orleans-iot-example.html',
            title: 'Azure Deployment',
            tags: ['Distributed', 'Orleans'],
            sourceLinks: [],
            languages: ['C#'],
            algos: []
        },
    ];


    nextPage = [
        {
            key: 'getting-started-with-microsoft-orleans',
            slug: 'microsoft-orleans-concepts',
            title: 'Microsoft Orleans Concepts',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'microsoft-orleans-concepts',
            slug: 'microsoft-orleans-grains',
            title: 'Microsoft Orleans Grains',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'microsoft-orleans-grains',
            slug: 'orleans-silos-lifecycle',
            title: 'Microsoft Orleans Silos',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'orleans-silos-lifecycle',
            slug: 'microsoft-orleans-state',
            title: 'Microsoft Orleans State',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'microsoft-orleans-state',
            slug: 'microsoft-orleans-persistence',
            title: 'Microsoft Orleans Persistence',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'microsoft-orleans-persistence',
            slug: 'microsoft-orleans-scheduling',
            title: 'Microsoft Orleans Scheduling',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'microsoft-orleans-scheduling',
            slug: 'microsoft-orleans-hosting',
            title: 'Microsoft Orleans Hosting',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'microsoft-orleans-hosting',
            slug: 'microsoft-orleans-dashboard',
            title: 'Microsoft Orleans Dashboard',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'microsoft-orleans-dashboard',
            slug: 'microsoft-orleans-observability',
            title: 'Microsoft Orleans Observability',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'microsoft-orleans-observability',
            slug: 'microsoft-orleans-distributed-tracing',
            title: 'Distributed Tracing',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'microsoft-orleans-distributed-tracing',
            slug: 'orleans-unit-testing',
            title: 'Unit Testing',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'orleans-unit-testing',
            slug: 'orleans-grain-identity',
            title: 'Grain Identity',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'orleans-grain-identity',
            slug: 'orleans-grain-lifecycle',
            title: 'Grain Lifecycle',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'orleans-grain-lifecycle',
            slug: 'orleans-grain-placement',
            title: 'Grain Placement',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'orleans-grain-placement',
            slug: 'orleans-grain-state',
            title: 'Grain State',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'orleans-grain-state',
            slug: 'microsoft-orleans-stateless-worker',
            title: 'Stateless Worker Grains',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'microsoft-orleans-stateless-worker',
            slug: 'microsoft-orleans-reentrant-grain',
            title: 'Re-Entrant Grains',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'microsoft-orleans-reentrant-grains',
            slug: '',
            title: 'Microsoft Orleans',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'microsoft-orleans-deployment',
            slug: 'microsoft-orleans-docker-deployment',
            title: 'Docker Deployment',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'microsoft-orleans-docker-deployment',
            slug: 'microsoft-orleans-azure-deployment',
            title: 'Azure Deployment',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },
        {
            key: 'microsoft-orleans-azure-deployment',
            slug: '',
            title: 'Microsoft Orleans',
            path: '/distributed-computing/microsoft-orleans/concepts/'
        },

    ]

    relatedPages = [
        {
            id: 1,
            key: 'getting-started-with-microsoft-orleans',
            title: 'What is Microsoft Orleans',
            slug: "What is Microsoft Orleans",
            link: '/distributed-computing/orleans/concepts'
        },
        {
            id: 2,
            key: 'how-do-distributed-frameworks-operate',
            title: 'How do distributed-frameworks-operate?',
            slug: "how-do-distributed-frameworks-operate",
            link: '/distributed-computing/basics/'
        },
        {
            id: 3,
            key: 'how-do-distributed-frameworks-operate',
            title: 'How do distributed-frameworks-operate?',
            slug: "how-do-distributed-frameworks-operate",
            link: '/distributed-computing/basics/'
        },
        {
            id: 4,
            key: 'microsoft-orleans-concepts',
            title: 'The Actor Model',
            slug: "",
            link: 'https://en.wikipedia.org/wiki/Actor_model'
        },
        {
            id: 5,
            key: 'microsoft-orleans-concepts',
            title: 'AKKA Framework',
            slug: "",
            link: 'https://akka.io/'},
        {
            id: 6,
            key: 'microsoft-orleans-grains',
            title: 'Grain Identity',
            slug: "",
            link: '/distributed-computing/microsoft-orleans/examples/orleans-grain-identity'
        },
        {
            id: 7,
            key: 'microsoft-orleans-grains',
            title: 'Grain Life Cycle',
            slug: "",
            link: '/distributed-computing/microsoft-orleans/examples/orleans-grain-lifecycle'
        },
        {
            id: 8,
            key: 'microsoft-orleans-grains',
            title: 'Grain Placement',
            slug: "",
            link: '/distributed-computing/microsoft-orleans/examples/orleans-grain-placement'
        },
        {
            id: 9,
            key: 'microsoft-orleans-grains',
            title: 'Grain Versioning',
            slug: "",
            link: '/distributed-computing/microsoft-orleans/examples/orleans-grain-versioning'
        },
        {
            id: 10,
            key: 'microsoft-orleans-observability',
            title: 'Prometheus',
            slug: "",
            link: 'https://prometheus.io/'},
        {
            id: 11,
            key: 'microsoft-orleans-observability',
            title: 'Grafana',
            slug: "",
            link: 'https://grafana.com'},
        {
            id: 12,
            key: 'microsoft-orleans-observability',
            title: 'Orleans Counters',
            slug: "",
            link: 'https://learn.microsoft.com/en-us/dotnet/orleans/host/monitoring/?pivots=orleans-7-0#messaging'
        },
        {
            id: 13,
            key: 'microsoft-orleans-distributed-tracing',
            title: 'Prometheus',
            slug: "",
            link: 'https://prometheus.io/'
        },
        {
            id: 14,
            key: 'microsoft-orleans-distributed-tracing',
            title: 'Grafana',
            slug: "",
            link: 'https://grafana.com'},
        {
            id: 15,
            key: 'microsoft-orleans-distributed-tracing',
            title: 'Zipkin',
            slug: "",
            link: 'https://zipkin.com'}
    ]

    suggestedCourses = [
        {
            id: 1,
            key: 'getting-started-with-microsoft-orleans',
            title: 'What is Microsoft Orleans',
            slug: "What is Microsoft Orleans",
            link: '/distributed-computing/orleans/concepts'
        },
        {
            id: 2,
            key: 'how-do-distributed-frameworks-operate',
            title: 'How do distributed-frameworks-operate?',
            slug: "how-do-distributed-frameworks-operate",
            link: '/distributed-computing/basics/'
        },
        {
            id: 3,
            key: 'how-do-distributed-frameworks-operate',
            title: 'How do distributed-frameworks-operate?',
            slug: "how-do-distributed-frameworks-operate",
            link: '/distributed-computing/basics/'
        },
        {
            id: 4,
            key: 'microsoft-orleans-concepts',
            title: 'The Actor Model',
            slug: "",
            link: 'https://en.wikipedia.org/wiki/Actor_model'
        },
        {id: 5, key: 'microsoft-orleans-concepts', title: 'AKKA Framework', slug: "", link: 'https://akka.io/'},
        {
            id: 6,
            key: 'microsoft-orleans-grains',
            title: 'Grain Identity',
            slug: "",
            link: '/distributed-computing/microsoft-orleans/examples/orleans-grain-identity'
        },
        {
            id: 7,
            key: 'microsoft-orleans-grains',
            title: 'Grain Life Cycle',
            slug: "",
            link: '/distributed-computing/microsoft-orleans/examples/orleans-grain-lifecycle'
        },
        {
            id: 8,
            key: 'microsoft-orleans-grains',
            title: 'Grain Placement',
            slug: "",
            link: '/distributed-computing/microsoft-orleans/examples/orleans-grain-placement'
        },
        {
            id: 9,
            key: 'microsoft-orleans-grains',
            title: 'Grain Versioning',
            slug: "",
            link: '/distributed-computing/microsoft-orleans/examples/orleans-grain-versioning'
        },
        {id: 7, key: 'microsoft-orleans-observability', title: 'Prometheus', slug: "", link: 'https://prometheus.io/'},
        {id: 8, key: 'microsoft-orleans-observability', title: 'Grafana', slug: "", link: 'https://grafana.com'},
        {
            id: 9,
            key: 'microsoft-orleans-observability',
            title: 'Orleans Counters',
            slug: "",
            link: 'https://learn.microsoft.com/en-us/dotnet/orleans/host/monitoring/?pivots=orleans-7-0#messaging'
        },
        {
            id: 19,
            key: 'microsoft-orleans-distributed-tracing',
            title: 'Prometheus',
            slug: "",
            link: 'https://prometheus.io/'
        },
        {id: 11, key: 'microsoft-orleans-distributed-tracing', title: 'Grafana', slug: "", link: 'https://grafana.com'},
        {id: 12, key: 'microsoft-orleans-distributed-tracing', title: 'Zipkin', slug: "", link: 'https://zipkin.com'}
    ]

    constructor(props) {
        super(props);
        this.state.slug = props.params.slug;
    }

    componentDidUpdate(prevProps, prevState, False) {
        console.log('OrleansPage: Rebinding jquery hooks after document update');
        // console.log('MarkdownDisplay: component updating ' + this.state.html);

        // Connect Copy and Tag functionality
        attachCopyTagHandlers();

        $("div[data-mime-type='application/vnd.jupyter.stderr']").hide();

        setTimeout(() => Prism.highlightAll(), 0);
    }

    componentDidUnMount() {
        console.log('Orleans Page component UNMOUNTED');
    }


    componentDidMount() {

        // Get the contents from the HTML file and put them in the React state, so we can reference it in render() below.
        const path = this.slugs.filter(path => path.slug === this.state.slug);
        console.log("OP Slug [" + this.state.slug + "] matched to url [" + path[0].source + "]")

        if (path.length === 0) {
            console.error('OP Failed to located path for : ' + this.state.slug);
            //this.setState((state) => ({ ...state, "Failed to located requested content" }));
        }

        const next = this.nextPage.filter(item => item.key === this.state.slug)[0];
        const related = this.relatedPages.filter(item => item.key === this.state.slug);
        const suggested = this.suggestedCourses.filter(item => item.key === this.state.slug);

        this.setState({
            nextContent: next !== undefined ? next : {
                key: '',
                slug: '',
                title: 'Orleans',
                path: '/distributed-computing/microsoft-orleans/'
            },
            relatedContent: related,
            title: path[0].title,
            tags: path[0].tags,
            sourceLinks: path[0].sourceLinks,
            languages: path[0].languages,
            algos: path[0].algos,
            suggestedCourses: null,
            attributed: path[0].attributed

        });
        if (this.state) {
            console.log("OP Next Page Value determined as : " + this.state.nextContent);
        }

        fetch(path[0].source)
            .then(res => res.text())
            .then(html => this.setState((state) => ({...state, html})))
            //.then(html => console.log(html))
            .catch((err) => console.error(err));
        console.log("OP Page content was successfully retrieved ");

        console.log('OrleansPage component mounted ' + this.state.html);
    }


    render() {

        return (
            <PageComponent props={this.state} page="Orleans Page"/>
        );

    }
}

export default withParams(OrleansPage);