import React, {Component} from 'react';

import Prism from '../../prism-plugins-tomorrow'
import $ from "jquery";
import {withParams} from "../../App";
import {attachCopyTagHandlers} from "../../utility";
import PageComponent from "../pageComponent/pageComponent";

class DCPage extends Component {


    state = {
        title: '',
        tags: [],
        html: '',
        nextContent:'',
        relatedContent: [],
        sourceLinks: [],
        suggestedCourses: [],
        algos: [],
        languages: [],
        slug: '',
        attrib:[]
    };

  slugs = [
    {slug: 'what-is-distributed-computing',
        source: '/dc/what-is-distributed-computing.html',
        title: 'What is Distributed Computing',
        tags: ['Distributed', 'Network'],
        sourceLinks:[],
        languages: [], attrib:[] },
    {slug: 'brief-history-of-distributed-computing',
        source: '/dc/brief-history-of-distributed-computing.html',
        title: 'How id distributed computing come about',
        tags: ['Distributed'],
        sourceLinks:[],
        languages: [] },
    {slug: 'distributed-computing-frameworks',
        source: '/dc/distributed-computing-frameworks.html',
        title: 'Some common distributed computing frameworks',
        tags: ['Distributed', 'Frameworks'],
        sourceLinks:[],
        languages: [] },
    {slug: 'how-do-distributed-frameworks-operate',
        source: '/dc/distributed-computing-frameworks-operation.html',
        title: 'How do Distributed Frameworks Operate',
        tags: ['Distributed', 'Frameworks'],
        sourceLinks:[],
        languages: [] },
    {slug: 'instrumenting-your-code',
        source: '/dc/instrumenting-your-code.html',
        title: 'Instrumenting Your Code',
        tags: ['Distributed', 'Observability', 'Tracing'],
        sourceLinks:[],
        languages: [] },
    {slug: 'microsoft-orleans-scheduling',
        source: '/dc/html/orleans/orleans-scheduling.html',
        title: 'Microsoft Orleans Scheduling',
        tags: ['Distributed', 'Orleans'],
        sourceLinks:[],
        languages: [] },
    {slug: 'microsoft-orleans-state',
        source: '/dc/html/orleans/orleans-state.html',
        title: 'Microsoft Orleans State',
        tags: ['Distributed', 'Orleans'],
        sourceLinks:[],
        languages: [] },
    {slug: 'microsoft-orleans-persistence',
        source: '/dc/html/orleans/orleans-grain-persistence.html',
        title: 'Microsoft Orleans Persistence',
        tags: ['Distributed', 'Orleans'],
        sourceLinks:[],
        languages: [] },

    {slug: 'observability-in-distributed-computing',
        source: '/dc/observability-in-distributed-computing.html',
        title: 'Observability in Distributed Computing',
        tags: ['Distributed', 'Orleans', 'Observability'],
        sourceLinks:[],
        languages: [] },
    {slug: 'zipkin',
        source: '/dc/distributed-computing-zipkin.html',
        title: 'Zipkin',
        tags: ['Distributed', 'Observability', 'Zipkin'],
        sourceLinks:[],
        languages: [] },
      {slug: 'jaeger',
          source: '/dc/distributed-computing-jaeger.html',
          title: 'Jaeger Tracing',
          tags: ['Distributed', 'Observability', 'Tracing'],
          sourceLinks:[],
          algos: [],
          languages: [] },
    {slug: 'grafana',
        source: '/dc/distributed-computing-grafana.html',
        title: 'Grafana',
        tags: ['Distributed', 'Observability', 'Grafana'],
        sourceLinks:[],
        languages: [] },
    {slug: 'prometheus',
        source: '/dc/distributed-computing-prometheus.html',
        title: 'Prometheus',
        tags: ['Distributed', 'Observability', 'Prometheus'],
        sourceLinks:[],
        languages: [] },
    {slug: 'distributed-computing-scalability',
        source: '/dc/distributed-computing-scalability.html',
        title: 'Scalability',
        tags: ['Distributed', 'Observability', 'Scalability'],
        sourceLinks:[],
        languages: [] },
    {slug: 'distributed-computing-reliability',
        source: '/dc/distributed-computing-reliability.html',
        title: 'Reliability',
        tags: ['Distributed', 'Observability', 'Reliability'],
        sourceLinks:[],
        languages: [] },
    {slug: 'distributed-computing-messaging',
        source: '/dc/distributed-computing-messaging.html',
        title: 'Distributed Messaging',
        tags: ['Distributed', 'Messaging', 'Asynchronous'],
        sourceLinks:[],
        languages: [],
        attrib: ['https://www.csl.cornell.edu/~delimitrou/papers/2019.asplos.microservices.pdf'] },
    {slug: 'stream-processing-introduction',
        source: '/dc/distributed-computing-stream-processing.html',
        title: 'Stream Processing',
        tags: ['Distributed', 'Observability', 'Stream'],
        sourceLinks:[],
        languages: [] },
    {slug: 'batch-processing-introduction',
        source: '/dc/distributed-computing-batch-processing.html',
        title: 'Batch Processing',
        tags: ['Distributed', 'Observability', 'Batch'],
        sourceLinks:[],
        languages: [] },
      {slug: 'batch-processing-example',
          source: '/dc/distributed-computing-batch-processing-example.html',
          title: 'Batch Processing Example',
          tags: ['Distributed', 'Observability', 'Batch'],
          sourceLinks:[],
          languages: [] },
      {slug: 'stream-processing-example',
          source: '/dc/distributed-computing-stream-processing-example.html',
          title: 'Stream Processing Example',
          tags: ['Distributed', 'Observability', 'Stream'],
          sourceLinks:[],
          languages: [] },
      {slug: 'open-telemetry-tracing',
          source: '/dc/distributed-computing-open-telemetry.html',
          title: 'Tracing and Instrumentation',
          tags: ['Distributed', 'Observability', 'Tracing'],
          sourceLinks:[],
          languages: [] },

  ];


  nextPage = [
    {key: 'what-is-distributed-computing',
        slug: 'brief-history-of-distributed-computing',
        title: 'Distributed Computing frameworks',
        path: '/distributed-computing/basics/'},
    {key: 'brief-history-of-distributed-computing',
        slug: 'distributed-computing-frameworks',
        title:'Distributed Computing frameworks',
        path: '/distributed-computing/basics/' },
    {key: 'distributed-computing-frameworks',
        slug: 'distributed-computing-scalability',
        title:'Scalability in Distributed Computing',
        path: '/distributed-computing/concepts/' },
    {key: 'distributed-computing-scalability',
        slug: 'distributed-computing-reliability',
        title: 'Reliability',
        path: '/distributed-computing/concepts/' },
    {key: 'distributed-computing-reliability',
        slug: 'distributed-computing-messaging',
        title: 'Messaging',
        path: '/distributed-computing/concepts/' },
      {key: 'distributed-computing-messaging',
          slug: 'observability-in-distributed-computing',
          title: 'Observability',
          path: '/distributed-computing/observability/' },
      {key: 'observability-in-distributed-computing',
          slug: 'open-telemetry-tracing',
          title: 'Telemetry',
          path: '/distributed-computing/observability/' },
      {key: 'open-telemetry-tracing',
          slug: 'instrumenting-your-code',
          title: 'Instrumenting Code',
          path: '/distributed-computing/observability/' },
      {key: 'instrumenting-your-code',
          slug: 'prometheus',
          title: 'Prometheus',
          path: '/distributed-computing/observability/' },
      {key: 'prometheus',
          slug: 'grafana',
          title: 'Grafana',
          path: '/distributed-computing/observability/' },
      {key: 'grafana',
          slug: 'zipkin',
          title: 'Zipkin',
          path: '/distributed-computing/observability/' },
      {key: 'zipkin',
          slug: 'batch-processing-introduction',
          title: 'Batch Processing',
          path: '/distributed-computing/observability/' },
      {key: 'batch-processing-introduction',
          slug: 'batch-processing-example',
          title: 'Batch Processing Example',
          path: '/distributed-computing/basics/' },
      {key: 'batch-processing-example',
          slug: 'stream-processing-introduction',
          title: 'Stream Processing',
          path: '/distributed-computing/basics/' },
      {key: 'stream-processing-introduction',
          slug: 'stream-processing-example',
          title: 'Stream Processing Example',
          path: '/distributed-computing/basics/' },
      {key: 'stream-processing-example',
          slug: '',
          title: 'Distributed Computing',
          path: '/distributed-computing/' },

  ]


  relatedPages = [
    { id: 1,
        key: 'what-is-distributed-computing',
        title:'Wikipedia',
        slug:'',
        link: 'https://en.wikipedia.org/wiki/Distributed_computing' },
    { id: 2,
        key: 'brief-history-of-distributed-computing',
        title:'Brief history of Distributed Computing',
        slug:'',
        link: '/distributed-computing/basics/' },
    { id: 3,
        key: 'distributed-computing-frameworks',
        title:'Common Distributed Computing Frameworks',
        slug:'distributed-computing-frameworks',
        link: '/distributed-computing/basics/' },
    { id: 4,
        key: 'how-do-distributed-frameworks-operate',
        title:'How do distributed-frameworks-operate?',
        slug:'how-do-distributed-frameworks-operate',
        link: '/distributed-computing/basics/' },
    { id: 5,
        key: 'getting-started-with-microsoft-orleans',
        title:'How do distributed-frameworks-operate?',
        slug:'how-do-distributed-frameworks-operate',
        link: '/distributed-computing/basics/' },
    { id: 6,
        key: 'zipkin',
        title:'Prometheus',
        slug:'prometheus',
        link: '/distributed-computing/observability/' },
    { id: 7,
        key: 'zipkin',
        title:'Grafana',
        slug:'grafana',
        link: '/distributed-computing/observability/' },
      { id: 8,
          key: 'prometheus',
          title:'Zipkin',
          slug:'zipkin',
          link: '/distributed-computing/observability/' },
      { id: 9,
          key: 'prometheus',
          title:'Grafana',
          slug:'grafana',
          link: '/distributed-computing/observability/' },
      { id: 10,
          key: 'grafana',
          title:'Prometheus',
          slug:'prometheus',
          link: '/distributed-computing/observability/' },
      { id: 11,
          key: 'grafana',
          title:'Zipkin',
          slug:'zipkin',
          link: '/distributed-computing/observability/' },
      { id: 12,
          key: 'grafana',
          title:'Grafana.com',
          slug:'',
          link: 'https://grafana.com/' },

      { id: 13,
          key: 'zipkin',
          title:'Zipkin.io',
          slug:'',
          link: 'https://zipkin.io/' },
      {
          id: 14,
          key: 'prometheus',
          title: 'Jaeger',
          slug: "jaeger",
          link: '/distributed-computing/observability/'
      },
      {
          id: 15,
          key: 'prometheus',
          title: 'Grafana',
          slug: "",
          link: 'https://grafana.com'},
      {
          id: 16,
          key: 'zipkin',
          title: 'Zipkin.io',
          slug: "",
          link: 'https://zipkin.io'},
      {
          id: 17,
          key: 'grafana',
          title: 'Jaeger',
          slug: "jaeger",
          link: '/distributed-computing/observability/'},
      {
          id: 18,
          key: 'distributed-computing-frameworks',
          title: 'Storm',
          slug: "",
          link: 'https://storm.apache.org'},
      {
          id: 19,
          key: 'distributed-computing-frameworks',
          title: 'Orleans',
          slug: "",
          link: 'https://learn.microsoft.com/en-us/dotnet/orleans/overview'},
      {
          id: 20,
          key: 'distributed-computing-frameworks',
          title: 'Apache Beam',
          slug: "",
          link: 'https://beam.apache.com'},
      {
          id: 21,
          key: 'distributed-computing-frameworks',
          title: 'AKKA',
          slug: "",
          link: 'https://akka.io/'},
      {
          id: 22,
          key: 'distributed-computing-frameworks',
          title: 'AKKA.NET',
          slug: "",
          link: 'https://getakka.net/'},
      {
          id: 23,
          key: 'distributed-computing-frameworks',
          title: 'Spark',
          slug: "",
          link: 'https://spark.apache.org'},
      {
          id: 24,
          key: 'distributed-computing-frameworks',
          title: 'Ray',
          slug: "",
          link: 'https://www.ray.io/'},
      {
          id: 25,
          key: 'distributed-computing-frameworks',
          title: 'Kafka Streams',
          slug: "",
          link: 'https://www.ray.io/'},


  ]

    suggestedCourses = [
        {
            id: 1,
            key: 'getting-started-with-microsoft-orleans',
            title: 'What is Microsoft Orleans',
            slug: "What is Microsoft Orleans",
            link: '/distributed-computing/orleans/concepts'
        },
        {
            id: 2,
            key: 'how-do-distributed-frameworks-operate',
            title: 'How do distributed-frameworks-operate?',
            slug: "how-do-distributed-frameworks-operate",
            link: '/distributed-computing/basics/'
        },
        {
            id: 3,
            key: 'how-do-distributed-frameworks-operate',
            title: 'How do distributed-frameworks-operate?',
            slug: "how-do-distributed-frameworks-operate",
            link: '/distributed-computing/basics/'
        },
        {
            id: 4,
            key: 'microsoft-orleans-concepts',
            title: 'The Actor Model',
            slug: "",
            link: 'https://en.wikipedia.org/wiki/Actor_model'
        },
        {id: 5,
            key: 'microsoft-orleans-concepts',
            title: 'AKKA Framework',
            slug: "",
            link: 'https://akka.io/'},
        {
            id: 6,
            key: 'microsoft-orleans-grains',
            title: 'Grain Identity',
            slug: "",
            link: '/distributed-computing/microsoft-orleans/examples/orleans-grain-identity'
        },
        {
            id: 7,
            key: 'microsoft-orleans-grains',
            title: 'Grain Life Cycle',
            slug: "",
            link: '/distributed-computing/microsoft-orleans/examples/orleans-grain-lifecycle'
        },
        {
            id: 8,
            key: 'microsoft-orleans-grains',
            title: 'Grain Placement',
            slug: "",
            link: '/distributed-computing/microsoft-orleans/examples/orleans-grain-placement'
        },
        {
            id: 9,
            key: 'microsoft-orleans-grains',
            title: 'Grain Versioning',
            slug: "",
            link: '/distributed-computing/microsoft-orleans/examples/orleans-grain-versioning'
        },
        {
            id: 10, key: 'microsoft-orleans-observability',
            title: 'Prometheus',
            slug: "",
            link: 'https://prometheus.io/'},
        {
            id: 11, key: 'microsoft-orleans-observability', title: 'Grafana', slug: "", link: 'https://grafana.com'},
        {
            id: 12,
            key: 'microsoft-orleans-observability',
            title: 'Orleans Counters',
            slug: "",
            link: 'https://learn.microsoft.com/en-us/dotnet/orleans/host/monitoring/?pivots=orleans-7-0#messaging'
        },

    ]


    constructor(props) {
        super(props);
        this.state.slug = props.params.slug;
    }

    componentDidUpdate(prevProps, prevState, False) {
        console.log('DistributedPage: Rebinding jquery hooks after document update');
        // console.log('MarkdownDisplay: component updating ' + this.state.html);

        // Connect Copy and Tag functionality
        attachCopyTagHandlers();

        $("div[data-mime-type='application/vnd.jupyter.stderr']").hide();

        setTimeout(() => Prism.highlightAll(), 0);
    }

    componentDidUnMount() {
        console.log('Distribubted Page component UNMOUNTED');
    }


    componentDidMount() {

        // Get the contents from the HTML file and put them in the React state, so we can reference it in render() below.
        const path = this.slugs.filter(path => path.slug === this.state.slug);
        console.log("OP Slug [" + this.state.slug + "] matched to url [" + path[0].source + "]")

        if (path.length === 0) {
            console.error('DP Failed to located path for : ' + this.state.slug);
            //this.setState((state) => ({ ...state, "Failed to located requested content" }));
        }

        const next = this.nextPage.filter(item => item.key === this.state.slug)[0];
        const related = this.relatedPages.filter(item => item.key === this.state.slug);
        const suggested = this.suggestedCourses.filter(item => item.key === this.state.slug);

        this.setState({
            nextContent: next !== undefined ? next : {
                key: '',
                slug: '',
                title: 'Distributed',
                path: '/distributed-computing/'
            },
            relatedContent: related,
            title: path[0].title,
            tags: path[0].tags,
            sourceLinks: path[0].sourceLinks,
            languages: path[0].languages,
            algos: path[0].algos !== undefined ? path[0].algos : [],
            suggestedCourses: [],
            attributed: path[0].attributed

        });
        if (this.state) {
            console.log("DP Next Page Value determined as : " + this.state.nextContent);
        }

        fetch(path[0].source)
            .then(res => res.text())
            .then(html => this.setState((state) => ({...state, html})))
            //.then(html => console.log(html))
            .catch((err) => console.error(err));
        console.log("DP Page content was successfully retrieved ");

        console.log('DistributedPage component mounted ' + this.state.html);
    }


    render() {

        return (
            <PageComponent props={this.state} page="Distributed Page"/>
        );

    }
}


export default withParams(DCPage);