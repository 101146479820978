import ErrorBoundary from "../errorBoundary/errorBoundary";
import GeneralError from "../pages/generalError";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Prism from "../../prism-plugins-tomorrow";
import CommentBox from "../commentsComponent/comment";
import TableOfContent from "../tableContents/tableContents";


const PageComponent = ({props, page}) => {

        const { html, nextContent, relatedContent, interactiveNotebook, suggestedCourses} = props;

        const options = {};
        options.dangerouslySetInnerHTML = {__html: html};
        console.log({page} + ' : RENDER: content set for component');

        return (
            <ErrorBoundary FallbackComponent={GeneralError}>
                <ToastContainer
                    autoClose={2500}
                    hideProgressBar={true}
                />
                <header className="bg-gradient-to-t from-gray-50-to-white via-white dark:via-gray-950 pt-2">
                    <div className="container relative">
                        <h1 className="flex items-center flex-wrap text-lg leading-tight mb-2 md:text-xl ">
                            <div className="flex items-center mb-1">
                                <svg className="mr-1.5 text-gray-400" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 25 25">
                                    <ellipse cx="12.5" cy="5" fill="currentColor" rx="7.5" ry="2" />
                                    <path d="M12.5 15C16.6421 15 20 14.1046 20 13V20C20 21.1046 16.6421 22 12.5 22C8.35786 22 5 21.1046 5 20V13C5 14.1046 8.35786 15 12.5 15Z" fill="currentColor" opacity="0.5" />
                                    <path d="M12.5 7C16.6421 7 20 6.10457 20 5V11.5C20 12.6046 16.6421 13.5 12.5 13.5C8.35786 13.5 5 12.6046 5 11.5V5C5 6.10457 8.35786 7 12.5 7Z" fill="currentColor" opacity="0.5" />
                                    <path d="M5.23628 12C5.08204 12.1598 5 12.8273 5 13C5 14.1046 8.35786 15 12.5 15C16.6421 15 20 14.1046 20 13C20 12.8273 19.918 12.1598 19.7637 12C18.9311 12.8626 15.9947 13.5 12.5 13.5C9.0053 13.5 6.06886 12.8626 5.23628 12Z" fill="currentColor" />
                                </svg>
                                <span className="text-gray-400 mr-3 font-bold">Tutorial:</span>
                            </div>

                            <div className="mb-1">
                                <span className="font-open font-bold">{props.title}</span>

                            </div>
                        </h1>
                        <div className="flex flex-wrap mb-3 lg:mb-5">
                            {props.tags.length > 0 &&
                            <div className="js-tag-group-wrapper mr-1" >
                                    <span className="p-1 text-gray-400 text-sm leading-tight">Tags:
                                    </span>

                                {props.tags.map((data, index) => (
                                    <span key={index} className="tag js-tag-group-entry tag-blue" >
                                    <span>{data}</span>
                                </span>

                                ))}

                            </div>
                            }
                            {props.algos.length > 0 &&
                            <div className="js-tag-group-wrapper mr-1">
                                    <span className="p-1 text-gray-400 text-sm leading-tight">Algorithms:
                                    </span>
                                {props.algos.map((data, index) => (
                                    <span key={index} className="tag js-tag-group-entry tag-blue" >
                                    <span>{data}</span>
                                </span>

                                ))}
                            </div>
                            }
                            {props.languages.length > 0 &&
                            <div className="js-tag-group-wrapper mr-1" >
                                    <span className="p-1 text-gray-400 text-sm leading-tight">Languages:
                                    </span>
                                {props.languages.map((data, index) => (
                                    <span key={index} className="tag js-tag-group-entry tag-green" >
                                    <span >{data}</span>
                                </span>

                                ))}
                            </div>
                            }
                        </div>
                        <div className="border-b border-gray-100">
                            <div className="js-tag-group-wrapper mr-1" >
                                    <span className="p-1 text-gray-400 text-sm leading-tight">Attribute:</span>
                                    <span className="tag js-tag-group-entry tag-purple" >
                                    <span>{props.attribute}</span>
                                </span>
                            </div>
                            <div className="flex flex-col-reverse lg:flex-row lg:items-center lg:justify-between" />
                        </div>
                    </div>
                </header>

                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 relative space-y-4 flex flex-col md:grid md:space-y-0 w-full
                                    md:grid-cols-12 2xl:grid-cols-12
                                    md:flex-1 md:grid-rows-full" >
                    <section className="pt-2 border-gray-200  lg:block lg:col-span-2 2xl:col-span-2 pb-24 pr-6 border-r border-t border-gray-100">
                        <div className="flex flex-wrap flex-col xl:flex-row mb-5 border-b border-gray-100 pb-3">
                            <nav id="contentTable">

                            </nav>
                           <TableOfContent />
                        </div>
                    </section>
                    <section className="pt-2 border-gray-200 md:col-span-6 lg:col-span-8 2xl:col-span-6 pb-24 md:pr-6 lg:pl-6">
                        <div className="2xl:pr-6">
                            <div className="prose dark:prose-light" {...options} />
                          {Prism.highlightAll()}
                        </div>
                        <CommentBox/>
                    </section>
                    <section className="pt-2 border-gray-200 md:col-span-2 2xl:col-span-2 md:pb-24 md:pl-6 md:border-l  border-t order-last md:order-none">

                        <div className="flex flex-wrap flex-col xl:flex-row mb-5 border-b border-gray-100 pb-3">
                            {props.sourceLinks &&
                                <div>
                                    {props.sourceLinks.map((data) => (

                                        <a key={data.id} className="text-sm btn mb-2 md:mr-2" href={props.sourceLinks[0]} rel="noreferrer" target="_blank">
                                            <svg className="mr-1.5" xmlns="http://www.w3.org/2000/svg"
                                                 aria-hidden="true" focusable="false" role="img" width="1.03em"
                                                 height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 250">
                                                <path
                                                    d="M128.001 0C57.317 0 0 57.307 0 128.001c0 56.554 36.676 104.535 87.535 121.46c6.397 1.185 8.746-2.777 8.746-6.158c0-3.052-.12-13.135-.174-23.83c-35.61 7.742-43.124-15.103-43.124-15.103c-5.823-14.795-14.213-18.73-14.213-18.73c-11.613-7.944.876-7.78.876-7.78c12.853.902 19.621 13.19 19.621 13.19c11.417 19.568 29.945 13.911 37.249 10.64c1.149-8.272 4.466-13.92 8.127-17.116c-28.431-3.236-58.318-14.212-58.318-63.258c0-13.975 5-25.394 13.188-34.358c-1.329-3.224-5.71-16.242 1.24-33.874c0 0 10.749-3.44 35.21 13.121c10.21-2.836 21.16-4.258 32.038-4.307c10.878.049 21.837 1.47 32.066 4.307c24.431-16.56 35.165-13.12 35.165-13.12c6.967 17.63 2.584 30.65 1.255 33.873c8.207 8.964 13.173 20.383 13.173 34.358c0 49.163-29.944 59.988-58.447 63.157c4.591 3.972 8.682 11.762 8.682 23.704c0 17.126-.148 30.91-.148 35.126c0 3.407 2.304 7.398 8.792 6.14C219.37 232.5 256 184.537 256 128.002C256 57.307 198.691 0 128.001 0zm-80.06 182.34c-.282.636-1.283.827-2.194.39c-.929-.417-1.45-1.284-1.15-1.922c.276-.655 1.279-.838 2.205-.399c.93.418 1.46 1.293 1.139 1.931zm6.296 5.618c-.61.566-1.804.303-2.614-.591c-.837-.892-.994-2.086-.375-2.66c.63-.566 1.787-.301 2.626.591c.838.903 1 2.088.363 2.66zm4.32 7.188c-.785.545-2.067.034-2.86-1.104c-.784-1.138-.784-2.503.017-3.05c.795-.547 2.058-.055 2.861 1.075c.782 1.157.782 2.522-.019 3.08zm7.304 8.325c-.701.774-2.196.566-3.29-.49c-1.119-1.032-1.43-2.496-.726-3.27c.71-.776 2.213-.558 3.315.49c1.11 1.03 1.45 2.505.701 3.27zm9.442 2.81c-.31 1.003-1.75 1.459-3.199 1.033c-1.448-.439-2.395-1.613-2.103-2.626c.301-1.01 1.747-1.484 3.207-1.028c1.446.436 2.396 1.602 2.095 2.622zm10.744 1.193c.036 1.055-1.193 1.93-2.715 1.95c-1.53.034-2.769-.82-2.786-1.86c0-1.065 1.202-1.932 2.733-1.958c1.522-.03 2.768.818 2.768 1.868zm10.555-.405c.182 1.03-.875 2.088-2.387 2.37c-1.485.271-2.861-.365-3.05-1.386c-.184-1.056.893-2.114 2.376-2.387c1.514-.263 2.868.356 3.061 1.403z"
                                                    fill="currentColor">
                                                </path>
                                            </svg>
                                            Github
                                        </a>

                                    ))} <br/></div>
                            }
                            { props.dataFile &&
                                <a className="btn text-sm mb-2 md:mr-2" href={props.dataFile} rel="noreferrer"  target="_blank">
                                    <svg className="mr-1.5" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32">
                                        <path d="M30 28.59L22.45 21A11 11 0 1 0 21 22.45L28.59 30zM5 14a9 9 0 1 1 9 9a9 9 0 0 1-9-9z" fill="currentColor" />
                                    </svg>
                                    Explore Dataset
                                </a>
                            }
                            { props.download &&
                                <a className="btn text-sm mb-2 md:mr-2" href={props.download} rel="noreferrer">
                                    Download Notebook
                                </a>
                            }
                        </div>

                        {nextContent && <div className="flex flex-wrap flex-col xl:flex-row mb-5 border-gray-100 pb-3.5">

                            <div className="flex flex-col border bg-gradient-to-r from-blue-100 via-white to-green-50
            dark:from-gray-900 dark:to-gray-925 dark:hover:to-gray-900 rounded-lg border-gray-100 py-1 px-2 group overflow-hidden mb-1.5 mr-1.5 max-w-full">
                                <div className="text-sm text-green-800 truncate">Next:</div>

                                <a className="text-xs text-blue-400 truncate " href={nextContent.path + nextContent.slug}>{nextContent.title}</a>
                            </div>

                        </div>
                        }

                        {relatedContent.length > 0 && <div className="flex flex-wrap flex-col xl:flex-row mb-5 border-b border-gray-100 pb-3.5">

                            <div className="flex flex-col border bg-gradient-to-r from-blue-100 via-white to-green-50
            dark:from-gray-900 dark:to-gray-925 dark:hover:to-gray-900 rounded-lg border-gray-100 py-1 px-2 group overflow-hidden mb-1.5 mr-1.5 max-w-full">
                                <div className="text-sm text-green-800 truncate cols-10 ">Related Content:</div>
                                {relatedContent.map((data) =>(
                                    <a key={data.id} className="text-xs text-blue-400 truncate" rel="noreferrer"  target="_blank" href={data.link + data.slug}>{data.title}</a>
                                ))}

                            </div>

                        </div>
                        }
                        {interactiveNotebook && <div className="flex flex-wrap flex-col xl:flex-row mb-5 border-b border-gray-100 pb-3.5">

                            <div className="flex flex-col border bg-gradient-to-r from-blue-100 via-white to-green-50
            dark:from-gray-900 dark:to-gray-925 dark:hover:to-gray-900 rounded-lg border-gray-100 py-1 px-2 group overflow-hidden mb-1.5 mr-1.5 max-w-full">
                                <div className="text-sm text-green-800 truncate cols-10 ">Interactive Notebook:</div>
                                    <a className="text-xs text-blue-400 truncate" rel="noreferrer"  target="_blank" href={interactiveNotebook}>Run in MyBinder</a>
                            </div>

                        </div>
                        }
                        {suggestedCourses && <div className="flex flex-wrap flex-col xl:flex-row mb-5 border-b border-gray-100 pb-3.5">

                            <div className="flex flex-col border bg-gradient-to-r from-blue-100 via-orange-50 to-green-50
            dark:from-gray-900 dark:to-gray-925 dark:hover:to-gray-900 rounded-lg border-gray-100 py-1 px-2 group overflow-hidden mb-1.5 mr-1.5 max-w-full">
                                <div className="text-sm text-green-800 truncate cols-10 ">Suggested Courses:</div>
                                {suggestedCourses.map((data) =>(
                                    <a key={data.id} className="text-xs text-blue-400 truncate" rel="noreferrer"  target="_blank" href={data.link + data.slug}>{data.title}</a>
                                ))}

                            </div>

                        </div>
                        }

                    </section>
                </div>
            </ErrorBoundary>);


}


export default PageComponent;
