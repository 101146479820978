import {useEffect} from "react";


function TableOfContent() {

    let headings = [];

    useEffect(() => {
        headings = Array.from(document.querySelectorAll("h2, h3, h4"))
            .map((elem) => ({
                text: elem.innerText,
                id: elem.id

            }))

        //setHeadings(elements)
        console.log("Headings detected " + headings.length);
    }, [])


    return (
        <nav>
            <ul>
                {headings.map(heading => (
                    <li key={heading.text}>
                        <a href={ '#' + heading.id}>{heading.text} + 'ddddddddddd'</a>
                        </li>
                        ))}
                    </ul>
                    </nav>
                );
}
export default TableOfContent