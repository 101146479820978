import React, { Component } from 'react';
import LandingPanel from "../../landingPagePanel/landingPagePanel";

class OrleansTutorials extends Component{

    constructor(props){
        super(props);
        this.gettingStartedTutorials = [
            {id: 1,
                title: 'Getting started with Microsoft Orleans',
                content: 'Using Microsoft Orleans',
                image:"/images/distributed/panel-distributed-computing-orleans.svg",
                slug:'microsoft-orleans-getting-started',
                sourceLinks: '',
                enabled: true},
            {id: 2,
                title: 'Hello Computer',
                content: 'Simple Hello Computer Example',
                image:"/images/distributed/orleans/panel-hello-computer.svg",
                slug:'microsoft-orleans-hello-world',
                sourceLinks: '',
                enabled: true},

            {id: 3,
                title: 'Chat Application ',
                content: 'How to create a chat application',
                image:"/images/distributed/orleans/panel-orleans-chat-example.svg",
                slug:'microsoft-orleans-chat-application',
                sourceLinks: '',
                enabled: true},
            {id: 4,
                title: 'IOT Example',
                content: 'Basic IOT example with Orleans',
                image:"/images/distributed/orleans/panel-orleans-iot-example.svg",
                slug:'microsoft-orleans-iot-example',
                sourceLinks: '',
                enabled: false}
        ];


        this.simpleExamples = [
            {id: 1,
                title: 'Bank Account',
                content: 'How a Bank account could be implemented with Orleans',
                image:"/images/distributed/orleans/panel-orleans-bank-account.svg",
                slug:'microsoft-orleans-bank-account',
                sourceLinks: '',
                enabled: false},
            {id: 2,
                title: 'Stock Market Prices',
                content: 'Display Stock Market prices',
                image:"/images/distributed/panel-time-series.svg",
                slug:'microsoft-orleans-stock-price',
                sourceLinks: '',
                enabled: false}

        ];

        this.deployment = [
            {id: 1,
                title: 'Basic Deployment',
                content: 'Deploy a basic Orleans application',
                image:"/images/distributed/orleans/panel-orleans-basic-deployment.svg",
                slug:'microsoft-orleans-basic-deployment',
                sourceLinks: '',
                enabled: false},
            {id: 2,
                title: 'Docker Deployment',
                content: 'Deploy Microsoft Orleans with Docker',
                image:"/images/distributed/orleans/panel-orleans-docker-deployment.svg",
                slug:'microsoft-orleans-docker-deployment',
                sourceLinks: '',
                enabled: false},
            {id: 3,
                title: 'Azure Deployment',
                content: 'Deploy Microsoft Orleans into Azure',
                image:"/images/distributed/orleans/panel-orleans-azure-deployment.svg",
                slug:'microsoft-orleans-azure-deployment',
                sourceLinks: '',
                enabled: false}

        ];

    }

    render(){

        return (
            <React.Fragment>
                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-red-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2"></div>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Getting started
                                        </h2></div>
                                </div>
                                <div className="h-6"></div>
                                <p className="leading-snug text-gray-500">Getting setup and started with Orleans
                                </p>
                                <div className="h-6"></div>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.gettingStartedTutorials.map((data) => (
                                <LandingPanel key={data.id} title={data.title} imageSource={data.image} to={"/distributed-computing/microsoft-orleans/tutorials/" + data.slug} strapLine={data.content} enabled={data.enabled} />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-blue-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2"></div>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Examples
                                        </h2></div>
                                </div>
                                <div className="h-6"></div>
                                <p className="leading-snug text-gray-500">Basic examples using Orleans
                                </p>
                                <div className="h-6"></div>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.simpleExamples.map((data) => (
                                <LandingPanel key={data.id} title={data.title} imageSource={data.image} to={"/distributed-computing/microsoft-orleans/examples/" + data.slug} strapLine={data.content} enabled={data.enabled}  />
                            ))}
                        </div>
                    </div>
                </div>


                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-green-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2"></div>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Deployment
                                        </h2></div>
                                </div>
                                <div className="h-6"></div>
                                <p className="leading-snug text-gray-500">Deploying Orleans Applications
                                </p>
                                <div className="h-6"></div>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.deployment.map((data) => (
                                <LandingPanel key={data.id} title={data.title} imageSource={data.image} to={"/distributed-computing/microsoft-orleans/tutorials/" + data.slug} strapLine={data.content} enabled={data.enabled} />
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )

    };

}

export default OrleansTutorials;