import React, { Component } from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
     
import LandingPanel from '../landingPagePanel/landingPagePanel';

class MLExamples extends Component{


    constructor(props){
        super(props);
        this.classificationExamples = [
                {id: 1, 
                    title: 'Installation CLI', 
                    content: 'Installing ML.NET for the CLI', 
                    image: "/images/panel-classification.svg", 
                    slug:'mlnet-getting-started-cli'},
                {id: 2, 
                    title: 'Installation IDE', 
                    content: 'Installing ML.NET and VSCode interactive', 
                    image: '/images/panel-classification.svg', 
                    slug:'mlnet-getting-started-ide'},
                {id: 3, 
                    title: 'Installation IDE', 
                    content: 'Installing ML.NET and VSCode interactive', 
                    image: '/images/panel-classification.svg', 
                    slug:'mlnet-getting-started-ide'},
                
            ];


        this.regressionExamples = [
            {id: 1, 
                title: 'Basic Regression', 
                content: 'A basic regression example', 
                image: '/images/panel-regression.svg', 
                slug:'basic-house-price-prediction'},
            {id: 2, 
                title: 'Basic Regression', 
                content: 'A basic regression example', 
                image: '/images/panel-regression.svg', 
                slug:'basic-house-price-prediction'}
            
        ];

        this.nlpExamples = [
            {id: 1, 
                title: 'Basic NLP example', 
                content: 'Installing ML.NET and VSCode interactive', 
                image: '/images/panel-nlp-processing.svg',
                slug: 'basic-nlp-example'},
            {id: 2, 
                title: 'Installation', 
                content: 'Installing ML.NET and VSCode interactive', 
                image: '/images/panel-nlp-processing.svg',
                slug: 'basic-nlp-example'},
        ];
    }
    

    render(){

        return (
            <div className="container-fluid">
                <div className="main-content">

                <Accordion className="material-accordion" disabled>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className="material-accordion-title">Classification Examples</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography component={'span'} variant={'body2'}>
                    <ul className="two-columns">
              
                        {this.classificationExamples.map((data) => (
                            <li key={data.id} className="content-link-item"> 
                            <LandingPanel title={data.title} imageSource={data.image} to={"/machine-learning/examples/mlnet/" + data.slug} strapLine={data.content} />
                                                
                            </li>
                        ))}
                    </ul>
                    </Typography>
                    </AccordionDetails>
                </Accordion>
              
                <Accordion className="material-accordion" disabled>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className="material-accordion-title">Regression Examples</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography component={'span'} variant={'body2'}>
                    <ul className="two-columns">
              
                        {this.regressionExamples.map((data) => (
                            <li key={data.id} className="content-link-item"> 
                            <LandingPanel title={data.title} imageSource={data.image} to={"/machine-learning/examples/mlnet/" + data.slug} strapLine={data.content} />
                                                
                            </li>
                        ))}
                    </ul>
                    </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="material-accordion" disabled>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className="material-accordion-title">Natural Language Processing Examples</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography component={'span'} variant={'body2'}>
                    <ul className="two-columns">
              
                        {this.nlpExamples.map((data) => (
                            <li key={data.id} className="content-link-item"> 
                            <LandingPanel title={data.title} imageSource={data.image} to={"/machine-learning/examples/mlnet/" + data.slug} strapLine={data.content} />
                                                
                            </li>
                        ))}
                    </ul>
                    </Typography>
                    </AccordionDetails>
                </Accordion>

                </div>
            </div>
        )
    };

}

export default MLExamples;