import React, { Component} from 'react';

class NewsPanel extends Component {

    render() {

        return (

            <div className="bg-yellow">
                <div
                    className="h-500 p-lg-1 pr-1 rounded-lg bg-gradient-to-r from-blue-50 to-yellow dark:from-gray-900 dark:to-gray-950 mb-50 flex justify-left shadow-inner">
                    <div className="flex flex-col pl-2">
                        <div className="flex items-center rounded-lg">
                            <div>
                                <h3 className="font-semibold leading-tight mt-6 text-green-800 false">{this.props.title}</h3>
                            </div>
                        </div>
                        <div className="news-image-frame">
                            <img alt="Image for News Panel" className="news-panel-image" src={this.props.imageSource}/>
                        </div>

                        <div className="h-6"></div>
                        <p className="leading-snug text-gray-500">{this.props.preview}</p>
                        <div className="h-6"></div>
                        <p dir="rtl"><a href={this.props.link} className="mt-auto self-end border-b
                                        hover:text-red-700 pb-0.5 hover:border-red-700 border-red-700" target="_blank">More</a></p>
                    </div>
                </div>
            </div>

        );
    }
}

export default NewsPanel;