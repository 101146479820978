import React, { Component } from 'react';
import LandingPanel from '../../landingPagePanel/landingPagePanel';

class Spark extends Component{

    constructor(props){
        super(props);
        this.SparkConcepts = [
            {id: 1, title: 'What is Apache Spark?',
                content: 'An introduction to Apache Spark and what it can do.',
                slug:'what-is-apache-Spark',
                image: "/images/distributed/spark/panel-apache-Spark.svg",
                enabled: true},
            {id: 2, title: 'Concepts in Apache Spark',
                content: 'The concepts behind Apache Spark',
                slug:'apache-Spark-concepts',
                image: "/images/distributed/spark/panel-Spark-concepts.svg",
                enabled: true},
            {id: 3, title: 'State in Apache Spark',
                content: 'How Apache Spark handles state',
                slug:'apache-Spark-state',
                image: "/images/distributed/spark/panel-Spark-state.svg",
                enabled: true},
            {id: 4, title: 'Persistence in Apache Spark',
                content: 'How Apache Spark handles persistence',
                slug: 'apache-Spark-persistence',
                image: '/images/distributed/spark/Spark-persistence.svg',
                enabled: true}

        ];

        this.SparkSimpleExamples = [
            {id: 1, title: 'Hello Computer',
                content: 'Simple Hello world example',
                slug:'Spark-hello-computer',
                image: '/images/distributed/Spark/panel-Spark-hello-world.svg',
                enabled: true},
            {id: 2, title: 'Common Algorithms',
                content: 'Some of the algorithms that power Machine Learning       ',
                slug:'common-machine-learning-algorithms',
                image: '/images/panel-equation.svg',
                enabled: true},
            {id: 3, title: 'Data and Feature Analysis',
                content: 'Introduction to data cleansing and feature engineering',
                slug:'data-and-feature-engineering',
                image: '/images/data-analysis.svg',
                enabled: true},
            {id: 4, title: 'When to not use Machine Learning',
                content: 'Inferred learning is not always the correct approach',
                slug: 'when-to-not-use-machine-learning',
                image: '/images/panel-people-working.svg',
                enabled: true},

        ];

        this.tutorials = [
            {id: 1, title: 'Hello Classification',
                content: 'Training a simple ML.NET classification model',
                slug:'basic-classification-problem',
                image: '/images/panel-classification.svg'},
            {id: 2, title: 'Clustering',
                content: 'Training a simple ML.NET clustering model',
                slug:'basic-clustering-problem',
                image: '/images/panel-clustering.svg'}

        ];

        this.regressionBasics = [
            {id: 1, title: 'What is a regression problem',
                content: 'Installing ML.NET and VSCode interactive',
                slug:'basic-regression',
                image: '/images/spark/panel-default-image.svg'},
            {id: 2, title: 'Hello Regression',
                content: 'Training a simple ML.NET Regression model',
                slug:'basic-regression-problem',
                image: '/images/spark/panel-regression.svg'},
            {id: 3, title: 'What is a regression problem',
                content: 'Installing ML.NET and VSCode interactive',
                slug:'basic-regression',
                image: '/images/spark/panel-default-image.svg'},
            {id: 4, title: 'Hello Regression',
                content: 'Training a simple ML.NET Regression model',
                slug:'basic-regression-problem',
                image: '/images/spark/panel-regression.svg'},

        ];
    }

    render(){

        return (
            <React.Fragment>
                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-red-400 text-2xl" xmlns="http://www.w3.org/2000/svg"
                                     aria-hidden="true" focusable="false"
                                     role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet"
                                     viewBox="0 0 24 24">
                                    <path className="uim-quaternary"
                                          d="M20.23 7.24L12 12L3.77 7.24a1.98 1.98 0 0 1 .7-.71L11 2.76c.62-.35 1.38-.35 2 0l6.53 3.77c.29.173.531.418.7.71z"
                                          opacity=".25" fill="currentColor" />
                                    <path className="uim-tertiary"
                                          d="M12 12v9.5a2.09 2.09 0 0 1-.91-.21L4.5 17.48a2.003 2.003 0 0 1-1-1.73v-7.5a2.06 2.06 0 0 1 .27-1.01L12 12z"
                                          opacity=".5" fill="currentColor" />
                                    <path className="uim-primary"
                                          d="M20.5 8.25v7.5a2.003 2.003 0 0 1-1 1.73l-6.62 3.82c-.275.13-.576.198-.88.2V12l8.23-4.76c.175.308.268.656.27 1.01z"
                                          fill="currentColor" />
                                </svg>
                                <div className="h-2" />
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Spark Concepts
                                        </h2></div>
                                </div>
                                <div className="h-6" />
                                <p className="leading-snug text-gray-500">Some of the concepts underpinning Apache Spark
                                </p>
                                <div className="h-6" />
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.SparkConcepts.map((data) => (
                                <LandingPanel title={data.title} imageSource={data.image} to={"/distributed-computing/apache-Spark/concepts/" + data.slug} strapLine={data.content}  />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-blue-400 text-2xl" xmlns="http://www.w3.org/2000/svg"
                                     aria-hidden="true" focusable="false"
                                     role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet"
                                     viewBox="0 0 24 24">
                                    <path className="uim-quaternary"
                                          d="M20.23 7.24L12 12L3.77 7.24a1.98 1.98 0 0 1 .7-.71L11 2.76c.62-.35 1.38-.35 2 0l6.53 3.77c.29.173.531.418.7.71z"
                                          opacity=".25" fill="currentColor"></path>
                                    <path className="uim-tertiary"
                                          d="M12 12v9.5a2.09 2.09 0 0 1-.91-.21L4.5 17.48a2.003 2.003 0 0 1-1-1.73v-7.5a2.06 2.06 0 0 1 .27-1.01L12 12z"
                                          opacity=".5" fill="currentColor"></path>
                                    <path className="uim-primary"
                                          d="M20.5 8.25v7.5a2.003 2.003 0 0 1-1 1.73l-6.62 3.82c-.275.13-.576.198-.88.2V12l8.23-4.76c.175.308.268.656.27 1.01z"
                                          fill="currentColor"></path>
                                </svg>
                                <div className="h-2"></div>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Simple Examples
                                        </h2></div>
                                </div>
                                <div className="h-6"></div>
                                <p className="leading-snug text-gray-500">Some simple examples with Apache Spark
                                </p>
                                <div className="h-6"></div>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.SparkSimpleExamples.map((data) => (
                                <LandingPanel title={data.title} imageSource={data.image} to={"/distributed-computing/apache-Spark/examples/" + data.slug} strapLine={data.content}  />
                            ))}
                        </div>
                    </div>
                </div>


                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-green-400 text-2xl" xmlns="http://www.w3.org/2000/svg"
                                     aria-hidden="true" focusable="false"
                                     role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet"
                                     viewBox="0 0 24 24">
                                    <path className="uim-quaternary"
                                          d="M20.23 7.24L12 12L3.77 7.24a1.98 1.98 0 0 1 .7-.71L11 2.76c.62-.35 1.38-.35 2 0l6.53 3.77c.29.173.531.418.7.71z"
                                          opacity=".25" fill="currentColor"></path>
                                    <path className="uim-tertiary"
                                          d="M12 12v9.5a2.09 2.09 0 0 1-.91-.21L4.5 17.48a2.003 2.003 0 0 1-1-1.73v-7.5a2.06 2.06 0 0 1 .27-1.01L12 12z"
                                          opacity=".5" fill="currentColor"></path>
                                    <path className="uim-primary"
                                          d="M20.5 8.25v7.5a2.003 2.003 0 0 1-1 1.73l-6.62 3.82c-.275.13-.576.198-.88.2V12l8.23-4.76c.175.308.268.656.27 1.01z"
                                          fill="currentColor"></path>
                                </svg>
                                <div className="h-2"></div>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Advanced Examples
                                        </h2></div>
                                </div>
                                <div className="h-6"></div>
                                <p className="leading-snug text-gray-500">Some more in depth examples
                                </p>
                                <div className="h-6"></div>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.tutorials.map((data) => (
                                <LandingPanel title={data.title} imageSource={data.image} to={"/distributed-computing/apache-Spark/tutorials/" + data.slug} strapLine={data.content}  />
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    };

}

export default Spark;