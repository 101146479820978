import React, { Component } from 'react';

import Prism from '../../prism-plugins-tomorrow'
import {withParams} from "../../App";
import {attachCopyTagHandlers} from "../../utility";
import PageComponent from "../pageComponent/pageComponent";
import $ from "jquery";

class MLNETPage extends Component {


    state = {
        title: '',
        tags: [],
        html: '',
        nextContent:'',
        relatedContent: [],
        sourceLinks: [],
        algos: [],
        languages: [],
        attributed:[],
        suggestedCourses:[],
        dataFile:'',
        slug: ''
    };

    slugs = [
        {slug:'getting-started-cli',
            source: '/ml/tutorials/mlnet/mlnet-getting-started-cli-test.html',
            title: 'Getting started from the Command Line',
            tags: ['ML', 'ML.NET'],
            algos: [''],
            languages: ['C#'],
            sourceLinks:[],
            attributed:'' },
        {slug:'getting-started-ide',
            source: '/ml/tutorials/mlnet/mlnet-getting-started-ide.html',
            title: 'Getting started using an IDE',
            tags: ['IDE'],
            algos: ['FastTree'], languages: ['C#'],
            dataFile: '/data/explore/titanic-train-data.csv?label=Survived',
            sourceLinks:['https://github.com/intelligeus/machine-learning-mlnet/tree/main/TitanicApp'], attributed:'' },
        {slug:'dotnet-interactive-notebook',
            source: '/ml/tutorials/mlnet/mlnet-dotnet-interactive-notebook.html',
            title: 'What are dotnet interactive notebooks',
            tags: ['Interactive'],
            algos: [], languages: ['C#'],
            dataFile: '',
            sourceLinks:[],
            attributed:'' },
        {slug:'dotnet-interactive-housing',
            source: '/ml/tutorials/mlnet/housing-example.html',
            title: 'House Price Prediction', tags: [], algos: [], languages: ['C#'], sourceLinks:[], attributed:'' },
        {slug:'interactive-iris-classification',
            source: '/ml/tutorials/mlnet/interactive/mlnet-interactive-iris-classification.html',
            title: 'Interactive Iris Classification',
            tags: ['Interactive', 'Classification', 'Clustering'],
            algos: ['MulticlassClassification', 'SdcaMaximumEntropyMulti'],
            languages: ['C#'],
            dataFile: '/data/explore/iris.csv?label=class', sourceLinks:['https://github.com/intelligeus/machine-learning-mlnet/tree/main/TitanicApp'], attributed:'',
            download: '/download/notebook/interactive-iris-classification.ipynb'
        },
        {slug:'mlnet-interactive-time-series',
            source: '/ml/tutorials/mlnet/interactive/mlnet-interactive-time-series.html',
            title: 'Interactive Stock Market Prediction tutorial',
            tags: ['Forecasting', 'Time Series'],
            algos: ['SSA'],
            languages: ['C#'],
            dataFile: '/data/explore/msft-train-data.csv?label=Adj Close', sourceLinks:[], attributed:'',
            download: '/download/notebook/interactive-stock-market-prediction.ipynb'},
        {slug:'mlnet-binary-image-classification',
            source: '/ml/tutorials/mlnet/mlnet-binary-image-classification.html',
            title: 'Basic Image Classification',
            tags: ['Classification', 'Images', 'Tensorflow'],
            algos: ['BinaryClassification', 'MulticlassClassification'],
            languages: ['C#'],
            dataFile: '', sourceLinks:['https://github.com/intelligeus/machine-learning-mlnet/tree/main/BinaryImageClassification'], attributed:''},
        {slug:'mlnet-titanic-binary-classification',
            source: '/ml/tutorials/mlnet/mlnet-titanic-binary-classification.html',
            title: 'Binary Classification Example',
            tags: ['Classification'],
            algos: ['FastTree', 'FastForest'],
            languages: ['C#'],
            dataFile: '/data/explore/titanic-train-data.csv?label=Survived', sourceLinks:['https://github.com/intelligeus/machine-learning-mlnet/tree/main/TitanicApp'], attributed:''},
        {slug:'mlnet-interactive-titanic-binary-classification',
            source: '/ml/tutorials/mlnet/interactive/mlnet-interactive-titanic-classification.html',
            title: 'Interactive Binary Classification',
            tags: ['Classification', 'Interactive'],
            algos: ['FastTree', 'FastForest'],
            languages: ['C#'],
            dataFile: '/data/explore/titanic-train-data.csv?label=Survived', sourceLinks:[], attributed:'',
            download: '/download/notebook/interactive-titanic-classification.ipynb'},
        {slug:'mlnet-multi-image-classification',
            source: '/ml/tutorials/mlnet/mlnet-multi-image-classification.html',
            title: 'Multi Image Classification',
            tags: ['Classification', 'Tensorflow'],
            algos: ['Softmax2'],
            languages: ['C#'],
            sourceLinks:['https://github.com/intelligeus/machine-learning-mlnet/tree/main/MultiImageClassification'], attributed:''},
        {slug:'mlnet-stock-price-prediction',
            source: '/ml/tutorials/mlnet/mlnet-stock-price-prediction.html',
            title: 'Stock price prediction (time series)',
            tags: ['Regression', 'Time series'],
            algos: ['SSA'],
            languages: ['C#'],
            dataFile: '/data/explore/msft-train-data.csv?label=Adj Close', sourceLinks:['https://github.com/intelligeus/machine-learning-mlnet/tree/main/StockMarketPrediction'], attributed:''},
        {slug:'mlnet-linear-regression',
            source: '/ml/tutorials/mlnet/mlnet-linear-regression-tutorial.html',
            title: 'Linear Regression Tutorial',
            tags: [],
            algos: ['SCDA'],
            languages: ['C#'],
            dataFile: '/data/explore/taxi-fare-train.csv?label=fare_amount', sourceLinks:['https://github.com/intelligeus/machine-learning-mlnet/tree/main/LinearRegression'], attributed:''},
        {slug:'mlnet-nlp-text-classification',
            source: '/ml/tutorials/mlnet/mlnet-nlp-basic-example.html',
            title: 'NLP Text Classification example',
            tags: ['NLP', 'Text Classification'],
            algos: ['NasBertTrainer'],
            languages: ['C#'],
            dataFile: '/data/explore/home-depot-train.csv?label=relevance', sourceLinks:['https://github.com/intelligeus/machine-learning-mlnet/tree/main/NLPExample'], attributed:''},
        {slug:'mlnet-nlp-senntence-similarity',
            source: '/ml/tutorials/mlnet/mlnet-nlp-sentence-similarity.html',
            title: 'Compare the similarity of sentences',
            tags: ['NLP', 'Text Classification'],
            algos: [''],
            languages: ['C#'],
            dataFile: '/data/explore/home-depot-train.csv?label=relevance', sourceLinks:['https://github.com/intelligeus/machine-learning-mlnet/tree/main/NLPSentences'], attributed:''},

    ];
    nextPage = [
        {key: 'getting-started-cli',
            slug: 'getting-started-ide',
            title: 'Getting stared with an IDE',
            path: '/machine-learning/tutorials/mlnet/'},
        {key: 'getting-started-ide',
            slug: 'dotnet-interactive-notebook',
            title:'Interactive Notebooks',
            path: '/machine-learning/tutorials/mlnet/' },
        {key: 'dotnet-interactive-notebook',
            slug: 'interactive-iris-classification',
            title:'Interactive Iris Classification',
            path: '/machine-learning/tutorials/mlnet/' },
        {key: 'interactive-iris-classification',
            slug: 'mlnet-titanic-binary-classification',
            title:'Binary Classification',
            path: '/machine-learning/tutorials/mlnet/' },
        {key: 'mlnet-titanic-binary-classification',
            slug: 'mlnet-interactive-titanic-binary-classification',
            title:'Interactive Binary Classification',
            path: '/machine-learning/tutorials/mlnet/' },
        {key: 'mlnet-interactive-titanic-binary-classification',
            slug: 'mlnet-binary-image-classification',
            title:'Image Classification',
            path: '/machine-learning/tutorials/mlnet/' },
        {key: 'mlnet-binary-image-classification',
            slug: 'mlnet-multi-image-classification',
            title:'Multi Image Classification',
            path: '/machine-learning/tutorials/mlnet/' },

        {key: 'mlnet-multi-image-classification',
            slug: 'mlnet-linear-regression',
            title:'Linear Regression',
            path: '/machine-learning/tutorials/mlnet/' },
        {key: 'mlnet-linear-regression',
            slug: 'mlnet-interactive-time-series',
            title:'Interactive Time Series',
            path: '/machine-learning/tutorials/mlnet/' },
        {key: 'mlnet-interactive-time-series',
            slug: 'mlnet-stock-price-prediction',
            title:'Stock Price Prediction',
            path: '/machine-learning/tutorials/mlnet/' },

        {key: 'mlnet-stock-price-prediction',
            slug: 'mlnet-nlp-text-classification',
            title: 'MLP Text Classification',
            path: '/machine-learning/tutorials/mlnet/' },


        {key: 'mlnet-nlp-text-classification',
            slug: 'mlnet-nlp-senntence-similarity',
            title: 'Sentence Similarity',
            path: '/machine-learning/tutorials/mlnet/' },
        {key: 'mlnet-nlp-senntence-similarity',
            slug: '',
            title: 'ML.NET Tutorials',
            path: '/machine-learning/tutorials/mlnet'}
    ]

    relatedPages = ['I am a page'];
    suggestedCourses = [];


    constructor(props){
        super(props);
        this.state.slug = props.params.slug;
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        console.log('MarkDown componentDidUpdate ==================== called');
        // Connect Copy and Tag functionality
        attachCopyTagHandlers();

        $('script').each(function() {

            if(this.parentNode.nodeName === 'HEAD') {
                return;
            }

            try{
                if(this.innerHTML.includes('renderPlotly')){
                    eval(this.innerHTML);
                }
            }catch(err){
                console.log(err);
            }

        });

        $("div[data-mime-type='application/vnd.jupyter.stderr']").hide();

        setTimeout(() => Prism.highlightAll(), 0);

    }

    componentDidUnMount() {
        console.log('MarkDown component UNMOUNTED');
    }



    componentDidMount() {

        console.log("MD ConsoleDidMount called with slug: " + this.state.slug );
        // Get the contents from the HTML file and put them in the React state, so we can reference it in render() below.
        const path = this.slugs.filter(path => path.slug === this.state.slug);
        console.log("MD Slug [" + this.state.slug + "] matched to url [" + path[0].source + "]" )

        if(path.length === 0){
            console.error('MD Failed to located path for : ' + this.state.slug);
            //this.setState((state) => ({ ...state, "Failed to located requested content" }));
        }

        const next = this.nextPage.filter(item => item.key === this.state.slug)[0];
        const related = this.relatedPages.filter(item => item.key === this.state.slug);
        const suggested = this.suggestedCourses.filter(item => item.key === this.state.slug);

        this.setState({
            nextContent : next,
            relatedContent : related,
            title : path[0].title,
            tags : path[0].tags,
            sourceLinks : path[0].sourceLinks,
            languages: path[0].languages,
            algos: path[0].algos,
            suggestedCourses: null,
            attributed: path[0].attributed,
            dataFile: path[0].dataFile,
            download: path[0].download
        });
        if(this.state){
            console.log("MD Next Page Value determined as : " + this.state.nextContent);
        }

        fetch(path[0].source)
            .then(res => res.text())
            .then(html => this.setState((state) => ({ ...state, html})))
            //.then(html => console.log(html))
            .catch((err) => console.error(err));

        //console.log("MOUNTED There are [" + $(".wiki-preview").length + " ] wiki tags on the page")
        Prism.highlightAll();

        console.log('MarkDown ============================================ component mounted ' + this.state.html);
    }


    render() {

        return (
            <PageComponent props={this.state} page="MD Page"/>
        );


    }
}

export default withParams(MLNETPage);