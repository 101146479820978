import React, { Component } from 'react';
import LandingPanel from '../landingPagePanel/landingPagePanel';

class DCLanding extends Component{

    constructor(props){
        super(props);
        this.distributedComputingBasics = [
                {id: 1, title: 'What is Distributed Computing?',
                        content: 'An introduction to distributed computing and what it can do.',
                        slug:'what-is-distributed-computing',
                        image: "/images/distributed/panel-default-image.svg",
                        enabled: true},
                {id: 2, title: 'Brief history of Distributed Computing',
                        content: 'How distributed computing has evolved over the time',
                        slug:'brief-history-of-distributed-computing',
                        image: "/images/distributed/panel-default-image.svg",
                        enabled: true},
                {id: 3, title: 'Common Distributed Computing Frameworks',
                        content: 'A brief examination of some common Distributed Computing frameworks',
                        slug: 'distributed-computing-frameworks',
                        image: '/images/distributed/panel-distributed-computing-frameworks.svg',
                        enabled: true},
            /*    {id: 4, title: 'How do distributed computing frameworks operate?',
                    content: 'Taking a look at some of the concepts behind distributed computing',
                    slug:'how-do-distributed-frameworks-operate',
                    image: "/images/distributed/panel-default-image.svg",
                    enabled: true}*/
                
            ];

        this.distributedComputingConcepts = [
          {id: 1, title: 'Scalability',
                    content: 'Scalability and Distributed Computing',
                    slug:'distributed-computing-scalability',
                    image: '/images/distributed/panel-distributed-computing-scalability.svg',
                    enabled: false},
          {id: 2, title: 'Reliability',
                    content: 'What do we mean when we talk about reliability',
                    slug:'distributed-computing-reliability',
                    image: '/images/distributed/panel-distributed-computing-resilience.svg',
                    enabled: true},
          {id: 3, title: 'Messaging',
                    content: 'Inter process communication using messaging is an important component of distributed computing',
                    slug:'distributed-computing-messaging',
                    image: '/images/distributed/panel-distributed-computing-messaging.svg',
                enabled: true}
          
        ];

        this.distributedComputingObservability = [
            {id: 1, title: 'Observability in Distributed Computing',
                content: 'What do we mean by observability' ,
                slug: 'observability-in-distributed-computing',
                image: '/images/distributed/panel-distributed-computing-observability.svg',
                enabled: true},
            {id: 2, title: 'OpenTelemetry and Tracing',
                content: 'The current standards on telemetry' ,
                slug: 'open-telemetry-tracing',
                image: '/images/distributed/panel-distributed-computing-observability.svg',
                enabled: true},
            {id: 3, title: 'Instrumenting your code',
                content: 'Instrument code to generate telemetry data' ,
                slug: 'instrumenting-your-code',
                image: '/images/distributed/panel-distributed-computing-observability.svg',
                enabled: true},
            {id: 4, title: 'Prometheus',
                content: 'Managing Time Series Data' ,
                slug: 'prometheus',
                image: '/images/distributed/panel-distributed-computing-prometheus.svg',
                enabled: true},
            {id: 5, title: 'Grafana',
                content: 'Data Visualisation and Dashboards' ,
                slug: 'grafana',
                image: '/images/distributed/panel-distributed-computing-grafana.svg',
                enabled: true},
            {id: 6, title: 'Zipkin',
                content: 'Distributed Tracing System' ,
                slug: 'zipkin',
                image: '/images/distributed/panel-distributed-computing-zipkin.svg',
                enabled: false},
            {id: 7, title: 'Jaeger',
                content: 'Distributed Tracing System' ,
                slug: 'jaeger',
                image: '/images/distributed/panel-distributed-computing-jaeger.svg',
                enabled: true}

        ];

        this.batchProcessing = [
            {id: 1, title: 'Introduction',
                    content: 'What is batch processing',
                    slug:'introduction-to-batch-processing',
                    image: '/images/distributed/panel-distributed-computing-batch-processing.svg',
                    enabled: false},
            {id: 2, title: 'Simple Batch processing example',
                    content: 'Simple batch processing example',
                    slug:'batch-processing-example',
                    image: '/images/distributed/panel-distributed-computing-batch-processing.svg',
                    enabled: false}
            
        ];

        this.streamProcessing = [
            {id: 1, title: 'Introduction',
                    content: 'Introduction to stream processing',
                    slug:'introduction-to-stream-processing',
                    image: '/images/distributed/panel-distributed-computing-stream-processing.svg',
                    enabled:false},
            {id: 2, title: 'Stream Processing Example',
                    content: 'A simple example of stream processing',
                    slug:'basic-regression-problem',
                    image: '/images/distributed/panel-distributed-computing-stream-processing.svg',
                    enabled:false}
            
        ];

    }
    

    render(){

        return (
            <React.Fragment>
               {/* <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 md:py-16">
                    <h2 className="text-2xl font-semibold
											leading-tight text-green-800 false">We are sorry but unfortunately this content is not available at the moment.</h2>
                </div>*/}
            <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0 ">
                    <div className="lg:pr-12 lg:place-self-top">
                        <div className="flex flex-col ">
                            <svg className="text-red-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                            </svg>
                            <div className="h-2" />
                            <div className="flex  rounded-lg">
                                <div>
                                    <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Getting started
                                    </h2></div>
                            </div>
                            <div className="h-6" />
                            <p className="leading-snug text-gray-500">A very brief introduction to Distributed Computing
                            </p>
                            <div className="h-6" />
                        </div>
                    </div>
                    <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                        {this.distributedComputingBasics.map((data) => (
                            <LandingPanel key={data.id} title={data.title} imageSource={data.image} to={"/distributed-computing/basics/" + data.slug} strapLine={data.content}  />
                        ))}
                    </div>
                </div>
            </div>

            <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                    <div className="lg:pr-12 lg:place-self-top">
                        <div className="flex flex-col ">
                            <svg className="text-blue-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                            </svg>
                            <div className="h-2"></div>
                            <div className="flex  rounded-lg">
                                <div>
                                    <h2 className="text-2xl font-semibold
                                                leading-tight text-gray-800 false">Advantages
                                    </h2></div>
                            </div>
                            <div className="h-6"></div>
                            <p className="leading-snug text-gray-500">Some of the advantages offered by Distributed Computing
                            </p>
                            <div className="h-6"></div>
                        </div>
                    </div>
                    <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                        {this.distributedComputingConcepts.map((data) => (
                            <LandingPanel key={data.id} title={data.title} imageSource={data.image} to={"/distributed-computing/concepts/" + data.slug} strapLine={data.content} enabled={data.enabled} />
                        ))}
                    </div>
                </div>
            </div>

            <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16 ">
                <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                    <div className="lg:pr-12 lg:place-self-top">
                        <div className="flex flex-col ">
                            <svg className="text-fuchsia-800 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                            </svg>
                            <div className="h-2"></div>
                            <div className="flex  rounded-lg">
                                <div>
                                    <h2 className="text-2xl font-semibold
                                        leading-tight text-gray-800 false">Observability
                                    </h2></div>
                            </div>
                            <div className="h-6"></div>
                            <p className="leading-snug text-gray-500">Distributed systems can be extremely complex, how we monitor whats going on under the hood.
                            </p>
                            <div className="h-6"></div>
                        </div>
                    </div>
                    <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                        {this.distributedComputingObservability.map((data) => (
                            <LandingPanel key={data.id} title={data.title} imageSource={data.image} to={"/distributed-computing/observability/" + data.slug} strapLine={data.content} enabled={data.enabled}  />
                        ))}
                    </div>
                </div>
            </div>


            <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16 overlay">
                <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                    <div className="lg:pr-12 lg:place-self-top">
                        <div className="flex flex-col ">
                            <svg className="text-green-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                            </svg>
                            <div className="h-2"></div>
                            <div className="flex  rounded-lg">
                                <div>
                                    <h2 className="text-2xl font-semibold
                                                leading-tight text-gray-800 false">Batch Processing
                                    </h2></div>
                            </div>
                            <div className="h-6"></div>
                            <p className="leading-snug text-gray-500">An introduction to Batch Processing
                            </p>
                            <div className="h-6"></div>
                        </div>
                    </div>
                    <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                        {this.batchProcessing.map((data) => (
                            <LandingPanel key={data.id} title={data.title} imageSource={data.image} to={"/distributed-computing/batch-processing" + data.slug} strapLine={data.content} enabled={data.enabled}  />
                        ))}
                    </div>
                </div>
            </div>

            <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16 overlay">
            <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                <div className="lg:pr-12 lg:place-self-top">
                    <div className="flex flex-col ">
                        <svg className="text-purple-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                            <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                        </svg>
                        <div className="h-2" />
                        <div className="flex  rounded-lg">
                            <div>
                                <h2 className="text-2xl font-semibold
                                    leading-tight text-gray-800 false">Stream Processing
                                </h2></div>
                        </div>
                        <div className="h-6" />
                        <p className="leading-snug text-gray-500">An introduction to Stream Processing
                        </p>
                        <div className="h-6" />
                    </div>
                </div>
                <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                    {this.streamProcessing.map((data) => (
                        <LandingPanel key={data.id} title={data.title} imageSource={data.image} to={"/distributed-computing/stream-processing" + data.slug} strapLine={data.content}  enabled={data.enabled} />
                    ))}
                </div>
            </div>
        </div>
    </React.Fragment>
        )
    };

}

export default DCLanding;