import React, { Component } from 'react';


import * as Papa from "papaparse";

import {
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}



class Table extends Component{
    render() {
        return (
            <BootstrapRow>
                <p className="info">Found {this.props.filteredCount || 0} record(s)</p>
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <Head columns={this.props.columns} label={this.props.label} handleSortChange={this.props.handleSortChange} />
                        <Body displayData={this.props.displayData} columns={this.props.columns} label={this.props.label} />
                    </table>
                </div>
            </BootstrapRow>
        );
    }
}

class Head extends Component {

    handleSortChange = (column, event) => {
        event.preventDefault();
        this.props.handleSortChange(column)
    }

    render() {
        return (
            <thead>
            <tr>
                {this.props.columns.map(function(column){
                    if(column === this.props.label){
                        return <th key={column} className="tag-green"><a href="#" onClick={this.handleSortChange.bind(null, column)}>{column}</a></th>
                    }
                    return <th key={column}><a href="#" onClick={this.handleSortChange.bind(null, column)}>{column}</a></th>
                }.bind(this))}
            </tr>
            </thead>
        );
    }
}

class Body extends Component{
    render() {
        return (
            <tbody>
            {this.props.displayData.map(function(r){
                return <Row data={r} columns={this.props.columns} label={this.props.label} />
            }.bind(this))}
            </tbody>
        );
    }
}

class Row extends Component {
    render() {
        const label = this.props.label;
        return (
            <tr>
                {this.props.columns.map(function(h){
                    if(h === label){
                        return <td key={h} className="tag-green-bg">{ this.props.data[h] }</td>
                    }
                    return <td key={h}>{ this.props.data[h] }</td>
                }.bind(this))}
            </tr>
        );
    }
}

class BootstrapRow extends Component{
    render() {
        return (
            <div className="row">
                <div className="col-xs-12">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

class GenericFilter extends Component{

    render() {
        return (
            <BootstrapRow>
                <form className="form-inline">
                    <div className="form-group">
                        <label htmlFor="filterColumn">Filter Value</label>
                        <select className="form-control" id="filterColumn" onChange={this.handleChange} >
                            <option value="">Select column</option>
                            {this.props.columns.map(function(c){
                                return <option key={c} value={c}>{c}</option>
                            })}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="filterValue">Filter Value</label>
                        <input type="text" className="form-control" id="filterValue" onChange={this.handleChange}  />
                    </div>
                </form>
            </BootstrapRow>
        );
    }
}

class Pager extends Component{

    handlePaginationChange = (page, event) => {
        event.preventDefault();
        this.props.handlePaginationChange(page)
    }

    render() {
        if(this.props.filteredCount < 50)
            return null;

        let currentPage = this.props.page;

        let showPages = 9
        let showPagesHalf = Math.floor(showPages / 2.0);
        let totalPages = Math.ceil(this.props.filteredCount / 50.0)
        let startIndex = Math.max(0, (currentPage > (totalPages - showPagesHalf) ? (totalPages - showPages) : (currentPage - showPagesHalf)));

        let pages = []
        let i = startIndex;
        for (; pages.length < Math.min(showPages, totalPages); i++) {
            pages.push({display : i + 1, page : i})
        }

        if(startIndex > 0)
            pages.unshift({display: "...", page: Math.ceil(startIndex / 2.0) })
        if(i < totalPages)
            pages.push({display: "...", page: Math.floor(i + ((totalPages - i) / 2.0))})
        return (
            <BootstrapRow>
                <nav>
                    <div className="text-center">
                        <ul className="pagination">
                            <li>
                                <a href="#" onClick={this.handlePaginationChange.bind(null, 0)}>First</a>
                            </li>
                            <li>
                                <a href="#" aria-label="Previous" onClick={this.handlePaginationChange.bind(null, "-1")}>
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            {pages.map(function(i){
                                let active = currentPage === i.page ? "active" : null;
                                return <li className={active} key={i.page}><a href="#" onClick={this.handlePaginationChange.bind(this, i.page)}>{i.display}</a></li>
                            }.bind(this))}
                            <li>
                                <a href="#" aria-label="Next" onClick={this.handlePaginationChange.bind(null, "+1")}>
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" onClick={this.handlePaginationChange.bind(null, totalPages - 1)}>Last</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </BootstrapRow>
        );
    }
}

class DataExplorer extends Component{

    fileStats = [
        {file: 'iris.csv', data: {rowCount: 150, size: '0',
                columns: ['sepallength', 'sepalwidth', 'petallength', 'petalwidth'],
                types: ['float','float','float','float'],
                valueCount: [150, 150, 150,150],
                std: [0.828066, 0.433594, 1.764420, 0.763161],
                min: [4.300000, 2.000000, 1.000000, 0.100000],
                max: [7.900000, 4.400000, 6.900000, 2.500000],
                mean:[5.843333, 3.054000, 3.758667, 1.198667] }},
        {file: 'msft-train-data.csv', data: {rowCount: 901, size: '0',
                columns: ['Open', 'High', 'Low', 'Close', 'Adj Close', 'Volume'],
                types: ['float','float','float','float', 'float', 'int'],
                valueCount: [901, 901, 901, 901, 901, 901],
                std: [55.347911,   55.880716,   54.919318,   55.451828,   56.461987,  1.351294e+07],
                min: [86.059998,   86.309998,   83.830002,   85.010002,   80.953156,  8.989200e+06],
                max: [289.429993,  290.149994,  286.640015, 289.670013,  289.116516,  1.112421e+08],
                mean:[158.788224,  160.388812,  157.136193,  158.856559,  156.144788,  3.064417e+07]}},
        {file: 'taxi-fare-train.csv', data: {rowCount: 100000, size: '5.3+ MB',
                columns: ['rate_code', 'passenger_count', 'trip_time_in_secs', 'trip_distance', 'fare_amount'],
                types: ['int','int','int', 'float', 'float'],
                valueCount: [100000, 100000, 100000, 10000, 10000],

                std: [0.276297,   1.497018,   3.318861, 9.484526],
                min: [0.0,   0.0,   0.0, 2.500000],
                max: [5.0,  6.0,  97.80, 225.00],
                mean:[1.033480,  1.803710,  2.796149,  11.648603] }},
        {file: 'home-depot-train.csv', data: {rowCount: 3004, size: '318 KB',
                columns: ['relevance'],
                types: ['float'],
                valueCount: [3003],

                std: [0.551780],
                min: [1.0,],
                max: [3.0],
                mean:[2.399221] }},
        {file: 'titanic-train-data.csv', data: {rowCount: 793, size: '5.3+ MB',
                columns: ['rate_code', 'passenger_count', 'trip_time_in_secs', 'trip_distance', 'fare_amount'],
                types: ['int','int','int', 'float', 'float'],
                valueCount: [100000, 100000, 100000, 10000, 10000],

                std: [0.551780],
                min: [1.0,],
                max: [3.0],
                mean:[2.399221] }
        }
    ]

    getFileSelectState = (data) =>
    {
        this.state.data = data.data;
        this.state.columns = data.meta.fields;
        this.state.totalCount = data.data.length;

        return this.getFilterChangeState(null, null, this.state);
    }

    getFilterChangeState = (column, value, fileState) =>
    {
        if(column && value)
        {
            let loweredFilter = value.toLowerCase();
            let filtered = fileState.data.filter(function(d){

                return d[column] && d[column].toString().toLowerCase().indexOf(loweredFilter) > -1
            });
            fileState.filteredData = filtered;
            fileState.filteredCount = filtered.length;
        }
        else
        {
            fileState.filteredData = fileState.data;
            fileState.filteredCount = fileState.totalCount;
        }

        return this.getSortChangeState(null, fileState);
    }

    getSortChangeState = (col, filterState) =>
    {

        let desc;
        if(!col)
        {
            col = filterState.sort.slice(1);
            desc = filterState.sort[0] === "-";
        }
        else
        {
            desc = filterState.sort.slice(1) === col && filterState.sort[0] !== "-";
        }

        filterState.sortedData = filterState.filteredData.sort(function(a, b) {
            return a[col] === b[col] ? 0 : (a[col] < b[col] ? -1 : 1) * (desc ? -1 : 1);
        });
        filterState.sort = (desc ? "-" : "+") + col;


        return this.getPaginationChangeState(0, filterState);
    }

    handleError = () => {
        alert("Could not find that file");
    }

    getPaginationChangeState = (page, sortState) =>
    {
        if(typeof page === "string")
        {
            if(page === "-1" && this.state.page > 0)
                page = sortState.page - 1;
            else if(page === "+1" && sortState.page < Math.floor(sortState.filteredCount / 50))
                page = sortState.page + 1;
            else
                page = sortState.page;
        }

        sortState.page = page;
        sortState.displayData = sortState.sortedData.slice(page * 50, (page + 1) * 50);
        return sortState;
    }

    handleDrop(event)
    {
        event.preventDefault();
    }

    handleFileChange = (file) =>
    {
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: this.handleDataChange
        });


    }

    handleDataChange = (data) => {
        this.setState(this.getFileSelectState(data, this.state));

    }
    handleFilterChange = (column, value) => {
        this.setState(this.getFilterChangeState(column, value, this.state));
    }

    handleSortChange = (col) => {

        this.setState(this.getSortChangeState(col, this.state));
    }

    handlePaginationChange = (page) => {
        this.setState(this.getPaginationChangeState(page, this.state));
    }
    constructor(props) {
        super(props);
        this.state = {
            raw: '',
            data: [],
            filteredData: [],
            sortedData: [],
            displayData: [],
            columns: [],
            totalCount: 0,
            filteredCount: 0,
            page: 0,
            sort: "+yearID",
            fileData: [],
            label: new URLSearchParams(props.router.location.search).get('label'),
            slug: props.router.params.slug
        }
    }

    componentDidMount() {

        let file = "/data/csv/" + this.state.slug;
        console.log("DataExplorer mounted " + file);

        fetch(file)
            .then(res => res.text())
            .then(html => Papa.parse(html, {
                header: true,
                dynamicTyping: true,
                complete: this.handleDataChange,
                error: this.handleError
                }))
            .catch((err) => console.error(err));
        this.state.fileData = this.fileStats.filter(x => x.file === this.state.slug)[0];
        console.log("DataExplorer loaded " + file);
    }

    render() {

        let label = this.state.label;
        let features = this.state.columns.filter(x => x !== label);
        this.state.fileData = this.fileStats.filter(x => x.file === this.state.slug)[0];

        return (

        <React.Fragment>
            <header className="bg-gradient-to-t from-gray-50-to-white via-white dark:via-gray-950 pt-10 ">
                <div className="container relative">
                    <h1 className="flex items-center flex-wrap text-lg leading-tight mb-2 md:text-xl ">
                        <div className="flex items-center mb-1">
                            <svg className="mr-1.5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                                 aria-hidden="true" fill="currentColor"
                                 focusable="false" role="img" width="1em" height="1em"
                                 preserveAspectRatio="xMidYMid meet" viewBox="0 0 18 19">
                                <path
                                    d="M15.4988 8.79309L12.1819 5.47621C12.0188 5.25871 11.7469 5.14996 11.475 5.14996H7.12501C6.52688 5.14996 6.03751 5.63934 6.03751 6.23746V16.025C6.03751 16.6231 6.52688 17.1125 7.12501 17.1125H14.7375C15.3356 17.1125 15.825 16.6231 15.825 16.025V9.55434C15.825 9.28246 15.7163 9.01059 15.4988 8.79309V8.79309ZM11.475 6.23746L14.6831 9.49996H11.475V6.23746ZM7.12501 16.025V6.23746H10.3875V9.49996C10.3875 10.0981 10.8769 10.5875 11.475 10.5875H14.7375V16.025H7.12501Z"/>
                                <path
                                    d="M3.8625 10.5875H2.775V2.97498C2.775 2.37686 3.26438 1.88748 3.8625 1.88748H11.475V2.97498H3.8625V10.5875Z" />
                            </svg>
                            <span className="text-gray-400 mr-3 font-bold">Dataset:
                                    </span>

                        </div>

                        <div className="mb-1">
                            <span className="font-open font-bold" >{this.state.slug}</span>
                        </div>

                        <span className="text-gray-400 ml-3 ">Size:</span>

                        <div className="mb-1">
                            <span className="font-open" >{this.fileStats.filter(x => x.file === this.state.slug)[0].size}</span>
                        </div>

                    </h1>

                    <div className="flex flex-wrap mb-3 lg:mb-5">

                        <div className="js-tag-group-wrapper mr-1" >
                            <span className="p-1 text-gray-400 text-sm leading-tight">Features:</span>

                            {features.map((data) => (
                                <span className="tag js-tag-group-entry tag-purple" >
                                    <span>{data}</span>
                                </span>

                            ))}

                        </div>

                        <div className="js-tag-group-wrapper mr-1" >
                            <span className="p-1 text-gray-400 text-sm leading-tight">Label:</span>
                                <span className="tag js-tag-group-entry tag-green" >
                                    <span>{label}</span>
                                </span>
                        </div>
                    </div>

                    {/*<GenericFilter handleFilterChange={this.handleFilterChange} columns={this.state.columns}/>*/}
                </div>
            </header>

            <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 relative space-y-4 flex flex-col md:grid md:space-y-0 w-full
                                    md:grid-cols-12 2xl:grid-cols-10
                                    md:flex-1 md:grid-rows-full" >
                <section className="pt-6 border-gray-100  lg:block lg:col-span-1 2xl:col-span-1 pb-24 pr-6 border-r border-gray-100">

               {/*     <div className="flex flex-col">
                        { this.state.fileData.data.columns.map((data, i) =>(
                            <div className="flex flex-wrap flex-col xl:flex-row mb-5 border-b border-gray-100 pb-3.5">

                                <div className="flex flex-col grid grid-cols-2 border bg-gradient-to-r from-white via-white to-white
            dark:from-gray-900 dark:to-gray-925 dark:hover:to-gray-900 rounded-lg border-gray-100 py-1 px-2 group overflow-hidden mb-1.5 mr-1.5 max-w-full">
                                    <div className="text-sm text-gray-400 truncate col-span-1">Column:</div><div className="text- truncate col-span-1">{data}</div>
                                    <div className="text-xs text-gray-400 truncate col-span-1">Type</div><div className="text-xs truncate">{this.state.fileData.data.types[i]}</div>
                                    <div className="text-xs text-gray-400 truncate col-span-1">Count</div><div className="text-xs truncate">{this.state.fileData.data.valueCount[i]}</div>
                                    <div className="text-xs text-gray-400 truncate col-span-1">Mean</div><div className="text-xs truncate">{this.state.fileData.data.mean[i]}</div>
                                    <div className="text-xs text-gray-400 truncate col-span-1">Std</div><div className="text-xs truncate">{this.state.fileData.data.std[i]}</div>
                                    <div className="text-xs text-gray-400 truncate col-span-1">Min</div><div className="text-xs truncate">{this.state.fileData.data.min[i]}</div>
                                    <div className="text-xs text-gray-400 truncate col-span-1">Max</div><div className="text-xs truncate">{this.state.fileData.data.max[i]}</div>

                                </div>

                            </div>
                        ))}
                    </div>*/}

                </section>
                <section className=" container pt-8 border-gray-100 md:col-span-8 lg:col-span-8 2xl:col-span-6 pb-24 md:pr-6 lg:pl-6">
                    <div className="font-sans 2xl:pr-6">
                        <Table displayData={this.state.displayData} columns={this.state.columns} label={label} handleSortChange={this.handleSortChange.bind(this)} filteredCount={this.state.filteredCount} />
                        <Pager filteredCount={this.state.filteredCount} page={this.state.page} handlePaginationChange={this.handlePaginationChange.bind(this)} />
                    </div>



                    <div className="flex flex-row">
                        { this.state.fileData.data.columns.map((data, i) =>(
                            <div className="flex flex-wrap flex-col xl:flex-row mb-5 border-b border-gray-100 pb-3.5">

                                <div className="flex flex-col grid grid-cols-2 border bg-gradient-to-r from-white via-white to-white
            dark:from-gray-900 dark:to-gray-925 dark:hover:to-gray-900 rounded-lg border-gray-100 py-1 px-2 group overflow-hidden mb-1.5 mr-1.5 max-w-full">
                                    <div className="text-sm text-gray-400 truncate col-span-1">Column:</div><div className="text- truncate col-span-1">{data}</div>
                                    <div className="text-xs text-gray-400 truncate col-span-1">Type</div><div className="text-xs truncate">{this.state.fileData.data.types[i]}</div>
                                    <div className="text-xs text-gray-400 truncate col-span-1">Count</div><div className="text-xs truncate">{this.state.fileData.data.valueCount[i]}</div>
                                    <div className="text-xs text-gray-400 truncate col-span-1">Mean</div><div className="text-xs truncate">{this.state.fileData.data.mean[i]}</div>
                                    <div className="text-xs text-gray-400 truncate col-span-1">Std</div><div className="text-xs truncate">{this.state.fileData.data.std[i]}</div>
                                    <div className="text-xs text-gray-400 truncate col-span-1">Min</div><div className="text-xs truncate">{this.state.fileData.data.min[i]}</div>
                                    <div className="text-xs text-gray-400 truncate col-span-1">Max</div><div className="text-xs truncate">{this.state.fileData.data.max[i]}</div>

                                </div>

                            </div>
                        ))}
                    </div>
                </section>
                <section className="pt-6 border-gray-100 md:col-span-2 2xl:col-span-2 md:pb-24 md:pl-6 md:border-l order-last md:order-none">


                </section>
            </div>
        </React.Fragment>
        );
    }
}


export default withRouter(DataExplorer);