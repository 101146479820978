import React, { Component } from 'react';
import LandingPanel from '../landingPagePanel/landingPagePanel';

class Courses extends Component{

    constructor(props){
        super(props);

        this.machinelearningCourses = [
            {id: 1, title: 'What is Machine Learning',
                content: 'Setting up your environment',
                slug:'machine-learning-setup-environment',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 2, title: 'Cleansing Data',
                content: 'How to prepare data for analysis',
                slug:'machine-learning-data-cleansing',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 3, title: 'Feature Engineering',
                content: 'What are features and how are they identified',
                slug:'machine-learning-feature-engineering',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 4, title: 'Data Normalization',
                content: 'What is data normalization and why do we use it?',
                slug:'machine-learning-data-normalization',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 5, title: 'Classification',
                content: 'Common uses of Machine Learning for classification problems',
                slug:'machine-learning-classification',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 6, title: 'Regression',
                content: 'Common uses of Machine Learning for regression problems',
                slug:'machine-learning-regression',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 7, title: 'Image Processing',
                content: 'Common uses of Machine Learning for image processing',
                slug:'machine-learning-regression',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 8, title: 'Visualization of Data',
                content: 'Graphing and plotting can be useful tools',
                slug:'machine-learning-data-visualization',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 9, title: 'Analysing Results',
                content: 'What does machine learning tell us',
                slug:'machine-learning-analysing-results',
                image: "/images/panel-default-image.svg",
                enabled: true}

        ];


        this.pythonCourses = [
            {id: 1, title: 'Setting up',
                content: 'Setting up your environment',
                slug:'scikit-learn-setup-environment',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 2, title: 'Loading data',
                content: 'Load data from various sources for analysis',
                slug:'scikit-learn-load-data',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 3, title: 'Training a Classified',
                content: 'How to train a classifier',
                slug:'scikit-learn-train-a-classifier',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 4, title: 'Creating a Pipeline',
                content: 'How to create a pipeline of transforms',
                slug:'scikit-learn-create-a-pipeline',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 5, title: 'Evaluation of results',
                content: 'Interpret the results of a pipeline',
                slug:'scikit-learn-result-evaluation',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 6, title: 'Text Classification',
                content: 'Classify text based on data from Wikipedia',
                slug:'scikit-learn-text-classification',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 7, title: 'Sentiment Analysis',
                content: 'How to perform sentiment analysis on movie recommendations',
                slug:'scikit-learn-sentiment-analysis',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 8, title: 'Image Processing',
                content: '',
                slug:'scikit-learn-sentiment-analysis',
                image: "/images/panel-default-image.svg",
                enabled: true}

        ];

        this.mlnetCourses= [
            {id: 1, title: 'Setting up',
                content: 'How do we achieve scalability',
                slug:'mlnet-set-up-environment',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 2, title: 'Loading Data',
                content: 'Ways to load data with MLNET',
                slug:'mlnet-loading-data',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 3, title: 'Features of MLNET',
                content: 'A look at some of the features MLNET provides',
                slug:'mlnet-features-provided',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 4, title: 'Pipelines in MLNET',
                content: 'How do pipelines work in MLNET',
                slug:'mlnet-pipelines',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 5, title: 'Training a Model',
                content: 'How to train a model',
                slug:'mlnet-training-a-model',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 6, title: 'AutoML',
                content: 'The AutoML API',
                slug:'mlnet-automl',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 7, title: 'Evaluation of Results',
                content: 'How to evaluate results in MLNET',
                slug:'mlnet-evaluating-results',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 8, title: 'Common Algorithms',
                content: 'Algorithms used in MLNET',
                slug:'mlnet-common-algorithms',
                image: "/images/panel-default-image.svg",
                enabled: true}

        ];

        this.tensorflowCourses = [
            {id: 1, title: 'Setting Up',
                content: 'Setup for a tensorflow environment' ,
                slug: 'tensorflow-setup-environment',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 2, title: 'Loading Data',
                content: 'Ways to load data with Tensorflow' ,
                slug: 'tensorflow-load-data',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 3, title: 'Pipelines in Tensorflow',
                content: 'What is happening under the hood' ,
                slug: 'distributed-computing-grafana',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 4, title: 'Classification',
                content: 'Classification in Tensorflow' ,
                slug: 'tensorflow-classification',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 5, title: 'Regression',
                content: 'Regression in Tensorflow' ,
                slug: 'tensorflow-regression',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 6, title: 'Image Processing',
                content: 'Image processing in Tensorflow' ,
                slug: 'tensorflow-image-processing',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 7, title: 'Text Classification',
                content: 'Text Classification in Tensorflow' ,
                slug: 'tensorflow-text-classification',
                image: "/images/panel-default-image.svg",
                enabled: true},
            {id: 8, title: 'Sentiment Analysis',
                content: 'Sentiment Analysis  in Tensorflow' ,
                slug: 'tensorflow-sentiment-analysis',
                image: "/images/panel-default-image.svg",
                enabled: true}

        ];

        this.pytorchCourses = [
            {id: 1, title: 'Setting Up',
                content: 'Setting up a PyTorch environment',
                slug:'pytorch-setup-environment',
                image: "/images/panel-default-image.svg"},
            {id: 2, title: 'Example',
                content: 'Simple batch processing example',
                slug:'batch-processing-example',
                image: "/images/panel-default-image.svg"}

        ];

        this.chatGPT = [
            {id: 1, title: 'Getting started with Chat GPT',
                content: 'How to use Chat GPT',
                slug:'chatgpt-introduction',
                image: "/images/panel-default-image.svg",},
            {id: 2, title: 'Running ChatGPT locally',
                content: 'How to run ChatGPT locally',
                slug:'chatgpt-local-installation',
                image: "/images/panel-default-image.svg",},
            {id: 3, title: 'Large Language Models',
                content: 'What are LLMs',
                slug:'chatgpt-llm',
                image: "/images/panel-default-image.svg",},
            {id: 4, title: 'Chatbots',
                content: 'Chatbots are a common usage for ChatGPT',
                slug:'chatgpt-chatbot',
                image: "/images/panel-default-image.svg",},
            {id: 4, title: 'ChatGPT Alternatives',
                content: 'Are there alternatives to ChatGPT',
                slug:'chatgpt-alternatives',
                image: "/images/panel-default-image.svg",},
        ];

    }


    render(){

        return (
            <React.Fragment>
                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 md:py-16">
                    <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">We are sorry but unfortunately courses are not available at the moment.</h2>
                </div>
                <div className="overlay">

                    <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16 overlay">
                        <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0 ">
                            <div className="lg:pr-12 lg:place-self-top">
                                <div className="flex flex-col ">
                                    <svg className="text-yellow-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                        <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                                    </svg>
                                    <div className="h-2" />
                                    <div className="flex  rounded-lg">
                                        <div>
                                            <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">General Machine Learning
                                            </h2></div>
                                    </div>
                                    <div className="h-6" />
                                    <p className="leading-snug text-gray-500">Covering some common Machine Learning topics
                                    </p>
                                    <div className="h-6" />
                                </div>
                            </div>
                            <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                                {this.machinelearningCourses.map((data) => (
                                    <LandingPanel key={data.id} title={data.title} imageSource={data.image} to={"/machine-learning/courses/ml/" + data.slug} strapLine={data.content}  />
                                ))}
                            </div>
                        </div>
                    </div>
                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16 overlay">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0 ">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-red-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2" />
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Python
                                        </h2></div>
                                </div>
                                <div className="h-6" />
                                <p className="leading-snug text-gray-500">Courses using SciKit, Keras and Tensorflow
                                </p>
                                <div className="h-6" />
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.pythonCourses.map((data) => (
                                <LandingPanel key={data.id} title={data.title} imageSource={data.image} to={"/machine-learning/courses/python/" + data.slug} strapLine={data.content}  />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-blue-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2"></div>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">MLNET
                                        </h2></div>
                                </div>
                                <div className="h-6"></div>
                                <p className="leading-snug text-gray-500">Some of the theory underpinning Distributed Computing
                                </p>
                                <div className="h-6"></div>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.mlnetCourses.map((data) => (
                                <LandingPanel key={data.id} title={data.title} imageSource={data.image} to={"/machine-learning/courses/" + data.slug} strapLine={data.content}  />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-fuchsia-800 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2"></div>
                                <div className="flex rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Tensorflow
                                        </h2></div>
                                </div>
                                <div className="h-6"></div>
                                <p className="leading-snug text-gray-500">Courses using Tensorflow with Java, .NET and Python
                                </p>
                                <div className="h-6"></div>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.tensorflowCourses.map((data) => (
                                <LandingPanel key={data.id} title={data.title} imageSource={data.image} to={"/machine-learning/tensorflow/" + data.slug} strapLine={data.content}  />
                            ))}
                        </div>
                    </div>
                </div>


                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-green-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2"></div>
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">PyTorch
                                        </h2></div>
                                </div>
                                <div className="h-6"></div>
                                <p className="leading-snug text-gray-500">An introduction to Batch Processing
                                </p>
                                <div className="h-6"></div>
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.pytorchCourses.map((data) => (
                                <LandingPanel key={data.id} title={data.title} imageSource={data.image} to={"/machine-learning/pytorch/" + data.slug} strapLine={data.content}  />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="bg-gradient-to-br from-gray-50 via-orange-50 to-orange-50 dark:from-gray-950 dark:to-gray-900 py-16 md:py-16">
                    <div className="container grid lg:grid-cols-3 gap-x-6 gap-y-12 lg:gap-y-0">
                        <div className="lg:pr-12 lg:place-self-top">
                            <div className="flex flex-col ">
                                <svg className="text-purple-400 text-2xl" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 13V21L5 16V8L12 3L19 8L12 13Z" fill="currentColor" opacity="0.5"/>
                                    <path d="M12 21V13M12 21L5.83752 16.5982C5.42695 16.305 5.22166 16.1583 5.11083 15.943C5 15.7276 5 15.4753 5 14.9708V8M12 21L18.1625 16.5982C18.573 16.305 18.7783 16.1583 18.8892 15.943C19 15.7276 19 15.4753 19 14.9708V8M12 13L5 8M12 13L19 8M5 8L10.8375 3.83034C11.3989 3.42938 11.6795 3.2289 12 3.2289C12.3205 3.2289 12.6011 3.42938 13.1625 3.83034L19 8" stroke="#222222"  strokeWidth="1.2" opacity="0.5"/>
                                </svg>
                                <div className="h-2" />
                                <div className="flex  rounded-lg">
                                    <div>
                                        <h2 className="text-2xl font-semibold
											leading-tight text-gray-800 false">Chat GPT
                                        </h2></div>
                                </div>
                                <div className="h-6" />
                                <p className="leading-snug text-gray-500">Using ChatGPT
                                </p>
                                <div className="h-6" />
                            </div>
                        </div>
                        <div className="lg:col-span-2 grid lg:grid-cols-2 gap-4">
                            {this.chatGPT.map((data) => (
                                <LandingPanel key={data.id} title={data.title} imageSource={data.image} to={"/machine-learning/courses/chat-gpt" + data.slug} strapLine={data.content}  />
                            ))}
                        </div>
                    </div>
                </div>
                </div>
            </React.Fragment>
        )
    };

}

export default Courses;