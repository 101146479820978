import $ from "jquery";
import {toast} from "react-toastify";

export function attachCopyTagHandlers(){

    //console.log("Checking for Tags and copy buttons");

    const copy = $('button.listing-action');
    const tags = $('span.js-tag-group-entry');

    //console.log('Tags :' + tags.length + '  ' + tags[0].innerHTML);
    //console.log("Copy :" + copy.length);

    copy.on('click', function() {
        const data = $(this).parent().siblings().first().children().first().text();
        const message = ({ closeToast }) => (
            <div className="toasty-preview">
                <p><strong>{data}</strong></p>
                <p>Has been added to the clipboard</p>
                <button onClick={closeToast}>Dismiss</button>
            </div>
        )
        toast(message, {
            toastId: '007',
            autoClose: 2500
        });
    });

    // With the current setup the handler can be added multiple times
    tags.off('click').on('click', function() {
        const data = $(this).text();

        console.log('Tag clicked with tag: ' + data);

        const message = ({ closeToast }) => (
            <div>
                <p><strong>{data}</strong></p>
                <p>Tag search is currently disabled</p>
                <button onClick={closeToast}>Dismiss</button>
            </div>
        )
        toast(message, {
            toastId: '007',
            autoClose: 2500
        });
    });

}